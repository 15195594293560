/* Author: Dalibor Kundrat  https://github.com/damikun */

import React, { useEffect } from 'react';
import { Toast } from './ToastProvider';
import {
  FaTimes,
  FaExclamationCircle,
  FaCheck,
  FaInfoCircle,
} from 'react-icons/fa';
import clsx from 'clsx';

const VARIANTS = {
  Info: {
    base: 'bg-white border-blue-500',
    iconstyle: 'text-blue-500 ',
    icon: FaInfoCircle,
    name: 'Info',
  },

  Error: {
    base: 'bg-white border-red-500 ',
    iconstyle: 'text-red-500 ',
    icon: FaExclamationCircle,
    name: 'Error',
  },

  Warning: {
    base: 'bg-white border-auraPrimary',
    iconstyle: 'text-auraPrimary ',
    icon: FaExclamationCircle,
    name: 'Warning',
  },

  Success: {
    base: 'bg-white border-green-500',
    iconstyle: 'text-green-500 ',
    icon: FaCheck,
    name: 'Success',
  },
};

export type Truncate =
  | 'truncate-1-lines'
  | 'truncate-2-lines'
  | 'truncate-3-lines';

export type ToastMessageProps = {
  id: string;
  lifetime?: number;
  variant?: keyof typeof VARIANTS | undefined;
  onRemove?: (id: string) => void;
  truncate?: Truncate;
} & Toast;

export default function ToastMessage({
  id,
  header,
  message,
  lifetime,
  onRemove,
  truncate = 'truncate-3-lines',
  icon,
  type,
}: ToastMessageProps) {
  const Var = type
    ? VARIANTS[type]
    : {
        base: 'bg-white border-gray-600 ',
        iconstyle: '',
        icon: icon,
        name: header,
      };

  useEffect(() => {
    if (lifetime && onRemove) {
      setTimeout(() => {
        onRemove(id);
      }, lifetime);
    }
  }, [lifetime]);

  return (
    <div
      className={clsx(
        'flex w-full visible flex-row shadow-lg',
        'border-l-4 rounded-md duration-100 cursor-pointer',
        'transform transition-all hover:scale-102',
        Var.base,
        type && 'max-h-40'
      )}
    >
      <div className="flex flex-row p-2 flex-no-wrap w-full">
        {Var.icon && (
          <div
            className={clsx(
              'flex items-center h-12 w-12',
              'mx-auto text-xl select-none'
            )}
          >
            <Var.icon className={clsx('mx-auto', Var.iconstyle)} />
          </div>
        )}

        <div className="flex flex-col flex-no-wrap px-1 w-full break-words">
          <div className="flex my-auto font-bold select-none">{Var.name}</div>
          <p
            className={clsx(
              '-mt-0.5 my-auto break-all flex',
              'text-gray-600 text-sm break-words',
              typeof message === 'string' && truncate
            )}
          >
            {message}
          </p>
        </div>
        <div
          onClick={() => onRemove && onRemove(id)}
          className={clsx(
            'mr-2 flex items-center mx-auto',
            'text-center leading-none text-lg'
          )}
        >
          <FaTimes
            className={clsx(
              'mx-auto my-auto text-center text-gray-600',
              'cursor-pointer hover:scale-105 transform '
            )}
          />
        </div>
      </div>
    </div>
  );
}
