import { Dispatch, AnyAction } from '@reduxjs/toolkit';
// import { NavigateFunction } from 'react-router-dom';
import {
  loadingStart,
  loadingStopped,
  paymentInitialization,
  paymentVerification,
  paymentConfirmation,
} from './reducers';
import paymentService from 'services/paymentService';
// import { ToastContextType } from 'components/common/Toast/ToastProvider';
// import { SetStateAction } from 'react';
import { InitializePaymentData } from 'services/paymentService/types';

export const initializePayment =
  (data: InitializePaymentData) =>
  async (dispatch: Dispatch<AnyAction>): Promise<void> => {
    try {
      dispatch(loadingStart());
      const res = await paymentService.initializePayment(data);
      dispatch(paymentInitialization(res.data));
    } catch (err: any) {
      dispatch(loadingStopped(err.response.data.responseMessage));
    }
  };

export const verifyPayment =
  (transactionID: number | string) =>
  async (dispatch: Dispatch<AnyAction>): Promise<void> => {
    try {
      dispatch(loadingStart());
      const res = await paymentService.verifyPayment(transactionID);
      dispatch(paymentVerification(res.data));
      // console.log(res.data);
    } catch (err: any) {
      dispatch(loadingStopped(err.response.data.responseMessage));
    }
  };

export const confirmPayment =(data: {
    transactionID: number | string;
    bookingID: number | string;
  }) =>
  async (dispatch: Dispatch<AnyAction>): Promise<void> => {
    try {
      dispatch(loadingStart());
      const res = await paymentService.confirmPayment(data);
      dispatch(paymentConfirmation(res));
      // console.log(res.data);
    } catch (err: any) {
      dispatch(loadingStopped(err.response.data.responseMessage));
    }
  };

// export const cancelUserBooking = (bookingID:number|string,  toast: ToastContextType | undefined )=>
// async (dispatch: Dispatch<AnyAction>): Promise<void> => {
//   try {
//     dispatch(loadingStart());
//     const res = await bookingService.cancelUserBooking(bookingID);
//     toast?.success(res.responseMessage, 5000);
//     console.log(res);
//   } catch (err: any) {
//     dispatch(loadingStopped(err.response.data.responseMessage));
//   }
// };

// export const saveSelectedBooking =
// (data: any) => (dispatch: Dispatch<AnyAction>) => {
//   try {
//     dispatch(selectedBooking(data));
//   } catch (err: any) {
//     console.log(err);
//   }
// };

// export const createBookingQuote = (data:any)=>
// async (dispatch: Dispatch<AnyAction>): Promise<void> => {
//   try {
//     dispatch(loadingStart());
//     const res = await bookingService.createQuote(data);
//     dispatch(createQuoteSuccess(res.data));
//   } catch (err: any) {
//     dispatch(loadingStopped(err.response.data.responseMessage));
//   }
// };
