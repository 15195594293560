import React, { useState } from 'react';
import Modal from '.';
import { IoMdCopy } from 'react-icons/io';
import { Dots } from '../Loader';

interface BankTransferProp {
    verifyloading: boolean;
    openModal: boolean;
    paymentSuccess: boolean;
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
    bankDetails: any;
    confirmDirectBankTransfer: ()=> void;
    isConfirmLoading: boolean
    setIsConfirmLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function BankTransfer(props: BankTransferProp) {
    const [copyText, setCopyText] = useState('Copy');
    const handleCopyToClipBoard = async () => {
        let inp = document.getElementById('accountNumber')?.innerHTML || '';
        try {
            await navigator.clipboard.writeText(inp);
            console.log('Content copied to clipboard');
            changeCopyText();
        } catch (err) {
            // console.error('Failed to copy: ', err);
        }
    };

    const changeCopyText = () => {
        setCopyText('Copied!');
        setTimeout(() => {
            setCopyText('Copy');
        }, 3000);
    };

    return (
        <>
            <Modal
                children={
                    <div className="relative  py-10 md:px-10 px-6 w-[370px] md:w-[650px] max-w-xl bg-white shadow-xl rounded-xl lg:max-w-xl">
                        <div className="p-2 text-center mb-6 xl:px-24">
                            <h2 className="text-2xl font-bold">Bank Transfer</h2>
                            <h4 className="text-[14px] mt-2 font-thin">Total amount to be paid</h4>
                            <h4 className="text-[18px] font-medium">₦{props?.bankDetails?.amount?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h4>
                        </div>
                        <div className="rounded-lg md:p-8 p-4 bg-gray-1 mb-8">
                            <div className="flex items-center justify-between">
                                <p>Bank Name</p>
                                <p className='capitalize'>{props?.bankDetails?.bankName}</p>
                            </div>

                            <div className="flex items-center justify-between mt-2">
                                <p className='text-left'>Account Name</p>
                                <p className='text-right capitalize'>{props?.bankDetails?.walletName}</p>
                            </div>

                            <div className="flex items-center justify-between mt-2">
                                <p>Account Number</p>
                                <div className="flex items-center gap-2">
                                    <p id="accountNumber">{props?.bankDetails?.accountNumber}</p>
                                    {copyText === 'Copied!' ? (
                                        <p className="text-auraPrimary">Copied!</p>
                                    ) : (
                                        <IoMdCopy className={`cursor-pointer text-[16px]`} onClick={handleCopyToClipBoard} />
                                    )}
                                </div>
                            </div>
                        </div>
                            <div className="p-4 rounded-xl border text-white w-[100%] cursor-pointer bg-[#F48220]"
                              onClick={()=>{
                                props.setIsConfirmLoading(true)
                                //call this func after 2mins
                                setTimeout(()=>{
                                    props?.confirmDirectBankTransfer();
                                }, 120000)
                               }
                              }>
                                {props?.isConfirmLoading ? <Dots /> : "I've made this transfer"}
                            </div>
                    </div>
                }
                openModal={props.openModal}
                setOpenModal={props.setOpenModal}
                verifyloading={props.verifyloading}
                paymentSuccess={props.paymentSuccess}
            />
        </>
    );
}
