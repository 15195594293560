import {
  Fragment,
  useRef,
  JSXElementConstructor,
  ReactElement,
  ReactFragment,
  ReactPortal,
} from 'react';
import { HiMenu } from 'react-icons/hi';
import { Popover, Transition } from '@headlessui/react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/rootReducer';

export default function PopOver(props: {
  type: string;
  icon:
    | ReactElement<any, string | JSXElementConstructor<any>>
    | ReactFragment
    | ReactPortal;
  children:
    | ReactElement<any, string | JSXElementConstructor<any>>
    | ReactFragment
    | ReactPortal;
}) {
  const { isAuthenticated } = useSelector((state: RootState) => state.user);
  //Beginning
  const buttonRef: any = useRef(null);
  const timeoutDuration = 1;
  let timeout: string | number | NodeJS.Timeout | undefined;

  const closePopover = () => {
    return buttonRef.current?.dispatchEvent(
      new KeyboardEvent('keydown', {
        key: 'Escape',
        bubbles: true,
        cancelable: true,
      })
    );
  };
  const onMouseEnter = (open: any) => {
    clearTimeout(timeout);
    if (open) return;
    return buttonRef.current?.click();
  };

  const onMouseLeave = (open: any) => {
    if (!open) return;
    timeout = setTimeout(() => closePopover(), timeoutDuration);
  };
  return (
    <Popover className="relative">
      {({ open }: any) => (
        <>
          <Popover.Button
            ref={buttonRef}
            type="button"
            className={`flex py-2 gap-1 items-center rounded-lg outline-0 ${
              props.type === 'menu' ? 'px-[6px] bg-auraPrimary' : ''
            }`}
            onMouseEnter={onMouseEnter.bind(null, open)}
            onMouseLeave={onMouseLeave.bind(null, open)}
          >
            {props.type === 'menu' && <HiMenu className="text-xl text-white" />}
            {isAuthenticated && props.icon}
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel
              className="absolute lg:left-1/2 z-10 mt-3 xl:-translate-x-1/2 -translate-x-[66%] sm:-translate-x-[70%] transform px-4 sm:px-0"
              onMouseEnter={onMouseEnter.bind(null, open)}
              onMouseLeave={onMouseLeave.bind(null, open)}
            >
              <div className="overflow-hidden w-[100%] rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                {props.children}
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}
