import { ChangeEventHandler, MouseEventHandler, useState, useEffect } from 'react';
import type { RadioChangeEvent } from 'antd';
import { Radio } from 'antd';
import moment from 'moment';
import { Dots } from 'components/common/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/rootReducer';
import { Link, useNavigate } from 'react-router-dom';
import dataService from 'services/appData';
import { useToast } from 'components/common/Toast/ToastProvider';
import { Navigate, useLocation } from 'react-router-dom';
import { getSession, storage } from 'utils';

interface PersonalDetailsFormProp {
    onChange: (e: RadioChangeEvent) => void;
    isBookingFor: any;
    productDetails?: any;
    formData: {
        firstName: string | number | readonly string[];
        lastName: string | number | readonly string[];
        email: string | number | readonly string[];
        phone: string | number | readonly string[];
    };
    handleFormChange: (arg0: string) => ChangeEventHandler<HTMLInputElement>;
    disabled: any;
    c_in: moment.MomentInput;
    c_out: moment.MomentInput;
    handleReserveBooking?: () => void;
    ticketQuantity?: number;
    initialActivityBookingAmount?: number;
    setticketQuantity?: (arg0: number) => void;
    decrementTicketQty?: () => void;
    incrementTicketQty?: () => void;
    activityBookingPrice?: number;
    isLoading?: boolean;
    setisLoading?: (arg0: boolean) => void;
    setActiviList:any
}

export default function PersonalDetailsForm(props: PersonalDetailsFormProp) {
    const path = window.location.pathname.toLowerCase();
    const { isAuthenticated, user } = useSelector((state: RootState) => state.user);
    const navigate = useNavigate();
    const toast = useToast();
    const location = useLocation();
    const [stateCount, setstateCount] = useState<number>(1);
    const [selectedIds, setSelectedIds] = useState<Array<any>>([]);
    const [selectedServices, setselectedServices] = useState<any[]>(
        (props?.productDetails?.packages || []).map((data: any) => ({
            activityTypeId: data?.activityTypeId,
            type: data?.type,
            originalProductPrice: data?.amount,
            newProductPrice: 0,
            quantity: 0,
        }))
    );

    function handleSubmit(e: any) {
        e.preventDefault();
        if (!path.includes('activities')) {
            if (!isAuthenticated || !getSession(storage.getToken())) {
                return navigate(`/login?redirect=${location.pathname}${location.search}`);
            }
        }
        const activityList = selectedServices.map(item => {
            delete item.type
            delete item.originalProductPrice
            delete item.newProductPrice
            return item
        })
        props.setActiviList(activityList.filter((item)=> item.quantity !== 0))

        if (!path.includes('activities')) {
            if (!user.isPhoneNumberConfirmed) {
                resentPhonevalidation();
                return;
            }
        }

        if (props.disabled) return;
        // props.handleReserveBooking && props.handleReserveBooking();
    }

    const resentPhonevalidation = async () => {
        await dataService
            .resendPhoneValidation({
                phoneNumber: `${user?.phoneNumber}`,
            })
            .then(() => navigate('/otp'))
            .catch((err: any) => navigate('/otp'));
    };

    const truncateWord = (params: string) => {
        return params ? params.substring(0, 12) + '...' : '';
    };

    const totalCounts: number = selectedServices.reduce((accumulator: number, object: any) => {
        return accumulator + (object?.quantity || 0);
    }, 0);

    const incrementSelectedService = (id: number | string, i: any) => {
        console.log('id', id);
        let arrWithSelectedObject = (selectedServices || []).find((data) => data.activityTypeId === id);
        // console.log('id', arrWithSelectedObject);
        let arrWithRemainingObject = selectedServices; //changed selectedServices to selectedProductServices (since it retained the required data)

        let newSelectedArr = {
            ...arrWithSelectedObject,
            quantity: arrWithSelectedObject.quantity + 1,
            newProductPrice: arrWithSelectedObject.originalProductPrice * (arrWithSelectedObject.quantity + 1),
        };

        arrWithRemainingObject[i] = newSelectedArr;
        setselectedServices([...arrWithRemainingObject]);
    };

    const decrementSelectedService = (id: number | string, i: any) => {
        let arrWithSelectedObject = (selectedServices || []).find((data) => data.activityTypeId === id);
        let arrWithRemainingObject = selectedServices;

        if (arrWithSelectedObject.quantity > 1) {
            let newSelectedArr = {
                ...arrWithSelectedObject,
                quantity: arrWithSelectedObject.quantity - 1,
                newProductPrice: arrWithSelectedObject.originalProductPrice * (arrWithSelectedObject.quantity - 1),
            };

            arrWithRemainingObject[i] = newSelectedArr;
            setselectedServices([...arrWithRemainingObject]);
        }
    };

    const countOfID = (id: number | string) => {
        return (selectedServices || []).filter((data) => data.activityTypeId === id)[0]?.quantity || 0;
    };

    const checkSelected = (id: any) => {
        return selectedIds.includes(id);
    };

    const isServiceSelected = (id: any, i: any) => {
        return !checkSelected(id) ? (
            <div
                onClick={() => {
                    setSelectedIds([...selectedIds, id]);
                    incrementSelectedService(id, i);
                }}
                className={`whitespace-nowrap cursor-pointer px-4 md:px-8 py-2 text-xs md:text-base font-normal border rounded-lg border-auraPrimary w-max ${
                    checkSelected(id) ? 'bg-auraPrimary text-white' : 'text-auraPrimary'
                }`}
            >
                Select Quantity
            </div>
        ) : (
            <div>
                <div className="w-[8rem] flex items-center">
                    <button
                        type="button"
                        className="px-4 py-2 rounded-lg bg-auraPrimary text-white font-bold text-xl"
                        onClick={() => {
                            decrementSelectedService(id, i);
                            if (stateCount > 0) {
                                setstateCount(stateCount - 1);
                            }
                        }}
                    >
                        -
                    </button>
                    <input
                        type="text"
                        value={countOfID(id)}
                        readOnly
                        className="min-w-[4rem] text-center font-bold border-0 w-full"
                    />

                    <button
                        type="button"
                        className="px-4 py-2 rounded-lg bg-auraPrimary text-white font-bold text-xl"
                        onClick={() => {
                            setstateCount(stateCount + 1);
                            incrementSelectedService(id, i);
                        }}
                    >
                        +
                    </button>
                </div>
            </div>
        );
    };

    return (
        <div className="space-y-8">
            <div className="space-y-5">
                {path.includes('activities') ? (
                    <>
                        <h3 className="text-2xl font-medium capitalize ">Ticket Details</h3>
                        <p className="text-base font-medium text-neutral">Who are you Purchasing for?</p>
                    </>
                ) : (
                    <>
                        <h3 className=" text-2xl capitalize font-medium ">Reservation</h3>
                        <p className=" font-medium text-neutral text-base ">Who are you booking for?</p>
                    </>
                )}
                <Radio.Group onChange={props.onChange} value={props.isBookingFor}>
                    <Radio value="Self">For Myself</Radio>
                    <Radio value="SomeoneElse">For Someone else</Radio>
                </Radio.Group>
            </div>

            <form className="grid gap-4 md:grid-cols-2" onSubmit={handleSubmit}>
                <div className="mb-6 col-span-2 md:col-span-1">
                    <label htmlFor="firstName" className="block mb-2 text-xs font-bold uppercase text-neutral108">
                        FIRST NAME
                    </label>
                    <input
                        type="text"
                        id="firstName"
                        name="firstName"
                        className="shadow-sm  border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5 placeholder:text-sm placeholder:font-light"
                        placeholder="Enter First Name"
                        value={props.formData.firstName}
                        onChange={props.handleFormChange('firstName')}
                        required
                    />
                </div>
                <div className="mb-6 col-span-2 md:col-span-1">
                    <label htmlFor="lastName" className="block mb-2 text-xs font-bold uppercase text-neutral108">
                        LAST NAME
                    </label>
                    <input
                        type="text"
                        id="lastName"
                        className="block w-full border border-gray-300 rounded-lg shadow-sm placeholder:text-sm placeholder:font-light"
                        placeholder="Enter Last Name"
                        required
                        name="lastName"
                        value={props.formData.lastName}
                        onChange={props.handleFormChange('lastName')}
                    />
                </div>
                <div className="mb-6 col-span-2">
                    <label htmlFor="email" className="block mb-2 text-xs font-bold uppercase text-neutral108">
                        EMAIL ADDRESS
                    </label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={props.formData.email}
                        onChange={props.handleFormChange('email')}
                        className="block w-full border border-gray-300 rounded-lg shadow-sm placeholder:text-sm placeholder:font-light"
                        placeholder="Enter Email"
                        required
                    />
                </div>
                <div className="mb-6 col-span-2">
                    <label htmlFor="phone" className="block mb-2 text-xs font-bold uppercase text-neutral108">
                        PHONE NUMBER
                    </label>
                    <input
                        type="text"
                        id="phone"
                        name="phone"
                        value={props.formData.phone}
                        onChange={props.handleFormChange('phone')}
                        className="block w-full border border-gray-300 rounded-lg shadow-sm placeholder:text-sm placeholder:font-light"
                        placeholder="e.g 08123123123"
                        required
                    />
                </div>
                {path.includes('activities') && (
                    <div className="w-full col-span-2">
                        {selectedServices.length == 0 ? 
                        <div className='text-center text-gray-500'> Oops! No Package set for this activity</div> : 
                        <table className="!w-[50%] text-sm ">
                            <thead className="text-base text-left">
                                <tr>
                                    <th scope="col" className="px-6 py-3 text-base text-left text-black">
                                        Event
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-base text-left text-black">
                                        Qty
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-base text-left text-black">
                                        Amount
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {(selectedServices || [])?.map((item, i: number) => (
                                    <tr className="" key={i}>
                                        <td scope="row" className="px-4 md:py-4 text-base">
                                            {/* <div className="flex items-center text-neutral"> */}
                                            {/* 
                                                <img src="../../img/icon/ticket-icon.svg" alt="" className="mr-1.5" srcSet="" />
                                          */}
                                            {/* <p>{truncateWord(item?.type) || ''}</p> */}
                                            <p>
                                                {item?.type?.substring(0, 12) || ''}
                                                {item?.type?.length >= 12 && '...'}
                                            </p>
                                            {/* </div> */}
                                        </td>
                                        <td className="px-4 md:py-4">
                                            {/* <div className="flex items-center w-full">
                                            <button
                                                type="button"
                                                className="minus-q product"
                                                onClick={() => props.decrementTicketQty && props.decrementTicketQty()}
                                            >
                                                <img src="../../img/icon/minus-qty.svg" className="w-20" alt="" srcSet="" />
                                            </button>
                                            <input
                                                type="number"
                                                className="mx-3 text-center bg-gray-100 form-control input-number text-black font-medium"
                                                style={{ color: 'black', fontWeight: '600' }}
                                                name="qty"
                                                value={props.ticketQuantity}
                                                readOnly
                                            />
                                            <button
                                                type="button"
                                                className="plus-q product"
                                                onClick={() => props.incrementTicketQty && props.incrementTicketQty()}
                                            >
                                                <img src="../../img/icon/plus-qty.svg" alt="" className="w-20" srcSet="" />
                                            </button>
                                        </div> */}
                                            <div className="">{isServiceSelected(item?.activityTypeId, i)}</div>
                                        </td>
                                        <td className="px-6 py-4 text-base font-medium">
                                            ₦
                                            {/* {props.activityBookingPrice
                                            ? props.activityBookingPrice.toLocaleString()
                                            : props.initialActivityBookingAmount
                                            ? props.initialActivityBookingAmount
                                            : 0
                                            } */}
                                            {/* {item?.amount * props?.ticketQuantity} */}
                                            {item.newProductPrice == 0
                                                ? item?.originalProductPrice?.toLocaleString()
                                                : item?.newProductPrice?.toLocaleString()}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        }
                    </div>
                )}

                {!path.includes('experience') &&
                    (path.includes('activities') ? (
                        totalCounts > 0 && (
                            <div className="mb-6 col-span-2">
                                <button
                                    className=" py-3 rounded-xl text-base font-medium mx-auto w-full bg-auraPrimary text-white capitalize"
                                    type="submit"
                                    disabled={
                                        path.includes('activ')
                                            ? false
                                            : !moment(props.c_in).isValid() || !moment(props.c_out).isValid()
                                    }
                                >
                                    {props.isLoading ? <Dots /> : path.includes('activities') ? 'Buy Now' : 'Reserve'}
                                </button>
                            </div>
                        )
                    ) : (
                        <div className="mb-6 col-span-2">
                            <button
                                className=" py-3 rounded-xl text-base font-medium mx-auto w-full bg-auraPrimary text-white capitalize"
                                type="submit"
                                disabled={
                                    path.includes('activ')
                                        ? false
                                        : !moment(props.c_in).isValid() || !moment(props.c_out).isValid()
                                }
                            >
                                {props.isLoading ? <Dots /> : path.includes('activities') ? 'Buy Now' : 'Reserve'}
                            </button>
                        </div>
                    ))}
            </form>
        </div>
    );
}
