import { combineReducers } from '@reduxjs/toolkit';
import userReducer from './authSlice/reducers';
import productReducer from './productSlice/reducers';
import filterReducer from './filterSlice/reducers';
import reservationReducer from './reservation/reducers';
import bookingReducer from './booking/reducers';
import paymentReducer from './payment/reducers';
import reviewReducer from './review/reducers';
import dealPromotionIDReducer from './deal_promotion/reducers';

const rootReducer = combineReducers({
  user: userReducer,
  product: productReducer,
  filter: filterReducer,
  reservation: reservationReducer,
  booking: bookingReducer,
  payment: paymentReducer,
  review:reviewReducer,
  dealPromotionID:dealPromotionIDReducer
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
