import { Suspense, lazy } from 'react';
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';
import { PrivateRoute } from 'components/common';
import { Spinner } from 'components/common/Loader';
import { Privacy } from 'components/common';
import Payment from 'pages/Payment';
import ActivityPayment from 'pages/Payment/indexActivi';
import { useSelector } from 'react-redux';
// import {Translator, Translate} from 'react-auto-translate';

import CacheBuster from 'react-cache-buster';

import CheckoutComplete from 'pages/Payment/CheckoutComplete';
import Messages from 'pages/Messages';

import SuccessModal from 'pages/Authentication/ResetSuccess';
import TermsOfService from 'components/common/Privacy/TermsOfService';
import CancellationPolicy from 'components/common/Privacy/CancellationPolicy'
import HelpCenterPage from 'pages/HelpCenter';
import ContactUs from 'components/common/ContactUs';
import AboutUs from 'components/common/AboutUs';
import ActivitiesProductDetail from 'pages/ReusableProduct/ActivitiesProductDetail';
import InstantCheckout from 'pages/Payment/InstantCheckout';

const Homepage = lazy(() => import('pages/Homepage'));
const ArtworkReserve = lazy(() => import('pages/Photography/Artwork'));
const Onboarding = lazy(() => import('pages/Onboarding'));
const Login = lazy(() => import('pages/Authentication/Login'));
const Signup = lazy(() => import('pages/Authentication/Signup'));
const ForgotPassword = lazy(() => import('pages/Authentication/ForgotPassword'));
const ResetPassword = lazy(() => import('pages/Authentication/ResetPassword'));
const VerifyEmail = lazy(() => import('pages/Authentication/Verify'));
const OTP = lazy(() => import('pages/Authentication/opt/Opt'));

const FirstPage = lazy(() => import('pages/Onboarding/Common/index'));
const UploadPage = lazy(() => import('pages/Onboarding/Common/upload'));
const OfferingPage = lazy(() => import('pages/Onboarding/Common/offering'));
const SetupPage = lazy(() => import('pages/Onboarding/Common/setup'));
const ServicesPage = lazy(() => import('pages/Onboarding/Common/uploadService'));
const FinalPage = lazy(() => import('pages/Onboarding/Common/final'));
// const Dashboard = lazy(() => import('pages/DashboardPage'));
const Dashboard = lazy(() => import('pages/Host/DashboardPage'));
const MyHotels = lazy(() => import('pages/Host/Hotels'));
const MyHostReservations = lazy(() => import('pages/Host/Reservations'));

// const PersonalInfo = lazy(() => import('pages/UserProfile/personal-info-form'));

const ProductReservation = lazy(() => import('pages/ReusableProduct/ProductReservation'));
const ProductListing = lazy(() => import('pages/ReusableProduct'));
const Partners = lazy(() => import('pages/ReusableProduct/Partners'));
const CurrentPartner = lazy(() => import('pages/ReusableProduct/CurrentPartner'));

const ApartmentTypePage = lazy(() => import('pages/Onboarding/Apartment/type'));

const ApartmentFlatPage = lazy(() => import('pages/Onboarding/Apartment/flat'));
const ApartmentSecondFlatPage = lazy(() => import('pages/Onboarding/Apartment/second-flat'));
const RestaurantPage = lazy(() => import('pages/Onboarding/Restaurant'));
const RestaurantUploadPage = lazy(() => import('pages/Onboarding/Restaurant/upload'));
const RestaurantOfferingPage = lazy(() => import('pages/Onboarding/Restaurant/offering'));
const RestaurantMenuPage = lazy(() => import('pages/Onboarding/Restaurant/menu'));
const RestaurantFinalPage = lazy(() => import('pages/Onboarding/Restaurant/final'));

const PhotographyPage = lazy(() => import('pages/Onboarding/Photography/index'));
const PhotographyUploadPage = lazy(() => import('pages/Onboarding/Photography/upload'));
const PhotographyBenefitsPage = lazy(() => import('pages/Onboarding/Photography/benefits'));
const PhotographyArtworksPage = lazy(() => import('pages/Onboarding/Photography/artworks'));
const PhotographyFinalPage = lazy(() => import('pages/Onboarding/Photography/final'));

const PhotographyPricingPage = lazy(() => import('pages/Onboarding/Photography/pricing'));

const ExperiencePage = lazy(() => import('pages/Onboarding/Experience'));
const ExperienceUploadPage = lazy(() => import('pages/Onboarding/Experience/upload'));
const ExperienceOfferingPage = lazy(() => import('pages/Onboarding/Experience/offering'));
const ExperienceFinalPage = lazy(() => import('pages/Onboarding/Experience/final'));

const WorkspacePage = lazy(() => import('pages/Onboarding/Workspace'));
const OfferingPageWorkspace = lazy(() => import('pages/Onboarding/Workspace/offering'));
const PricngPage = lazy(() => import('pages/Onboarding/Workspace/pricing'));

const Upload = lazy(() => import('pages/Onboarding/Workspace/upload'));

const UploadService = lazy(() => import('pages/Onboarding/Workspace/uploadService'));

const WorkspaceFinalPage = lazy(() => import('pages/Onboarding/Workspace/final'));

const UserProfile = lazy(() => import('pages/UserProfile'));

const ProductPage = lazy(() => import('pages/Onboarding/Product'));
const ProductOffering = lazy(() => import('pages/Onboarding/Product/offering'));
const ProductSetup = lazy(() => import('pages/Onboarding/Product/setup'));
const ProductUpload = lazy(() => import('pages/Onboarding/Product/upload'));
const ProductFinal = lazy(() => import('pages/Onboarding/Product/final'));
const ProductDetails = lazy(() => import('pages/ReusableProduct/ProductDetail'));

const VerifiedHotels = lazy(() => import('pages/HostHotels/index'));
const VerifiedApartment = lazy(() => import('pages/HostApartment/index'));
const VerifiedExperience = lazy(() => import('pages/HostExperience/index'));
const VerifiedWorkspace = lazy(() => import('pages/HostWorkspace/index'));
const VerifiedPhotography = lazy(() => import('pages/HostPhotography/index'));
const VerifiedRestaurant = lazy(() => import('pages/HostRestaurant/index'));
const Reservation = lazy(() => import('pages/Reservation'));
const MyOrder = lazy(() => import('pages/Myorder'));
const Delivery = lazy(() => import('pages/Delivery'));
const ReservationDetails = lazy(() => import('pages/Reservation/ReservationDetails'));
const ReservationDetailss = lazy(() => import('pages/Reservation/RestaurantReservationDetails'));
const OrderDetails = lazy(() => import('pages/Myorder/ReservationDetails'));
const ViewHotel = lazy(() => import('pages/HostHotels/ViewHotel'));
const UnVerifiedViewHotel = lazy(() => import('pages/HostHotels/ViewHotel'));
const ViewApartment = lazy(() => import('pages/HostApartment/ViewApartment'));
const ViewWorkspace = lazy(() => import('pages/HostWorkspace/ViewWorkspace'));
const ViewPhotography = lazy(() => import('pages/HostPhotography/ViewPhotography'));
const ViewRestaurant = lazy(() => import('pages/HostRestaurant/ViewRestaurant'));
const ViewExperience = lazy(() => import('pages/HostExperience/ViewExperience'));
const Ratings = lazy(() => import('pages/Ratings'));
const ViewRatings = lazy(() => import('pages/Ratings/viewRatings'));
const Earnings = lazy(() => import('pages/Earnings'));
const Promotions = lazy(() => import('pages/Promotions'));
const AddPromotions = lazy(() => import('pages/Onboarding/Promotion'));
const Notification = lazy(() => import('pages/Notification'));
// const PhotographyView = lazy(() => import("pages/Photography"));
const PhotographyReserve = lazy(() => import("pages/Photography/Reserve"));
const RestaurantReserve = lazy(() => import("pages/Restaurant/Reservation"));
// const WhhatAppWebHook = lazy(() => import("pages/WebHook"));

const version = process.env.REACT_VERSION;

const AppRoutes = () => {
    const { user } = useSelector((state: any) => state.user);

    return (
    <CacheBuster
      currentVersion={"1.0.0.7"}
      isEnabled={true} 
      isVerboseMode={false} 
      loadingComponent={<Spinner />} 
      metaFileDirectory={'.'} 
    >
    <BrowserRouter>
        <Suspense fallback={<Spinner />}>
        {/* <Translator
            // cacheProvider={cacheProvider}
            from='en'
            to='fr'
            googleApiKey='AIzaSyDkT2OOLhCjlWGd2wfuB88S1SL2Ef1LfZs'
        > */}
            <Routes>
                <Route
                    path="/"
                    element={
                        // <PrivateRoute>
                        <Homepage />
                        // </PrivateRoute>
                    }
                />
                <Route path="/terms-of-service" element={<TermsOfService />} />
                <Route path="/cancellation-policy" element={<CancellationPolicy />} />
                <Route path="/faqs" element={<HelpCenterPage />} />
                <Route path="/contact_us" element={<ContactUs />} />
                <Route path="/about_us" element={<AboutUs />} />
                <Route path="/privacy-policy" element={<Privacy />} />
                <Route path="/deals-of-the-day" element={<ProductListing />} />
                <Route path="/recommended" element={<ProductListing />} />
                <Route path="/featured" element={<ProductListing />} />
                <Route path="/partners/:partnerName/:partnerId" element={<Partners />} />
                <Route path="/partners/ican" element={<CurrentPartner />} />
                <Route path="/login" element={<Login />} />
                {/* <Route path="/admin-login" element={<AdminLogin />} /> */}
                <Route path="/signup" element={<Signup />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="/verify-email" element={<VerifyEmail />} />
                <Route path="/success" element={<SuccessModal />} />

                <Route path="/otp" element={<OTP />} />

                <Route path="/photography" element={<ProductListing />} />
                <Route path="/photography/:detailsId" element={<PhotographyReserve />} />
                <Route path="/artwork/:detailsId" element={<ArtworkReserve />} />

                <Route path="/restaurant" element={<ProductListing />} />
                <Route path="/restaurants" element={<ProductListing />} />

                <Route path="/restaurant/:detailsId" element={<RestaurantReserve />} />
                <Route path="/restaurants/:detailsId" element={<RestaurantReserve />} />




                <Route
                    path="/dashboard"
                    element={
                        <PrivateRoute>
                            {user?.ishost ? <Dashboard /> : <Navigate to="/" /> }
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/reservation"
                    element={
                        <PrivateRoute>
                             {user?.ishost ? <Reservation /> : <Navigate to="/" /> }
                        </PrivateRoute>
                    }
                />
                 <Route
                    path="/myorder"
                    element={
                        <PrivateRoute>
                             {user?.ishost ? <MyOrder /> : <Navigate to="/" /> }
                        </PrivateRoute>
                    }
                />
                 <Route
                    path="/delivery"
                    element={
                        <PrivateRoute>
                             {user?.ishost ? <Delivery /> : <Navigate to="/" /> }
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/reservation/host/:id"
                    element={
                        <PrivateRoute>
                            <ReservationDetails />
                        </PrivateRoute>
                    }
                />
                 <Route
                    path="/reservation/host/restaurant/:id"
                    element={
                        <PrivateRoute>
                            <ReservationDetailss />
                        </PrivateRoute>
                    }
                />
                 <Route
                    path="/order/host/:id"
                    element={
                        <PrivateRoute>
                            <OrderDetails />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/ratings"
                    element={
                        <PrivateRoute>
                            <Ratings />
                        </PrivateRoute>
                    }
                />

                <Route
                    path="/ratings/view/:id"
                    element={
                        <PrivateRoute>
                            {user?.ishost ? <ViewRatings /> : <Navigate to="/" /> }
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/earnings"
                    element={
                        <PrivateRoute>
                            {user?.ishost ? <Earnings /> : <Navigate to="/" /> }
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/promotions"
                    element={
                        <PrivateRoute>
                            {user?.ishost ?  <Promotions /> : <Navigate to="/" /> }
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/addnewpromotions"
                    element={
                        <PrivateRoute>
                            <AddPromotions />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/host-hotels"
                    element={
                        <PrivateRoute>
                            {user?.ishost ? <VerifiedHotels /> : <Navigate to="/" /> }
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/product/verifiedhotel/:productID/:productDetailsID"
                    element={
                        <PrivateRoute>
                            <ViewHotel />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/product/verifiedapartment/:productID/:productDetailsID"
                    element={
                        <PrivateRoute>
                            <ViewApartment />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/product/verifiedworkspace/:productID/:productDetailsID"
                    element={
                        <PrivateRoute>
                            <ViewWorkspace />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/product/verifiedexperience/:productID/:productDetailsID"
                    element={
                        <PrivateRoute>
                            <ViewExperience />
                        </PrivateRoute>
                    }
                />
                 <Route
                    path="/product/verifiedphotography/:productID/:productDetailsID"
                    element={
                        <PrivateRoute>
                            <ViewPhotography />
                        </PrivateRoute>
                    }
                />
                 <Route
                    path="/product/verifiedrestaurant/:productID/:productDetailsID"
                    element={
                        <PrivateRoute>
                            <ViewRestaurant />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/host-apartments"
                    element={
                        <PrivateRoute>
                            {user?.ishost ? <VerifiedApartment /> : <Navigate to="/" /> }
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/host-experience"
                    element={
                        <PrivateRoute>
                            {user?.ishost ? <VerifiedExperience /> : <Navigate to="/" /> }
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/host-workspace"
                    element={
                        <PrivateRoute>
                             {user?.ishost ? <VerifiedWorkspace /> : <Navigate to="/" /> }
                        </PrivateRoute>
                    }
                />
                 <Route
                    path="/host-photography"
                    element={
                        <PrivateRoute>
                             {user?.ishost ? <VerifiedPhotography /> : <Navigate to="/" /> }
                        </PrivateRoute>
                    }
                />
                 <Route
                    path="/host-restaurant"
                    element={
                        <PrivateRoute>
                             {user?.ishost ? <VerifiedRestaurant /> : <Navigate to="/" /> }
                        </PrivateRoute>
                    }
                />
                {/* unverified hotel */}
                <Route
                    path="/product/unverifiedhotels/:productDetailsID"
                    element={
                        <PrivateRoute>
                            <UnVerifiedViewHotel />
                        </PrivateRoute>
                    }
                />
                {/* unverified apartment */}
                <Route
                    path="/product/unverifiedapartments/:productDetailsID"
                    element={
                        <PrivateRoute>
                            <ViewApartment />
                        </PrivateRoute>
                    }
                />
                {/* unverified workspace */}
                <Route
                    path="/product/unverifiedworkspace/:productDetailsID"
                    element={
                        <PrivateRoute>
                            <ViewWorkspace />
                        </PrivateRoute>
                    }
                />
                 {/* unverified photography */}
                 <Route
                    path="/product/unverifiedphotography/:productDetailsID"
                    element={
                        <PrivateRoute>
                            <ViewPhotography />
                        </PrivateRoute>
                    }
                />
                 {/* unverified restaurant */}
                 <Route
                    path="/product/unverifiedrestaurant/:productDetailsID"
                    element={
                        <PrivateRoute>
                            <ViewRestaurant />
                        </PrivateRoute>
                    }
                />
                {/* unverified experience */}
                <Route
                    path="/product/unverifiedexperience/:productDetailsID"
                    element={
                        <PrivateRoute>
                            <ViewExperience />
                        </PrivateRoute>
                    }
                />
                {/* path="host"
          // element={
          //   <PrivateRoute>
          //     <Dashboard />
          //   </PrivateRoute>
          // }
        > */}
                {/* <Route
              path="dashboard"
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            /> */}
                {/* <Route
              path="hotels"
              element={
                <PrivateRoute>
                  <MyHotels />
                </PrivateRoute>
              }
            /> */}
                <Route
                    path="reservations"
                    element={
                        <PrivateRoute>
                            <MyHostReservations />
                        </PrivateRoute>
                    }
                />

                <Route
                    path="/onboarding"
                    element={
                        <PrivateRoute>
                            <Onboarding />
                        </PrivateRoute>
                    }
                />

                <Route
                    path="/onboarding/product/:productID"
                    element={
                        <PrivateRoute>
                            <ProductPage />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/onboarding/product/:productID/offering/:productDetailID"
                    element={
                        <PrivateRoute>
                            <ProductOffering />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/onboarding/product/:productID/setup/:productDetailsID"
                    element={
                        <PrivateRoute>
                            <ProductSetup />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/onboarding/product/:productID/upload/:productDetailsID"
                    element={
                        <PrivateRoute>
                            <ProductUpload />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/onboarding/product/:productID/final/:productDetailsID"
                    element={
                        <PrivateRoute>
                            <ProductFinal />
                        </PrivateRoute>
                    }
                />

                {/* onboarding Hotels Route for New Create */}
                <Route
                    path="/onboarding/hotel/:productID"
                    element={
                        <PrivateRoute>
                            <FirstPage />
                        </PrivateRoute>
                    }
                />

                {/* continue onboarding */}
                <Route
                    path="/onboarding/hotel/:productID/:productDetailsID"
                    element={
                        <PrivateRoute>
                            <FirstPage />
                        </PrivateRoute>
                    }
                />

                <Route
                    path="/onboarding/hotel/:productID/upload/:productDetailsID"
                    element={
                        <PrivateRoute>
                            <UploadPage />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/onboarding/hotel/:productID/offering/:productDetailsID"
                    element={
                        <PrivateRoute>
                            <OfferingPage />
                        </PrivateRoute>
                    }
                />

                <Route
                    path="/onboarding/hotel/:productID/setup/:productDetailsID"
                    element={
                        <PrivateRoute>
                            <SetupPage />
                        </PrivateRoute>
                    }
                />

                <Route
                    path="/onboarding/hotel/:productID/final/:productDetailsID"
                    element={
                        
                            <FinalPage />
                       
                    }
                />

                <Route
                    path="/onboarding/hotel/:productID/services/:productDetailsID"
                    element={
                        <PrivateRoute>
                            <ServicesPage />
                        </PrivateRoute>
                    }
                />

                {/* continue onboarding */}
                <Route
                    path="/onboarding/apartment/:productID/:productDetailsID"
                    element={
                        <PrivateRoute>
                            <FirstPage />
                        </PrivateRoute>
                    }
                />

                <Route
                    path="/onboarding/apartment/:productID"
                    element={
                        <PrivateRoute>
                            <FirstPage />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/onboarding/apartment/:productID/offering/:productDetailsID"
                    element={
                        <PrivateRoute>
                            <OfferingPage />
                        </PrivateRoute>
                    }
                />
                <Route path="/onboarding/apartment/:productID/type/:productDetailsID/" element={<ApartmentTypePage />} />
                <Route
                    path="/onboarding/apartment/:productID/setup/:productDetailsID"
                    element={
                        <PrivateRoute>
                            <SetupPage />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/onboarding/apartment/:productID/upload/:productDetailsID"
                    element={
                        <PrivateRoute>
                            <UploadPage />
                        </PrivateRoute>
                    }
                />

                <Route
                    path="/onboarding/apartment/:productID/services/:productDetailsID"
                    element={
                        <PrivateRoute>
                            <ServicesPage />
                        </PrivateRoute>
                    }
                />

                <Route
                    path="/onboarding/apartment/:productID/final/:productDetailsID"
                    element={
                        <PrivateRoute>
                            <FinalPage />
                        </PrivateRoute>
                    }
                />
                <Route path="/onboarding/apartment/type/flat" element={<ApartmentFlatPage />} />
                <Route path="/onboarding/apartment/type/second-flat" element={<ApartmentSecondFlatPage />} />

                {/* continue onboarding */}
                <Route
                    path="/onboarding/restaurant/:productID/:productDetailsID"
                    element={
                        <PrivateRoute>
                            <RestaurantPage />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/onboarding/restaurant/:productID"
                    element={
                        <PrivateRoute>
                            <RestaurantPage />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/onboarding/restaurant/upload/:productID/:productDetailsID"
                    element={
                        <PrivateRoute>
                            <RestaurantUploadPage />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/onboarding/restaurant/:productID/offering/:productDetailsID"
                    element={
                        <PrivateRoute>
                            <RestaurantOfferingPage />
                        </PrivateRoute>
                    }
                />
                <Route path="/onboarding/restaurant/:productID/menu/:productDetailsID" element={<PrivateRoute><RestaurantMenuPage /></PrivateRoute>} />
                <Route path="/onboarding/restaurant/:productID/final/:productDetailsID" element={<PrivateRoute><RestaurantFinalPage /></PrivateRoute>} />

                {/* continue onboarding */}
                <Route
                    path="/onboarding/photography/:productID/:productDetailsID"
                    element={
                        <PrivateRoute>
                            <PhotographyPage />
                        </PrivateRoute>
                    }
                />
                <Route path="/onboarding/photography/:productID" element={<PrivateRoute><PhotographyPage /></PrivateRoute>} />
                <Route path="/onboarding/photography/upload/:productID/:productDetailsID" element={<PrivateRoute><PhotographyUploadPage /></PrivateRoute>} />
                <Route path="/onboarding/photography/offering/:productID/:productDetailsID" element={<PrivateRoute><PhotographyBenefitsPage /></PrivateRoute>} />
                <Route path="/onboarding/photography/:productID/artworks/:productDetailsID" element={<PrivateRoute><PhotographyArtworksPage /></PrivateRoute>} />
                <Route path="/onboarding/photography/:productID/final/:productDetailsID" element={<PrivateRoute><PhotographyFinalPage /></PrivateRoute>} />
                <Route path="/onboarding/photography/:productID/pricing/:productDetailsID" element={<PrivateRoute><PhotographyPricingPage /></PrivateRoute>} />

                {/* continue onboarding */}
                <Route
                    path="/onboarding/workspaces/:productID/:productDetailsID"
                    element={
                        <PrivateRoute>
                            <WorkspacePage />
                        </PrivateRoute>
                    }
                />

                <Route
                    path="/onboarding/Workspaces/:productID"
                    element={
                        <PrivateRoute>
                            <WorkspacePage />
                        </PrivateRoute>
                    }
                />

                <Route
                    path="/onboarding/workspace/upload/:productID/:productDetailsID"
                    element={
                        <PrivateRoute>
                            <Upload />
                        </PrivateRoute>
                    }
                />

                <Route
                    path="/onboarding/workspace/:productID/service/:productDetailsID"
                    element={
                        <PrivateRoute>
                            <UploadService />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/onboarding/workspace/:productID/offering/:productDetailsID"
                    element={
                        <PrivateRoute>
                            <OfferingPageWorkspace />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/onboarding/workspace/:productID/pricing/:productDetailsID"
                    element={
                        <PrivateRoute>
                            <PricngPage />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/onboarding/workspace/:productID/final/:productDetailsID"
                    element={
                        <PrivateRoute>
                            <WorkspaceFinalPage />
                        </PrivateRoute>
                    }
                />

                <Route
                    path="/onboarding/experience/:productID"
                    element={
                        <PrivateRoute>
                            <ExperiencePage />
                        </PrivateRoute>
                    }
                />
                {/* continue onboarding */}
                <Route
                    path="/onboarding/experience/:productID/:productDetailsID"
                    element={
                        <PrivateRoute>
                            <ExperiencePage />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/onboarding/experience/upload/:productID"
                    element={
                        <PrivateRoute>
                            <ExperienceUploadPage />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/onboarding/experience/offering/:productID"
                    element={
                        <PrivateRoute>
                            <ExperienceOfferingPage />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/onboarding/experience/final/:productID"
                    element={
                        <PrivateRoute>
                            <ExperienceFinalPage />
                        </PrivateRoute>
                    }
                />

                {/* <Route
          path="/onboarding/workspace/:productID"
          element={
            <PrivateRoute>
              <FirstPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/onboarding/workspace/:productID/upload/:productDetailsID"
          element={
            <PrivateRoute>
              <UploadPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/onboarding/workspace/:productID/offering/:productDetailsID"
          element={
            <PrivateRoute>
              <OfferingPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/onboarding/workspace/:productID/setup/:productDetailsID"
          element={
            <PrivateRoute>
              <SetupPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/onboarding/workspace/:productID/final/:productDetailsID"
          element={<ExperienceFinalPage />}
        /> */}

                {/* continue onboarding */}
                <Route
                    path="/onboarding/experience/:productID/:productDetailsID"
                    element={
                        <PrivateRoute>
                            <ExperiencePage />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/onboarding/experience/upload/:productID/:productDetailsID"
                    element={
                        <PrivateRoute>
                            <ExperienceUploadPage />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/onboarding/experience/offering/:productID/:productDetailsID"
                    element={
                        <PrivateRoute>
                            <ExperienceOfferingPage />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/onboarding/experience/final/:productID/:productDetailsID"
                    element={
                        <PrivateRoute>
                            <ExperienceFinalPage />
                        </PrivateRoute>
                    }
                />

                <Route
                    path="/user-profile/*"
                    element={
                        <PrivateRoute>
                            <UserProfile />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/messages"
                    element={
                        <PrivateRoute>
                            <Messages />
                        </PrivateRoute>
                    }
                />

                <Route
                    path="/notification"
                    element={
                        <PrivateRoute>
                            <Notification />
                        </PrivateRoute>
                    }
                />

                <Route path="/hotel" element={<ProductListing />} />
                <Route path="/hotels" element={<ProductListing />} />

                <Route path="/hotel/:detailsID" element={<ProductDetails />} />
                <Route path="/partner/:partnerName/:detailsID" element={<ProductDetails />} />
                <Route path="/hotels/:detailsID" element={<ProductDetails />} />

                <Route
                    path="/hotel/:detailsID/reservation"
                    element={
                        // <PrivateRoute>
                            <ProductReservation />
                        // </PrivateRoute>
                    }
                />
                <Route
                    path="/hotels/:detailsID/reservation"
                    element={
                        // <PrivateRoute>
                            <ProductReservation />
                        // </PrivateRoute>
                    }
                />

                <Route path="/apartment" element={<ProductListing />} />
                <Route path="/apartments" element={<ProductListing />} />

                <Route path="/apartment/:detailsID" element={<ProductDetails />} />
                <Route path="/apartments/:detailsID" element={<ProductDetails />} />

                <Route
                    path="/apartment/:detailsID/reservation"
                    element={
                        // <PrivateRoute>
                            <ProductReservation />
                        // </PrivateRoute>
                    }
                />
                <Route
                    path="/apartments/:detailsID/reservation"
                    element={
                        // <PrivateRoute>
                            <ProductReservation />
                        // </PrivateRoute>
                    }
                />

                <Route path="/experience" element={<ProductListing />} />
                <Route path="/experiences" element={<ProductListing />} />
                <Route path="/experience/:detailsID" element={<ProductDetails />} />
                <Route path="/experiences/:detailsID" element={<ProductDetails />} />
                <Route
                    path="/experience/:detailsID/reservation"
                    element={
                        // <PrivateRoute>
                            <ProductReservation />
                        // </PrivateRoute>
                    }
                />
                <Route
                    path="/experiences/:detailsID/reservation"
                    element={
                        // <PrivateRoute>
                            <ProductReservation />
                        // </PrivateRoute>
                    }
                />

                <Route path="/workspace" element={<ProductListing />} />
                <Route path="/workspaces" element={<ProductListing />} />
                <Route path="/workspace/:detailsID" element={<ProductDetails />} />
                <Route path="/workspaces/:detailsID" element={<ProductDetails />} />
                <Route
                    path="/workspace/:detailsID/reservation"
                    element={
                        // <PrivateRoute>
                            <ProductReservation />
                        // </PrivateRoute>
                    }
                />
                <Route
                    path="/workspaces/:detailsID/reservation"
                    element={
                        // <PrivateRoute>
                            <ProductReservation />
                        // </PrivateRoute>
                    }
                />

                <Route path="/activities" element={<ProductListing />} />
                <Route
                    path="/activities/:detailsID"
                    element={
                        // <PrivateRoute>
                            <ActivitiesProductDetail />
                        // </PrivateRoute>
                    }
                />
                <Route
                    path="/checkout-complete/activity/:detailID"
                    element={
                        // <PrivateRoute>
                            <CheckoutComplete />
                        // </PrivateRoute>
                    }
                />

                <Route
                    path="/checkout-complete/:detailsID"
                    element={
                        // <PrivateRoute>
                            <CheckoutComplete />
                        // </PrivateRoute>
                    }
                />

                <Route
                    path="/instant-checkout/:detailsID"
                    element={
                        <PrivateRoute>
                            <InstantCheckout />
                        </PrivateRoute>
                    }
                />

                
                <Route
                    path="/confirm-pay/:detailsID"
                    element={
                        // <PrivateRoute>
                            <Payment />
                        // </PrivateRoute>
                    }
                />
                <Route
                    path="/confirm-pay/activity"
                    element={<ActivityPayment />}
                />
                <Route
                    path="/confirm-pay/experience"
                    element={
                        <PrivateRoute>
                            <Payment />
                        </PrivateRoute>
                    }
                />
                {/* <Route
                    path="/whatsapp"
                    element={
                        <WhhatAppWebHook/>
                    }
                /> */}

                <Route path="*" element={<Homepage />}  />

            </Routes>
        {/* </Translator> */}
        </Suspense>
    </BrowserRouter>
    </CacheBuster>
)};

export default AppRoutes;