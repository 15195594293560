import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import MenuBar from './Menubar';
import { AdminServices } from 'services/adminService';
import { useLocation } from 'react-router-dom';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { Dots } from 'components/common/Loader';
import { useToast } from 'components/common/Toast/ToastProvider';
import StartMsgModal from './StartMsgModal';

interface MyFormValues {
    bookingId: number | string;
    messageSubject: string;
    messageBody: string;
    toName: string;
}

function NewInbox({
    setPageItem,
    getAllMessages,
}: {
    setPageItem: Dispatch<SetStateAction<string>>;
    getAllMessages: () => void;
}) {
    const { search } = useLocation();
    const query = useMemo(() => new URLSearchParams(search), [search]);
    const bookingId: any = query.get('bookingId');
    const name: any = query.get('name');

    const toast = useToast();

    const [openModal, setOpenModal] = useState(false);

    const editor = useEditor({
        extensions: [StarterKit],
        content: '',
    });

    const msgSchema = Yup.object().shape({
        bookingId: Yup.string().required('BookingId is required'),
        messageSubject: Yup.string().required('message subject is required'),
    });

    const initialValues: MyFormValues = {
        bookingId: Number(bookingId),
        toName: name == "null" ? "Host name will show up when BE update" : name,
        messageSubject: '',
        messageBody: '',
    };

    const resetMsgForm = (resetForm: any, editor: any) => {
        resetForm({
            values: {
                bookingId: bookingId,
                toName: name == "null" ? "Host name will show up when BE update" : name,
                messageSubject: '',
                messageBody: '',
            },
        });
        editor.commands.clearContent();
    };

    useEffect(() => {
        if (!bookingId || !name) setOpenModal(true);
    }, [bookingId, name]);

    console.log(openModal);

    if (!editor) return null;
    return (
        <>
            <div className="flex-1  bg-white rounded-md border h-screen">
                <div className="bg-gray-200">
                    <h1 className="text-zinc-800 text-lg py-3 px-5 font-medium">New Message</h1>
                </div>
                <Formik
                    initialValues={initialValues}
                    validationSchema={msgSchema}
                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                        if (!editor?.getText()) return toast?.error("Message body can't be empty!");
                        setSubmitting(true);
                        try {
                            const resp = await AdminServices.createMessage({
                                ...values,
                                messageBody: editor.getText({ blockSeparator: '\n\n' }),
                                // messageBody: editor.getHTML(),
                            });
                            toast?.success('Message sent!');
                            setSubmitting(false);
                            setPageItem('Outbox');
                            getAllMessages();
                        } catch (error) {
                            console.log(error);
                            setSubmitting(false);
                            toast?.error('Message sending failed, please try again!');
                        }
                    }}
                >
                    {({ errors, isSubmitting, resetForm }) => (
                        <Form className=" w-full my-5 px-4">
                            <div className="w-full relative  mb-2 flex items-center">
                                <span className="absolute bottom-[30%] left-4 text-gray-400"> To:</span>
                                <Field
                                    type="text"
                                    name="toName"
                                    disabled={true}
                                    className="border border-gray-300 outline-none p-3 pl-12 w-full rounded-md"
                                />
                            </div>

                            <br />
                            <div className="w-full relative  mb-2 flex items-center">
                                <span className="absolute bottom-[30%] left-4 text-gray-400">Subject:</span>
                                <Field
                                    type="text"
                                    name="messageSubject"
                                    className="border border-gray-300 outline-none p-3 pl-20 w-full rounded-md"
                                />
                            </div>

                            <div className="bg-gray-200 rounded-[1rem] p-4 mt-10">
                                <MenuBar editor={editor} />
                                <EditorContent editor={editor} />
                            </div>

                            <div className="flex mt-5">
                                <button className="bg-auraPrimary text-white w-28  py-3 rounded-md">
                                    {isSubmitting ? <Dots /> : 'Send'}
                                </button>
                                <button
                                    type="button"
                                    className="border-auraPrimary border  text-auraPrimary w-28 py-3 rounded-md ml-5"
                                    onClick={() => {
                                        resetMsgForm(resetForm, editor);
                                    }}
                                >
                                    Discard
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
            <StartMsgModal openModal={openModal} setPageItem={setPageItem} />
        </>
    );
}

export default NewInbox;
