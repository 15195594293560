function padTo2Digits(num: number | string) {
  return num.toString().padStart(2, '0');
}

export const today = new Date();
export const tommorrow = new Date(new Date().getTime() + 1000 * 60 * 60 * 24);
export const sixMonths = new Date(new Date().getTime() + 1000 * 60 * 60 * 4380);



export default function formatDate(date: Date) {
  return [
    padTo2Digits(date?.getDate()),
    padTo2Digits(date?.getMonth() + 1),
    date.getFullYear(),
  ].join('/');
}

export function dashDateFormat(date: Date) {
  return [
    padTo2Digits(date?.getDate()),
    padTo2Digits(date?.getMonth() + 1),
    date.getFullYear(),
  ].join('-');
}


// 👇️ 24/10/2021 (dd/mm/yyyy)
//console.log(formatDate(new Date()));
