import { FC, useEffect, useState } from 'react';
import { Header, Footer, Modal, PlacesCard } from 'components/common';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { RootState } from 'redux/rootReducer';
import productService from 'services/productService';
import { Spinner } from 'components/common/Loader';
import homeIcon from 'assets/icon/home.svg';
import reserveModalIcon from 'assets/icon/reserve-modal.svg';
import type { RadioChangeEvent } from 'antd';
import { Radio } from 'antd';
import bookingService from 'services/bookingService';
import formatDate, {
  dashDateFormat,
  today,
  tommorrow,
} from 'utils/date/dateFormat';
import RightReservationSection from 'pages/ReusableProduct/RightReservationSection';
import Coupon from './Coupon';
import BankTransfer from './BankTransfer';
import CreditDebitCard from './CreditDebitCard';
import PaySmallSmall from './PaySmallSmall';
import KFC from 'assets/img/KFC.png';
import RoadChef from 'assets/img/roadchef.png';
import BeachBed from 'assets/img/beachbed.png';
import RestaurantUnderground from 'assets/img/restaurantunderground.png';
import { confirmPayment, verifyPayment } from 'redux/payment/actions';
import rate from 'assets/icon/rate.svg';
import moment from 'moment';
import calendarIcon from 'assets/icon/calendar.svg';
import person_drawIcon from 'assets/icon/calendar.svg';
import useeer from 'assets/icon/useer.svg';
import { UpcomingActivityServices } from 'services/upcomingActivities';
import dataService from 'services/appData';
import ProductItem from '../Homepage/product-item';
import emptyImage from 'assets/img/emptyImage.png';
import { AuxService } from 'services/auxService';
import { IOrderDetails } from 'services/auxService/interface';

const InstantCheckout: FC = () => {
  const { isAuthenticated, user } = useSelector(
    (state: RootState) => state.user
  );
  const { selectedProduct, selectedProductDetail, totalPrice } = useSelector(
    (state: RootState) => state.product
  );
  const { payment, paymentVerification } = useSelector(
    (state: RootState) => state.payment
  );
  const { createdQuote, selectedBooking } = useSelector(
    (state: RootState) => state.reservation
  );

  const [productDetails, setproductDetails] = useState<Record<string, any>>({});
  const [isLoading, setisLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { detailsID } = useParams<{ detailsID: string }>();
  const { detailID } = useParams<{ detailID: string }>();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const orderId = queryParams.get('orderId');
  const { filter } = useSelector((state: RootState) => state.filter);
  const [quotes, setQuotes] = useState();
  const [paymentDetails, setPaymentDetails] = useState<any>({});
  const [orderDetails, setorderDetails] = useState<IOrderDetails>();

  const [activityDetails, setactivity] = useState({
    title: '',
    pictures: [],
    activityCalendar: {
      date: null,
      time: null,
      title: ''
    }
  })

  async function getCompleteProductDetails(id: any) {
    // setisLoading(true)
    try {
      const res = await productService.getProductDetails(id);
      // console.log(res);

      setproductDetails(res.data['productDetails'])
      // setisLoading(false)
    } catch (error: any) {
      setisLoading(false)
      console.log(error);
    }
  }

  useEffect(() => {
    if (!detailsID) return;
    getCompleteProductDetails(detailsID)
  }, [detailsID])


  const path = window.location.pathname.toLowerCase();

  const [searchParams] = useSearchParams();
  // const detailID = searchParams.get('detailID');
  const paramGuest = searchParams.get('guest');
  const paramQty = searchParams.get('qty');
  const mode = searchParams.get('mode')

  const [activeRecommended, setactiveRecommended] = useState<any[]>([]);
  const [recommendedToShow, setrecommendedToShow] = useState(4);
  const recommendedSlice = activeRecommended.slice(0, recommendedToShow);

  const loadRecom = async () => {
    const recom_response = await dataService.getFeaturedProducts({
      productId: productDetails?.productID || 29
    });
    setactiveRecommended(recom_response.data);
  }

  useEffect(() => {
    loadRecom()
  }, [productDetails?.productID])

  return (
    <>
      <Header isAuthenticated={isAuthenticated} />
      {isLoading ? (
        <Spinner />
      ) : (
        <section className="bg-gray-50 py-1">
          <div className="container mb-36">
            <div className="flex flex-wrap gap-8 items-center justify-between my-10">
              <div>
                <Link
                  to="/"
                  className="flex items-center gap-3 border border-neutral108 rounded-md py-2 px-3 text-sm font-semibold text-neutral capitalize "
                >
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </span>
                  Go home
                </Link>
              </div>

              <div className="flex items-center flex-wrap  gap-3">
                <a
                  href="#"
                  className="text-sm text-neutral108 font-semibold capitalize"
                >
                  {productDetails?.productName || activityDetails?.activityCalendar?.title}
                </a>
                <span className="text-sm font-bold text-neutral108">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
                <a
                  href="#"
                  className="text-sm text-neutral108 font-semibold capitalize"
                >
                  Confirm and pay
                </a>
                <span className="text-sm font-bold text-neutral108">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
                <a
                  href="#"
                  className="text-sm text-neutral font-semibold capitalize"
                >
                  Checkout completed
                </a>
              </div>
            </div>

            <div className="grid lg:grid-cols-2 md:gap-x-16 gap-y-8">
              <div className="w-full">
                <img
                  src={
                    productDetails?.productBanner || productDetails?.productPicture || productDetails?.servicePicture
                    || activityDetails?.pictures?.[0]
                  }
                  className="w-full rounded-2xl h-full object-cover"
                  alt=""
                />
              </div>
              <div className="">
                <div className="space-y-5">
                  <div className=" space-y-3">
                    <h1 className=" md:text-5xl text-3xl font-medium ">
                      Congratulations!
                    </h1>
                    <p className=" text-neutral108 font-medium ">
                      {orderId ? " Your order has been confirmed! 🎉" : " Your reservation has been confirmed! 🎉"}
                    </p>
                  </div>

                  {path.includes('activity') ? (
                    <div className="bg-gray-100 rounded-2xl p-4 space-y-8">
                      <div className="flex items-center justify-between">
                        {paramQty &&
                          <div className="flex items-center space-x-3 ">
                            <div>
                              <img src={useeer} alt="" />
                            </div>
                            <div>
                              <p className="text-xs font-light text-neutral ">Quantity</p>
                              <p className="text-black text-base font-normal text-center pt-2">{
                                paramQty
                                  ?.replaceAll('?installmentNo=1', '')
                                  ?.replaceAll('?code=00', '')
                              }</p>
                            </div>
                          </div>
                        }
                        <div className="flex items-center space-x-3 ">
                          <div>
                            <img src={calendarIcon} alt="" />
                          </div>
                          <div>
                            <p className="text-xs font-light text-neutral ">Date</p>
                            <p className="text-black text-base font-normal text-center pt-2">
                              {activityDetails?.activityCalendar?.date
                                ? moment(activityDetails?.activityCalendar?.date).format('DD-MM-YYYY')
                                : ''}
                            </p>
                          </div>
                        </div>
                        <div className="flex items-center space-x-3 ">
                          <div>
                            <img src={calendarIcon} alt="" />
                          </div>
                          <div>
                            <p className="text-xs font-light text-neutral ">Time</p>
                            <p className="text-black text-base font-normal text-center pt-2">
                              {activityDetails?.activityCalendar?.time ? activityDetails?.activityCalendar?.time : ''}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                    : <>
                      <div className="pb-8 border-b space-y-3">
                        <h6 className=" text-base font-medium">
                          {productDetails?.productName}
                        </h6>
                        <p className=" text-neutral108 font-light text-base">
                          Hosted by{' '}
                          <span className="text-black font-normal">
                            {productDetails?.parentCompany}
                          </span>
                        </p>
                      </div>
                      <div className="flex items-center">

                        <div className="flex items-center gap-3">
                          <div className="ml-1 font-semibold tx-sm flex items-center space-x-3">
                            {selectedProductDetail?.productDetails?.rating > 0 ? (
                              <span className='flex items-center space-x-2'>
                                {`${selectedProductDetail?.productDetails?.rating} `}
                                <img src={rate} alt="" />
                                <span>ratings</span>
                              </span>

                            ) : (
                              'No rating'
                            )}
                            <span className="ml-2 font-light text-neutral">
                              (
                              {selectedProductDetail?.productDetails?.noOfReviews >
                                0
                                ? `${selectedProductDetail?.productDetails?.noOfReviews} reviews`
                                : 'No review'}
                              )
                            </span>
                          </div>
                          <p className="text-xs text-neutral108">
                            {productDetails?.productName}
                          </p>
                        </div>
                      </div>
                      <div className="bg-neutral107 rounded-2xl p-4 space-y-8 lg:w-[90%]">
                        <div className="flex items-center justify-between">
                          <div className="flex items-center space-x-3 ">
                            <div>
                              <img src="../../img/icon/useer.svg" alt="" />
                            </div>
                            <div>
                              <p className="text-xs font-light text-neutral ">
                                Guest
                              </p>
                              <p className="text-black text-base font-normal">
                                {
                                  location.state?.guest
                                }
                              </p>
                            </div>
                          </div>

                          {/* <div className="flex items-center  space-x-3 ">
                        <div>
                          <img src="../../img/Combined-Shape.svg" alt="" />
                        </div>
                        <div>
                          <p className="text-xs font-light text-neutral ">
                            Call Host
                          </p>
                          <p className="text-black text-base font-normal">
                            {productDetails?.phoneNumber}{' '}
                          </p>
                        </div>
                      </div> */}
                        </div>
                        <div className="flex items-center justify-between">
                          <div className="flex items-center space-x-3 ">
                            <div>
                              <img src="../../img/icon/calendar.svg" alt="" />
                            </div>
                            <div>
                              <p className="text-xs font-light text-neutral ">
                                Check-in
                              </p>
                              <p className="text-black text-base font-normal">

                                {location.state?.checkin}
                              </p>
                            </div>
                          </div>

                          
                        </div>
                      </div>
                    </>
                  }
                 
                  <div className="flex items-center gap-6">
                    {
                      (path.includes('activity') ? ''
                         :
                         orderId ?<Link
                         to="/user-profile/my-orders"
                         className="py-2 px-3 border rounded-md text-black capitalize text-base font-medium"
                       >
                         view order
                       </Link> :
                        <Link
                          to="/user-profile/bookings"
                          className="py-2 px-3 border rounded-md text-black capitalize text-base font-medium"
                        >
                          view booking
                        </Link>
                      )
                    }
                    <Link
                      to="/"
                      className="py-2 px-5 bg-auraPrimary rounded-md capitalize text-base font-medium text-white hover:text-white"
                    >
                      back home
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className=" space-y-10 my-36">
              <div className="space-y-3">
                <h3 className="text-3xl font-medium">You might also like</h3>
                <p>Based on our choices on trending places</p>
              </div>

              <div className="grid grid-cols-1 justify-center items-stretch gap-8 mt-10 sm:grid-cols-2 xl:grid-cols-4">
                {recommendedSlice.map((item: any, key: number) => (
                  <ProductItem
                    key={key}
                    name={item.productName}
                    bedrooms={15}
                    location={`${item.city ? item.city : 'Ikeja'}, ${item.stateName ? item.stateName : 'Lagos'}`}
                    image={item.productPicture ? item.productPicture : emptyImage}
                    productDetailsID={item?.productDetailsID ? item?.productDetailsID : ''}
                    productType={item.product ? item.product : 'hotel'}
                    corporatePrice={item?.corporatePrice || 0}
                  />
                ))}
              </div>

              {/* <div className="grid grid-cols-2 lg:grid-cols-4 gap-14">
                <PlacesCard image={KFC} title="KFC" />
                <PlacesCard image={RoadChef} title="Road Chef" />
                <PlacesCard image={BeachBed} title="Landmark Beach" />
                <PlacesCard image={RestaurantUnderground} title="Kapadoccia" />
              </div> */}
            </div>
          </div>
        </section>
      )}
      <Footer />
    </>
  );
};

export default InstantCheckout;