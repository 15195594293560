import { createSlice } from '@reduxjs/toolkit';

export type currentProductType = {
  productID: string | number;
  productName: string;
};
export interface ProductInitialState {
  product: Record<string, any>;
  hotelSearch: Record<string, any>;
  apartmentSearch: Record<string, any>;
  selectedProduct: Record<string, any>;
  selectedProductDetail: Record<string, any>;
  selectedProductReview: Record<string, any>;
  isLoading: boolean;
  error?: Record<string, any> | null;
  selectedProductServices: Array<any>;
  totalPrice: number | string;
  isProductFilterButtonClicked: boolean;
  currentProductType: currentProductType;
}

const productInitialState: ProductInitialState = {
  product: {},
  currentProductType: {
    productID: 29,
    productName: 'Hotels',
  },
  hotelSearch: {},
  apartmentSearch: {},
  selectedProduct: {},
  selectedProductDetail: {},
  selectedProductReview: {},
  isLoading: false,
  isProductFilterButtonClicked: false,
  selectedProductServices: [
    {
      id: 0,
      originalProductPrice: 0,
      numberAvailable: 0,
      newProductPrice: 0,
      count: 0,
    },
  ],
  totalPrice: 0,
  error: null,
};

function startLoading(state: ProductInitialState): void {
  state.isLoading = true;
}

function stopLoading(
  state: ProductInitialState,
  action: { payload: Record<string, any> | null }
): void {
  state.isLoading = false;
  state.error = action.payload;
}

function clearLoading(state: ProductInitialState, payload: any) {
  state.isLoading = false;
  state.error = null;
}

const product = createSlice({
  name: 'product',
  initialState: productInitialState,
  reducers: {
    loadingStart: startLoading,
    loadingStopped: stopLoading,
    productSuccess: (state: any, { payload }: any): void => {
      state.product = payload;
      clearLoading(state, payload);
    },
    hotelSearch: (state: ProductInitialState, { payload }: any): void => {
      state.hotelSearch = payload;
      clearLoading(state, payload);
    },
    apartmentSearch: (state: ProductInitialState, { payload }: any): void => {
      state.apartmentSearch = payload;
      clearLoading(state, payload);
    },
    selectedProduct: (state: ProductInitialState, { payload }: any): void => {
      state.selectedProduct = payload;
      clearLoading(state, payload);
    },
    selectedProductDetail: (
      state: ProductInitialState,
      { payload }: any
    ): void => {
      state.selectedProductDetail = payload;
      clearLoading(state, payload);
    },
    selectedProductReview: (
      state: ProductInitialState,
      { payload }: any
    ): void => {
      state.selectedProductReview = payload;
      clearLoading(state, payload);
    },
    addProductData(state: ProductInitialState, { payload }: any): void {
      state.product = payload;
    },
    clearProductData(state: ProductInitialState): void {
      state.product = productInitialState;
    },
    setProductPrice(state: ProductInitialState, { payload }: any): void {
      state.selectedProductServices = payload.selectedServices;
      state.totalPrice = payload.totalPrice;
    },
    setProductFilterButtonClick(
      state: ProductInitialState,
      { payload }: any
    ): void {
      state.isProductFilterButtonClicked = payload;
    },
    setCurrentProductType(state: ProductInitialState, { payload }: any): void {
      state.currentProductType = payload;
    },
    resetProductType(state: ProductInitialState): void {
      state.currentProductType = {
        productID: 29,
        productName: 'Hotels',
      };
    },
    clearSelectedProductServices(state: ProductInitialState): void {
      state.selectedProductServices = [];
    },
  },
});

export const {
  loadingStart,
  loadingStopped,
  productSuccess,
  hotelSearch,
  apartmentSearch,
  selectedProduct,
  selectedProductDetail,
  selectedProductReview,
  addProductData,
  clearProductData,
  setProductPrice,
  setProductFilterButtonClick,
  clearSelectedProductServices,
  setCurrentProductType,
  resetProductType
} = product.actions;

export default product.reducer;
