import React from 'react';

function HelpCenterPageModal({ modalData, setModal }: any) {
    return (
        <div
            className="fixed text-gray-700 top-0 left-0 transition bg-[#000000b3] delay-150 duration-300 ease-in-out right-0 z-50 w-full overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full justify-center modalDatas-center flex"
            aria-modal="true"
            role="dialog"
        >
            <div className="relative w-full h-full max-w-lg p-6 xl:max-w-2xl md:h-auto">
                <div className="relative bg-white rounded-lg shadow">
                    <div className="card  relative pb-2 mt-5 rounded px-2 bg-white ">
                        <div className="flex mx-1 my-2 pt-2 justify-between items-center modalDatas-center">
                            <div className="flex items-center space-x-2">
                                <h2>{modalData.title}</h2>
                                <div className="border-4 mx-1 my-1 rounded-full">
                                    <div className="p-1">
                                        <svg
                                            width="12"
                                            height="12"
                                            viewBox="0 0 384 512"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M202.021 0C122.202 0 70.5029 32.703 29.9139 91.026C22.5509 101.606 24.8209 116.112 35.0919 123.9L78.2299 156.609C88.6029 164.474 103.362 162.635 111.483 152.461C136.532 121.08 155.113 103.012 194.24 103.012C225.004 103.012 263.056 122.811 263.056 152.643C263.056 175.195 244.439 186.777 214.063 203.807C178.64 223.667 131.764 248.383 131.764 310.212V320C131.764 333.255 142.509 344 155.764 344H228.235C241.49 344 252.235 333.255 252.235 320V314.227C252.235 271.367 377.503 269.582 377.503 153.6C377.504 66.256 286.902 0 202.021 0ZM192 373.459C153.804 373.459 122.729 404.534 122.729 442.73C122.729 480.925 153.804 512 192 512C230.196 512 261.271 480.925 261.271 442.729C261.271 404.533 230.196 373.459 192 373.459Z"
                                                fill="black"
                                            />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <button onClick={() => setModal(false)} className="text-4xl text-gray-700">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6 cursor-pointer text-auraPrimary"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    stroke-width="2"
                                >
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                                </svg>
                            </button>
                        </div>

                        <div className="h-60 text-gray-700">
                            <img src={modalData.image} className="w-full h-full object-cover" alt="" />
                        </div>
                        <div className="py-3 px-3 text-gray-700">
                            {modalData.subtitle && (
                                <b className="mb-5">
                                    {modalData.subtitle} <br />
                                </b>
                            )}
                            <p>
                                {modalData.paragraph} <br />{' '}
                            </p>
                            {modalData.headline1 && <b>{modalData.headline1}</b>}
                            {modalData.paragraph1 && (
                                <p>
                                    {modalData.paragraph1} <br />{' '}
                                </p>
                            )}
                            {modalData.headline2 && <b>{modalData.headline2}</b>}
                            {modalData.paragraph2 && (
                                <p>
                                    {modalData.paragraph2} <br />{' '}
                                </p>
                            )}
                            {modalData.headline3 && <b>{modalData.headline3}</b>}
                            {modalData.paragraph3 && (
                                <p>
                                    {modalData.paragraph3} <br />{' '}
                                </p>
                            )}
                            {modalData.headline4 && <b>{modalData.headline4}</b>}
                            {modalData.paragraph4 && (
                                <p>
                                    {modalData.paragraph4} <br />{' '}
                                </p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HelpCenterPageModal;
