import service from 'services/fetchInterceptor';
import { CreateReviewData, ServiceType } from './types';

const reviewService: ServiceType | any = {};

reviewService.createReview = (data: CreateReviewData): Promise<any> =>
  service({
    url: '/v1/Review/create-review',
    method: 'post',
    data,
  });
  reviewService.replyReview = (data: { reviewID: number, message: string }): Promise<any> =>
  service({
    url: '/v1/Review/reply-review',
    method: 'post',
    data,
  });
  reviewService.getReviewReply = (reviewID: number): Promise<any> =>
  service({
    url: '/v1/Review/get-review-by-id/' + reviewID,
    method: 'get',
  });
reviewService.createRatingReason = (data:any): Promise<any> =>
  service({
    url: '/v1/RatingReason/create-rating-reason',
    method: 'post',
    data,
  });
  reviewService.updateReview = (data: CreateReviewData): Promise<any> =>
  service({
    url: '/v1/Review/update-review',
    method: 'post',
    data,
  });
  
  reviewService.getAllReviews = (): Promise<any> =>
  service({
    url: `v1/Review/get-all-review`,
    method: 'get',
    headers: { 'public-request': 'true' },
  });

  reviewService.getReviewByReviewID = (id:string|number): Promise<any> =>
  service({
    url: `v1/Review/get-review-by-id/${id}`,
    method: 'get',
    headers: { 'public-request': 'true' },
  });

reviewService.getReviewsByProductDetailsID = (
  id: string | number,
): Promise<any> =>
  service({
    url: `v1/Review/get-review-product-details-by-id/${id}`,
    method: 'get',
    headers: { 'public-request': 'true' },
  });

reviewService.deleteReview = (reviewID: string | number): Promise<any> =>
  service({
    url: `v1/Review/delete-review-by-id/${reviewID}`,
    method: 'put',
    headers: { 'public-request': 'true' },
  });

export default reviewService;
