import { ICreateActivityCalendar } from 'services/adminService/interface';
import service from 'services/fetchInterceptor';

class UpcomingActivityService {
    getAllActiveActivityCalendar(pageNumber: number, pageSize: number, searchVal: string): Promise<any> {
        return service({
            url: `v1/ActivityCalendar/get-all-activity-calendar/active?PageNumber=${pageNumber || 1}&PageSize=${
                pageSize || 12
            }&SearchVal=${searchVal || 'all'}`,
            method: 'get',
        });
    }

    getAllActivityCalendar(): Promise<any> {
        return service({
            url: `v1/ActivityCalendar/get-all-activity-calendar`,
            method: 'get',
        });
    }

    getActivityCalendar(id: number|string| undefined): Promise<any> {
        return service({
            url: `v1/ActivityCalendar/get-activity-calendar/${id}`,
            method: 'get',
        });
    }

    getActivityCategoryById(id: number): Promise<any> {
        return service({
            url: `v1/get-activity-calendar-category-by-id/${id}`,
            method: 'get',
        });
    }

    getActivityCalendarCategories(): Promise<any> {
        return service({
            url: `v1/get-all-activity-calendar-category`,
            method: 'get',
        });
    }

    createActivityCalendar(data: ICreateActivityCalendar): Promise<any> {
        return service({
            url: `v1/ActivityCalendar/activity-calendar/create`,
            method: 'post',
            data,
        });
    }

    shareActivityCategoryById(id: number): Promise<any> {
        return service({
            url: `v1/ActivityCalendar/shared-activity-calendar/${id}`,
            method: 'get',
        });
    }

    contautus(data: any): Promise<any> {
        return service({
            url: `v1/Contact/ContactUs`,
            method: 'post',
            data,
        });
    }
}

export const UpcomingActivityServices = new UpcomingActivityService();
