import { createSlice } from '@reduxjs/toolkit';
// import { NavigateFunction } from 'react-router-dom';
// import authService from 'services/authService';

export interface UserInitialState {
  isAuthenticated: boolean;
  user: Record<string, any>;
  isLoading: boolean;
  error: Record<string, any> | null;
  message?: string;
  profileUpdated: boolean;
  deviceTokenData:any;
  reloadCount:number;
}

const userInitialState: UserInitialState = {
  isAuthenticated: true,
  user: {},
  isLoading: false,
  error: null,
  message: '',
  profileUpdated: false,
  deviceTokenData:{},
  reloadCount:0,
};

function startLoading(state: UserInitialState): void {
  state.isLoading = true;
}



function stopLoading(
  state: UserInitialState,
  action: { payload: Record<string, any> | null }
): void {
  state.isLoading = false;
  state.error = action.payload;
  state.message = '';
}

const user = createSlice({
  name: 'user',
  initialState: userInitialState,
  reducers: {
    loadingStart: startLoading,
    loadingStopped: stopLoading,
    loginSuccess: (state: UserInitialState, { payload }:any): void => {
      state.isAuthenticated = true;
      state.user = payload;
      state.isLoading = false;
      state.error = null;
    },
    signupSuccess: (state: UserInitialState, { payload }:any): void => {
      state.message = payload;
      state.isLoading = false;
      state.error = null;
    },
    signupFailed:  (state: UserInitialState, { payload }:any): void => {
      state.error = payload;
      state.message = 'Signup Failed';
    },
    profileUpdateSuccessful:(state: UserInitialState): void=> {
      state.profileUpdated = true;
    },
    profileUpdateFailed:(state: UserInitialState): void=>  {
      state.profileUpdated = false;
    },
    clearError:(state: UserInitialState): void=>  {
      state.error = null;
    },
    logoutStart: startLoading,
    logoutSuccess: (state: UserInitialState): void => {
      state.user = {};
      state.isAuthenticated = false;
      state.isLoading = false;
      state.error = null;
      // state.reloadCount = 0;
      state.deviceTokenData ={};
    },
    logoutFailed: stopLoading,
    deviceTokenSuccess: (state: UserInitialState, { payload }:any): void => {
      state.deviceTokenData = payload;
    },
    setReloadCount: (state: UserInitialState, { payload }:any): void => {
      state.reloadCount = payload;
    },
  },
});

export const {
  loadingStart,
  loadingStopped,
  loginSuccess,
  signupSuccess,
  signupFailed,
  profileUpdateSuccessful,
  profileUpdateFailed,
  logoutStart,
  logoutSuccess,
  logoutFailed,
  clearError,
  deviceTokenSuccess,
  setReloadCount,
} = user.actions;

export default user.reducer;
