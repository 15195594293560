import { FilterProducts } from './types';

export const helperFeaturedRecommended = (data: FilterProducts) => {
    return (
        (data?.serviceAmenitiesId
            ? (data.serviceAmenitiesId || [])
                  .filter((id) => !(id === 0))
                  .map((id: number, index: number) => `?${index === 1 ? '&' : ''}ServiceAmenitiesId=${id}`)
            : ``) +
        (Boolean(data?.search?.trim())
            ? `${data?.serviceAmenitiesId && data?.serviceAmenitiesId[0] > 0 ? `&` : `?`}Search=${data?.search}`
            : ``) +
        (Boolean(data?.sortBy?.trim())
            ? `${(data?.serviceAmenitiesId && data?.serviceAmenitiesId[0] > 0) || data?.search?.trim() ? `&` : `?`}sortBy=${
                  data.sortBy
              }`
            : ``) +
        (Boolean(data.productId)
            ? `${
                  (data?.serviceAmenitiesId && data?.serviceAmenitiesId[0] > 0) || data.sortBy || data?.search ? `&` : `?`
              }productId=${data.productId}`
            : ``) +
        (Boolean(data.PartnerId)
            ? `${
                  (data?.serviceAmenitiesId && data?.serviceAmenitiesId[0] > 0) || data.sortBy || data?.search || data.productId ? `&` : `?`
              }PartnerId=${data.PartnerId}`
            : ``) +
        (Boolean(data?.latitude)
        ? `&latitude=${data?.latitude}`
        : ``
        ) +
        (Boolean(data?.longitude)
        ? `&longitude=${data?.longitude}`
        : ``
        )
        // + (Boolean(data?.rating)
        // ? `${data?.serviceAmenitiesId || data?.search?.trim() || data?.sortBy?.trim() || data.productId ? `&` : `?`}rating=${
        //       data?.rating
        //   }`
        // : ``)
    );
};
