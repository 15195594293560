import moment from 'moment';
import MsgList from './MsgList';

export default function Outbox({ outBox, setRelyMsg, setPageItem, searchedMessages }: any) {
    const handleMsgClick = (outBox: any) => {
        setRelyMsg(outBox);
        setPageItem('replyOutbox');
    };
    return (
        <div className="  bg-white rounded-md py-4 px-6 min-h-screen">
            <div className="flex justify-between">
                <h2 className="text-zinc-800 text-lg font-medium">Outbox</h2>
                <span className="tex-zinc-600 font-light">{moment(new Date()).format('DD/MM/YYYY')}</span>
            </div>

            {/* messaging listing section */}

            {searchedMessages && !searchedMessages?.length && outBox.length > 1 ? (
                <div className="text-center text-auraPrimary py-5">No results found</div>
            ) : null}
            {outBox.length ? null : <div className="text-center text-auraPrimary py-5">You have no outgoing messages</div>}
            {(searchedMessages || outBox)?.map((outBox: any) => (
                <MsgList key={outBox?.messageId} handleMsgClick={handleMsgClick} msgObj={outBox} />
            ))}
        </div>
    );
}
