import { FC, InputHTMLAttributes, useState } from 'react';
import { ErrorMessage, Field } from 'formik';
import { TbEyeOff, TbEye } from 'react-icons/tb';
import { ReactComponent as SmallArrow } from 'assets/icon/small-arrow.svg';
import newsletter from 'assets/img/newsletter.png';
import { useToast } from 'components/common/Toast/ToastProvider';
import MailchimpSubscribe from "react-mailchimp-subscribe"
import smallArrow from 'assets/icon/small-arrow.svg';

const NewsLetterSection: FC = () => {
    const validateEmail = (email : string) => {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
      };

    const url = `https://transcorphotels.us13.list-manage.com/subscribe/post?u=${process.env.REACT_APP_MAILCHIMP_U}&id=${process.env.REACT_APP_MAILCHIMP_ID}`;
    const [email, setEmail] = useState('');

    const toast:any = useToast();

    const handleSubmit= (e: any, subscribe: any, status: any, )  =>{
        e.preventDefault();
        if(!validateEmail(email)) return toast.error("Invalid email.")

        subscribe({
            EMAIL: email
        })
        status === "error" && toast.error("Error occurred, please try again.")

        status === "success" && toast.success('You have successfully subscribed to our newsletter.')

        status == null && toast.info('Email already exist or an error occurred. Please try again.')
    }

  return (
    <>
      
      <div className="container py-24 fit">
        <div className="flex flex-wrap items-center md:flex-nowrap ">
            <div className="flex flex-col order-2 space-y-8 items-star md:w-1/2 lg:pr-56 md:order-1 ">
                <h3 className="text-4xl font-medium md:pr-6">Views from the Penthouse 🎉</h3>
                <p className="text-base font-normal text-neutral ">Know about the best deals and exclusive offers before anyone else. Sign up for our newsletter.</p>
                <div className="space-y-2">
                    <div className="flex items-center gap-3">
                        <span className="py-1 px-4 text-white bg-[#58C27D] rounded-3xl">01</span>
                        <p className="text-base font-normal">Get more discount</p>
                    </div>
                    <div className="flex items-center gap-3">
                        <span className="py-1 px-4 text-white bg-[#92A5EF] rounded-3xl">02</span>
                        <p className="text-base font-normal ">Get premium travel magazine</p>
                    </div>
                </div>
                <MailchimpSubscribe
                    url={url}
                    render={({ subscribe, status, }: any) => (
                        <div>
                        <form action="" onSubmit={(e) =>{
                            e.preventDefault();
                            handleSubmit(e, subscribe, status)
                        }}>
                            <div className="flex rounded-full shadow-sm border px-1.5 py-1">
                                <input
                                    type="text"
                                    className="bg-gray-100 w-full border-0 focus:ring-0 no-show-input"
                                    placeholder="Enter your email"
                                    onChange={e=>setEmail(e.target.value)}
                                />
                                <button  className='Join_our_community_News_Letter' type='submit'>
                                    <img src={smallArrow} alt="" srcSet="" />
                                </button>
                            </div>
                        </form>
                        </div>
                    )}
                />
            </div>
            <div className="order-first mb-8 md:order-2"><img src={newsletter} className="w-full" alt="" />
            </div>
        </div>
    </div>
    </>
  );
};

export default NewsLetterSection;
