// import React from 'react'
import { Link } from 'react-router-dom';
import { RootState } from 'redux/rootReducer';
// import ProfilePhoto from 'assets/icon/user.svg';
import { ReactComponent as Messages } from 'assets/icon/comment.svg';
import { ReactComponent as Complain } from 'assets/icon/complain.svg';
import { ReactComponent as Email } from 'assets/icon/email.svg';
import { ReactComponent as Book } from 'assets/icon/home.svg';
import { ReactComponent as Flag } from 'assets/icon/flag.svg';
// import { Popover, Transition } from '@headlessui/react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from 'redux/authSlice/actions';
import { Button } from 'components/common';

// import { ReactComponent as Logo } from 'assets/img/logo.svg';
// import { ReactComponent as Bell } from 'assets/icon/bell.svg';


export default function MenuComponent(props: { setOpenModal1: (arg0: boolean) => void; }) {
    const { isAuthenticated } = useSelector((state: RootState) => state.user);
    const { user } = useSelector((state: RootState) => state.user);

    const navigate = useNavigate();
    const dispatch = useDispatch();

  return (
    <div className="bg-white md:w-64 p-4">
    {isAuthenticated && (
      <>
     
        <div className="flex flex-col gap-2">
          <Link
            to="/messages"
            className="flex items-center gap-[14px] hover:bg-gray-100 py-2 px-4"
          >
            <Messages />
            <p className="text-sm font-medium text-gray-4">
              Messages
            </p>
          </Link>
          <Link
            to="/user-profile/bookings"
            className="flex items-center gap-[14px] hover:bg-gray-100 py-2 px-4"
          >
            <Book />
            <p className="text-sm font-medium text-gray-4">
              Bookings
            </p>
          </Link>
          <Link
            to="/user-profile/wishlist"
            className="flex items-center gap-[14px] hover:bg-gray-100 py-2 px-4"
          >
            <Email />
            <p className="text-sm font-medium text-gray-4">
              Wishlists
            </p>
          </Link>
          {/* <div onClick={()=>props.setOpenModal1(true)}
            className="flex items-center gap-[14px] hover:bg-gray-100 py-2 px-4 cursor-pointer"
          >
            <Complain />
            <p className="text-sm font-medium text-gray-4">
              Complaints
            </p>
          </div> */}
          {/* {
            user.ishost ? (
              <Link
              to="/dashboard"
              className="flex items-center gap-[14px] hover:bg-gray-100 py-2 px-4"
            >
              <Flag />
              <p className="text-sm font-medium text-gray-4">
                Host Dashboard
              </p>
            </Link>
            )
            : ( */}
            <Link
              to="/onboarding"
              className="flex items-center gap-[14px] hover:bg-gray-100 py-2 px-4"
            >
              <Flag />
              <p className="text-sm font-medium text-gray-4">
                List a Property
              </p>
            </Link>
            {user.ishost && 
              <Link
                to="/dashboard"
                className="flex lg:hidden items-center gap-[14px] hover:bg-gray-100 py-2 px-4"
              >
                <Flag />
                <p className="text-sm font-medium text-gray-4">
                Host Dashboard
                </p>
              </Link>
            }
            <Link
            to="/faqs"
            className="flex items-center gap-[14px] hover:bg-gray-100 py-2 px-4"
          >
            <Messages />
            <p className="text-sm font-medium text-gray-4">
            Help Center
            </p>
          </Link>
            {/* )
          } */}
          
        </div>
        <div className="flex w-full h-10 gap-4 mt-6">
          <Button
            type="button"
            variant="primary"
            className="w-full"
            onClick={() =>
              navigate('/user-profile')
            }
          >
            Account
          </Button>
          <Button
            type="button"
            variant="neutral"
            className="w-full"
            onClick={() =>
              dispatch(logout(navigate))
            }
          >
            Logout
          </Button>
        </div>
      </>
    )}
  </div>  )
}
