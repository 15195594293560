import { useDispatch, useSelector } from 'react-redux';
import { saveGeoLocation } from 'redux/booking/action';
import store from '../redux/store'

 // DEFAULT LAGOS
export const defaultLongitude:any = 6.5568768;
export const defaultLatitude:any = 3.3685504;
    

export function getLocation() {
  // const dispatch = useDispatch();
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(function showPosition(position) {
      
// console.log('dydudu', position.coords.latitude, position.coords.longitude)
      store.dispatch(saveGeoLocation({
        latitude: position.coords.latitude
          ? position.coords.latitude
          : defaultLatitude,
        longitude: position.coords.longitude
          ? position.coords.longitude
          : defaultLongitude,
      }));

      return {
        latitude: position.coords.latitude
          ? position.coords.latitude
          : defaultLatitude,
        longitude: position.coords.longitude
          ? position.coords.longitude
          : defaultLongitude,
      };
    });
  } else {
    return {
      latitude: defaultLatitude,
      longitude: defaultLongitude,
    };
  }
}
