import { createSlice } from '@reduxjs/toolkit';

export interface PaymentInitialState {
  payment: Record<string, any>;
  isLoading: boolean;
  error: Record<string, any> | null;
  paymentVerification:Record<string, any>;
  paymentConfirmation:Record<string, any>;
}

const paymentInitialState: PaymentInitialState = {
  payment: {},
  isLoading: false,
  error: null,
  paymentVerification:{},
  paymentConfirmation:{},
};

function startLoading(state: PaymentInitialState): void {
  state.isLoading = false;
}

function stopLoading(
  state: PaymentInitialState,
  action: { payload: Record<string, any> | null }
): void {
  state.isLoading = false;
  state.error = action.payload;
}

function clearLoading(state: PaymentInitialState, payload: any) {
  state.isLoading = false;
  state.error = null;
}

const payment = createSlice({
  name: 'payment',
  initialState: paymentInitialState,
  reducers: {
    loadingStart: startLoading,
    loadingStopped: stopLoading,
    paymentInitialization: (
      state: PaymentInitialState,
      { payload }: any
    ): void => {
      state.payment = payload;
      clearLoading(state, payload);
    },
    paymentVerification: (
      state: PaymentInitialState,
      { payload }: any
    ): void => {
      state.paymentVerification = payload;
      clearLoading(state, payload);
    },
    paymentConfirmation: (
      state: PaymentInitialState,
      { payload }: any
    ): void => {
      state.paymentConfirmation = payload;
      clearLoading(state, payload);
    },
    paymentSuccess: (
      state: PaymentInitialState,
      { payload }: any
    ): void => {
      state.payment = payload;
      clearLoading(state, payload);
    },

  },
});

export const {
  loadingStart,
  loadingStopped,
  paymentInitialization,
  paymentVerification,
  paymentConfirmation
} = payment.actions;

export default payment.reducer;
