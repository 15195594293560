import { IOthQuote, IPhotoBooking, ServiceRequest } from 'pages/Photography/interface';
import service from 'services/fetchInterceptor';
import { CreateBookingData, ServiceType, CreateQuoteData, IVerifyPayment } from './types';

const bookingService: ServiceType | any = {};

bookingService.createBooking = (data: CreateBookingData): Promise<any> =>
    service({
        url: '/v1/Booking/create-booking',
        method: 'post',
        data,
    });
    bookingService.createGuestBooking = (data: CreateBookingData): Promise<any> =>
    service({
        url: '/v1/GuestBooking/guest-create-booking',
        method: 'post',
        data,
    });
bookingService.createPhotoBooking = (data: IPhotoBooking): Promise<any> =>
    service({
        url: '/v1/Booking/create-photography-booking',
        method: 'post',
        data,
    });

bookingService.createReservationForProduct = (data: IPhotoBooking): Promise<any> =>
service({
    url: '/v1/Booking/create-reservation-for-product',
    method: 'post',
    data,
});

bookingService.createOthQuote = (data: {productDetailsId: string | number}): Promise<{data: IOthQuote}> =>
service({
    url: '/v1/Booking/create-oth-quote',
    method: 'post',
    data,
});

bookingService.createExperienceBooking = (data: any): Promise<any> =>
    service({
        url: 'v1/Booking/create-experience-booking',
        method: 'post',
        data,
    });
bookingService.createActivityBooking = (data: any): Promise<any> =>
    service({
        url: 'v1/Booking/create-activity-calender-booking',
        method: 'post',
        data,
    });

bookingService.getAllBookings = (): Promise<any> =>
    service({
        url: `v1/Booking/get-bookings`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });

bookingService.getBookingByBookingID = (id: string | number): Promise<any> =>
    service({
        url: `v1/Booking/get-booking-by-id/${id}`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });
bookingService.getEmergencyContacts = (): Promise<any> =>
    service({
        url: `v1/Safety/GetEmergencyAgencies`,
        method: 'post',
        headers: { 'public-request': 'true' },
    });
bookingService.getAllBookingsByCustomerID = (id: number | string): Promise<any> =>
    service({
        url: `v1/Booking/get-all-booking-customer-id/${id}`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });

bookingService.getBookingsByProductID = (
    id: string | number,
    pageNumber?: number,
    pageSize?: number,
    searchKey?: string,
    sortBy?: string
): Promise<any> =>
    service({
        url: `v1/Booking/get-bookings-by-product-id?ProductID=${id}${searchKey ? `&searchKey=${searchKey}` : ``}${
            pageNumber ? `&PageNumber=${pageNumber}` : ``
        }${pageSize ? `&PageSize=${pageSize}` : ``}${sortBy ? `&sortBy=${sortBy}` : ``}`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });

bookingService.cancelBooking = (bookingID: string | number): Promise<any> =>
    service({
        url: `v1/Booking/cancel-booking/${bookingID}`,
        method: 'put',
        headers: { 'public-request': 'true' },
    });
bookingService.checkInStatus = (bookingID: string | number): Promise<any> =>
    service({
        url: `v1/Booking/checkin-status/${bookingID}`,
        method: 'put',
        headers: { 'public-request': 'true' },
    });
bookingService.cancelUserBooking = (data: { bookingID: string | number; reason: string }): Promise<any> =>
    service({
        url: `v1/Booking/user-cancel-booking`,
        method: 'put',
        data,
        headers: { 'public-request': 'true' },
    });

bookingService.createQuote = (data: CreateQuoteData): Promise<any> =>
    service({
        url: `v1/Booking/create-quote`,
        method: 'post',
        data,
        headers: { 'public-request': 'true' },
    });
bookingService.createPhotoQuote = (data: ServiceRequest): Promise<any> =>
    service({
        url: `v1/Booking/create-photography-quote`,
        method: 'post',
        data,
        headers: { 'public-request': 'true' },
    });

bookingService.verifyPayment = (data: IVerifyPayment): Promise<any> =>
    service({
        url: `v1/Booking/confirm/booking/payment/flutterwave`,
        method: 'post',
        data,
        headers: { 'public-request': 'true' },
    });

bookingService.verifyGuestPayment = (data: IVerifyPayment): Promise<any> =>
    service({
        url: `v1/GuestBooking/guest/confirm/booking/payment/flutterwave`,
        method: 'post',
        data,
        headers: { 'public-request': 'true' },
    });

bookingService.verifySeerbitPayment = (data: any): Promise<any> =>
    service({
        url: `v1/Booking/seerbit/transaction/verify`,
        method: 'post',
        data,
        headers: { 'public-request': 'true' },
    });
    
bookingService.verifySeerbitGuestPayment = (data: any): Promise<any> =>
    service({
        url: `v1/GuestBooking/guest/seerbit/transaction/verify`,
        method: 'post',
        data,
        headers: { 'public-request': 'true' },
    });

bookingService.initializeSeerbitPayment = (data: any): Promise<any> =>
    service({
        url: `v1/Booking/seerbit/transaction/init`,
        method: 'post',
        data,
        headers: { 'public-request': 'true' },
    });

    bookingService.initializeSeerbitGuestPayment = (data: any): Promise<any> =>
    service({
        url: `v1/GuestBooking/guest/seerbit/transaction/init`,
        method: 'post',
        data,
        headers: { 'public-request': 'true' },
    });

bookingService.createSafetyIncidence = (bookingId: number): Promise<any> =>
service({
    url: `v1/Safety/CreateSafetyIncidences?BookingId=${bookingId}`,
    method: 'post',
    headers: { 'public-request': 'true' },
});

bookingService.verifySeerbitPaymentWithoutBookingId = (data: any): Promise<any> =>
    service({
        url: `Payment/seer-bit-verification?paymentReference=${data[0]}`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });

bookingService.verifyFlutterwavePaymentWithoutBookingId = (data: any): Promise<any> =>
service({
    url: `Payment/flutterwave-verification?paymentReference=${data}`,
    method: 'get',
    headers: { 'public-request': 'true' },
});

bookingService.verifyFlutterwavePaymentActivityCalenderr = (data: any): Promise<any> =>
service({
    url: `v1/ActivityPayment/flutterwave-verification-activity?paymentReference=${data?.paymentReference}&bookingId=${data?.bookingId}`,
    method: 'get',
    headers: { 'public-request': 'true' },
});

bookingService.verifyFlutterwavePaymentActivityCalender = (data: any): Promise<any> =>
service({
    url: `Payment/flutterwave-verification-activity?paymentReference=${data?.paymentReference}&bookingId=${data?.bookingId}`,
    method: 'get',
    headers: { 'public-request': 'true' },
});

bookingService.createPromotion = (data: any): Promise<any> =>
service({
    url: '/v1/Advert/CreateServiceSpotlight',
    method: 'post',
    data,
});

bookingService.initSerbit = (data: any): Promise<any> =>
service({
    url: 'Payment/seer-bit-initialization',
    method: 'post',
    data,
});

bookingService.paysmallsmall = (data: any): Promise<any> =>
service({
    url: 'v1/Booking/calculate-paysmallsmall-charge',
    method: 'post',
    data,
});

bookingService.validateCorporateCode = (data: any): Promise<any> =>
service({
    url: 'v1/Booking/create-booking-with-corporate-code-new',
    method: 'post',
    data,
});

bookingService.fetchPaysmallsmallBreakdown = (bookingId: number): Promise<any> =>
service({
    url: `v1/Booking/get-booking-pay-small-small-list/${bookingId}`,
    method: 'get',
});

bookingService.applyReferralCode = (bookingId: number, ReferralCode: string, ServiceAmount: number ): Promise<any> =>
service({
    url: `v1/Referral/ApplyReferralCode?ReferralCode=${ReferralCode}&BookingId=${bookingId}&ServiceAmount=${ServiceAmount}`,
    method: 'post',
});

bookingService.createUserRefund = (data: CreateQuoteData): Promise<any> =>
service({
    url: `UserRefund/create-user-refund`,
    method: 'post',
    data,
    headers: { 'public-request': 'true' },
});

bookingService.getpaymentPlan = (): Promise<any> =>
service({
    url: `PaySmallSmallSetting/get-all-payment-plan`,
    method: 'get',
    headers: { 'public-request': 'true' },
});

bookingService.getActivityBookingById = (id: number): Promise<any> =>
service({
    url: `v1/Booking/get-activity-booking-by-id/${id}`,
    method: 'get',
    headers: { 'public-request': 'true' },
});

bookingService.verifySeerbitPaymentActivityCalender = (data: any): Promise<any> =>
    service({
        url: `Payment/seer-bit-verification-activity-calender?paymentReference=${data[0]}&bookingId=${data[1]}`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });

bookingService.verifySeerbitPaymentActivityCalenderr = (data: any): Promise<any> =>
    service({
        url: `v1/ActivityPayment/seer-bit-verification-activity-calender?paymentReference=${data[0]}&bookingId=${data[1]}`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });

bookingService.initializeSeerbitPaymentActivityCalenderr = (data: any): Promise<any> =>
    service({
        url: `v1/ActivityPayment/seer-bit-request-initialization`,
        method: 'post',
        data,
        headers: { 'public-request': 'true' },
    });

bookingService.initializeSeerbitPaymentActivityCalender = (data: any): Promise<any> =>
    service({
        url: `Payment/seer-bit-request-initialization`,
        method: 'post',
        data,
        headers: { 'public-request': 'true' },
    });

bookingService.validateDiscountCode = (data: any): Promise<any> =>
    service({
        url: 'v1/Booking/create-booking-discount-code',
        method: 'post',
        data,
    });

bookingService.checkoutWithLoyalty = (data: any): Promise<any> =>
service({
    url: 'v1/Booking/create-booking-with-point',
    method: 'post',
    data,
});

bookingService.getInsuranceList = (): Promise<any> =>
    service({
        url: `v1/Insurance/Products`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });

bookingService.selectInsurance = (data: any): Promise<any> =>
    service({
        url: 'v1/Booking/create-booking-with-insurance',
        method: 'post',
        data,
    });

bookingService.resetisDisountIsLoyalityApplied = (data: any): Promise<any> =>
    service({
        url: 'v1/Booking/perform-update-booking',
        method: 'post',
        data,
    });
export default bookingService;
