import { FC, Fragment, Dispatch, ReactNode, SetStateAction, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import styles from '../../components/common/newModal/modal.module.css';
import { Link } from 'react-router-dom';

interface ModalProps {
    openModal: boolean;
    setOpenModal?: Dispatch<SetStateAction<boolean>>;
    setPageItem: Dispatch<SetStateAction<string>>;
}

const StartMsgModal: FC<ModalProps> = ({ openModal, setOpenModal, setPageItem }) => {
    const cancelButtonRef = useRef(null);

    return (
        <Transition.Root show={openModal} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={() => {}}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel
                                className={`relative  bg-white rounded-lg text-left overflow-hidden shadow-xl  ${styles.modalWidth} transform transition-all sm:my-8`}
                            >
                                <div className=" p-6 md:px-8">
                                    <div className="flex justify-between mb-7">
                                        <div>
                                            <h2 className="text-lg">Start a new message</h2>
                                        </div>
                                        <button
                                            type="button"
                                            className="inline-flex items-center px-3 py-3 ml-auto text-sm bg-transparent rounded-lg  text-neutral hover:bg-auraPrimary hover:text-white"
                                            data-modal-toggle="role-modal"
                                            //   onClick={() => setOpenModal(false)}
                                            onClick={() => setPageItem('inbox')}
                                        >
                                            <svg
                                                className="w-5 h-5"
                                                fill="currentColor"
                                                viewBox="0 0 20 20"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                                    clipRule="evenodd"
                                                ></path>
                                            </svg>
                                        </button>
                                    </div>

                                    <div className="text-center">
                                        <div className="mb-4">Go to your ongoing booking to start a new massage</div>
                                        <Link
                                            to="/user-profile/bookings"
                                            className="bg-auraPrimary text-white px-3  py-3 rounded-md hover:text-white mb-4 block"
                                        >
                                            Go to booking
                                        </Link>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
};

export default StartMsgModal;
