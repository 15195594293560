import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { getMessaging, onMessage } from 'firebase/messaging';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_apiKey,
  authDomain: process.env.REACT_APP_authDomain,
  projectId: process.env.REACT_APP_projectId,
  storageBucket: process.env.REACT_APP_storageBucket,
  messagingSenderId: process.env.REACT_APP_messagingSenderId,
  appId: process.env.REACT_APP_appId,
  measurementId: process.env.REACT_APP_measurementId 
};

//From Unknown
export const apiKey_v1 = process.env.REACT_APP_apiKey_v1;

//From PM's google account => https://console.cloud.google.com/apis/credentials?project=aura-mobile-360407
export const apiKey_v2 = process.env.REACT_APP_apiKey_v2;

//From Aura's google account => https://console.cloud.google.com/google/maps-apis/credentials?project=aura-mobile-eb123
export const apiKey_v3 = process.env.REACT_APP_apiKey_v3;

export const linkedinClientId = process.env.REACT_APP_LINKEDIN_CLIENTID;

export const firebaseVapidKey = process.env.REACT_APP_firebaseVapidKey;

firebase.initializeApp(firebaseConfig);

const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);
export const messaging = getMessaging(firebaseApp);

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

export default firebase;
