import { useSelector } from 'react-redux';
import { Footer, Header } from 'components/common';
import { RootState } from 'redux/rootReducer';

export default function TermsOfService() {
    const { isAuthenticated } = useSelector((state: RootState) => state.user);

    return (
        <>
            <Header isAuthenticated={isAuthenticated} />
            <section className="container">
                <div className="mb-3 mt-10 px-20  pb-20 row">
                    {/* <div className="col-md-8 col-sm-12">
                        <div className="border-b-2 border-auraPrimary w-72">
                            <h2 className=" text-dark text-2xl font-semibold my-2 ">Cancellation Notice</h2>
                        </div>
                        <p className="text-gray-600 font-light text-md mb-3">
                            Guests are entitled to free cancellation and a full refund less transfer charges incurred by Aura, if the booking is cancelled not more than 48 hours after booking, and at least 14 days before check-in (time shown on the confirmation email).
                        </p>
                    </div> */}
                    <div className="col-md-8 col-sm-12">
                        <div className="border-b-2 border-auraPrimary w-72">
                            <h2 className=" text-dark text-2xl font-semibold my-2 ">Cancellation Policy</h2>
                        </div>
                        <p className="text-dark text-md font-medium my-2">
                        Guests are entitled to free cancellation and a full refund less transfer charges incurred by Aura, if the booking is cancelled 
                        <b> not more than 48 hours after booking, and at least 14 days before check-in </b> (time shown on the confirmation email).
                        </p>
                        <p className="text-gray-600 font-light text-md mb-3">
                            Where guests cancel their booking within 48 hours after booking and at least 14 full days 
                            prior to Listing’s local check-in time (shown in the confirmation email), they are entitled 
                            to a full refund of the nightly rate, less transfer charges incurred by Aura. 

                            Where guests cancel their booking less than 14 days but at least 7 days before the Listing's local check in time (shown in the confirmation email), they are entitled to a 50% refund of the nightly rate, less transfer charges. 
 
                            Where guests cancel their booking less than 5 days before the Listing's local check in time (shown in the confirmation email), they are entitled to a 25% refund of the nightly rate, less transfer charges. 

                            However, where the bookings were made through a Booking Agent or with a referral code, all refunds with respect to the above, shall be less transfer charges AND any commission paid to the Booking Agent or owner of the Referral Code. 

                            Check- In time may vary (as provided by property). 
                            Where guests choose to check-out early after check-in, the unspent nights are not refunded unless the check-out is due to proven issues with the property in which case we will relocate the person to a suitable property. 

                        </p>
                    </div>
                    <section id="Definitions">
                        <h3 className="text-dark text-lg font-semibold mt-3 titl">CANCELLATION POLICY FOR HOSTS</h3>

                        <p className="text-gray-600 font-light text-md mb-3">
                            <b className="text-black text-md font-medium underline mt-3 titl">Apartment Hosts: : - </b>shall be paid a 50% advance deposit at least 7 days prior to the check-in of the guest and the remainder within 24 hours after the Guest’s scheduled check-in time. 

                                Hosts are entitled to free cancellation, if the booking is cancelled prior to the advance deposit made by Aura. 

                                Where the Host cancels a booking within 48 hours of the advance payment but at least 5 days before the Guest’s check-in time (shown in the confirmation email), the Host shall make payment of 100% of the advance deposit paid by Aura with no cancellation charge.  

                        </p>
                        <p className="text-gray-600 font-light text-md mb-3">
                            <b className="text-black text-md font-medium underline mt-3 titl">Hotel Hosts:  : -</b>shall be paid by 2pm on the day of the Guest’s scheduled check-in time.  

                            Hosts are entitled to free cancellation up to 5 days ahead of the bookings. However, two cancellations within the month would earn the hosts certain sanctions. 
                        </p>
                    </section>
                    <section id="Collection">
                        <div className="border-b-2 border-auraPrimary w-32">
                            <h2 className=" text-dark text-lg font-semibold my-1 ">SANCTIONS</h2>
                        </div>
                        <p className="text-gray-600 font-light text-md mb-3">
                        Where the Host cancels confirmed bookings after receipt of advance payments up to two times within the same month, such host will be issued a warning and blacklisted on the Aura platform.

                        Where the Host cancels confirmed bookings after receipt of advance payments up to three times within the same quarter (Except for operational reasons), such host will be blacklisted on the Aura platform, and shall be removed and banned from the platform, at Aura’s discretion.


                        </p>
                        <p className="text-gray-600 font-light text-md mb-3">
                            
                            Hosts shall however not be held accountable for cancellations done on the day of the booking as a result of misleading/incorrect information from guests. 
                        </p>
                    </section>
                </div>
            </section>
            <Footer />
        </>
    );
}
