import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Modal from '.';
import success from "assets/img/success.svg";

interface SavedModal {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}
export default function ReviewSuccessModal(props: SavedModal) {

  const navigate = useNavigate();

  function redirectToHome(){
    navigate("/")
  }

  return (
    <>
      <Modal
        children={
          <div className="relative w-full max-w-xl px-3 py-2 bg-white shadow-xl rounded-xl lg:max-w-xl fit">
            <div className="p-3 text-center md:p-10 xl:px-8">
              <div className="flex justify-center">
                <img
                  src={success}
                  className="text-center"
                  alt=""
                  srcSet=""
                />
              </div>
              <h2 className="text-3xl font-bold">Review Sent!!</h2>
              <p className="py-3 text-lg text-neutral">
              Your review has been successfully sent.
              </p>
              <div className="my-10 ">
                <button
                  className="block w-full px-4 py-3 text-base text-white btn bg-auraPrimary hover:bg-auraPrimary hover:text-white my-btn"
                  onClick={redirectToHome}
                >
                  Go To Home
                </button>
              </div>
            </div>
          </div>
        }
        openModal={props.openModal}
        setOpenModal={props.setOpenModal}
      />
    </>
  );
}
