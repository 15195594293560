import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Modal from '.';
import lovedimage from '../../../assets/img/success.svg';
import loadingicon from '../../../assets/icon/loading.svg';
import {RiAlertFill} from "react-icons/ri";

interface SuccessModalProp {
  verifyloading: boolean;
  openModal: boolean;
  paymentSuccess: boolean
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}
export default function SuccessPayment(props: SuccessModalProp) {
  const dispatch = useDispatch();
  return (
    <>
      <Modal
        children={
          props.verifyloading ? <>
            <div className="successful-payment relative w-full max-w-xl px-3 py-2 bg-white shadow-xl rounded-xl lg:max-w-xl">
              <div className="p-3 text-center md:p-10 xl:px-24">
                <div className="flex justify-center">
                  <img
                    src={loadingicon}
                    className="text-center h-[50px]"
                    alt=""
                    srcSet=""
                    width="50px"
                    // height="50px"
                  />
                </div>
                <h2 className="font-bold">Please wait!</h2>
                <p className="py-3 text-neutral">
                  Verifying your transaction
                </p>
              </div>
            </div>
          </> :
          <div className="relative w-full max-w-xl px-3 py-2 bg-white shadow-xl rounded-xl lg:max-w-xl">
            <div className="p-3 text-center md:p-10 xl:px-24">
              <div className="flex justify-center">
                {props.paymentSuccess && <img
                  src={lovedimage}
                  className="text-center"
                  alt=""
                  srcSet=""
                />}
                {!props.paymentSuccess && <RiAlertFill className='text-red-500' />}
              </div>
              <h2 className="text-3xl font-bold">
                {props.paymentSuccess ? "Successful transaction!": "Failed transaction!"}
              </h2>
              {props.paymentSuccess && <>
                <p className="py-3 text-lg text-neutral">
                  You have successfully completed the transaction.
                </p>
                <div className="my-10 ">
                  <Link
                    to="/user-profile/bookings"
                    onClick={() => {
                      props.setOpenModal(false);
                    }}
                    className="block w-full px-4 py-3 text-base text-white btn bg-auraPrimary hover:bg-auraPrimary hover:text-white my-btn"
                  >
                    View your bookings
                  </Link>
                </div>
              </>}
            </div>
          </div>
        }
        openModal={props.openModal}
        setOpenModal={props.setOpenModal}
        verifyloading={props.verifyloading}
        paymentSuccess={props.paymentSuccess}
      />
    </>
  );
}
