import { FC } from 'react';
import './index.css';
import loadingGif from 'assets/icon/aura2.gif';


const Spinner: FC = () => (
  <div className="flex w-full h-screen items-center justify-center">
    {/* <div className="spinner" /> */}
    <img src={loadingGif} />
  </div>
);

export default Spinner;
