import { FC, ReactNode } from 'react';
import { formatPrice } from 'helpers';
import deal1 from 'assets/img/deal1.png';
import rate from 'assets/icon/rate.svg';

interface Props {
  image: string;
  amount: number;
  offerAmount: number;
  className?: string;
  onClick?: any;
  name: string;
  location: string;
  startDate: string;
  endDate: string;
  percentage?: string;
}

const DealItem: FC<Props> = ({
  amount = 0,
  image,
  offerAmount,
  className,
  onClick,
  name,
  location,
  startDate,
  endDate,
  percentage,
  ...rest
}) => {
  return (
    <div className="rounded-lg">
      <div className="relative">
        <img src={image} className="w-full rounded-t-xl h-72" alt="" />
        <div className="discount-off bg-black">
          {percentage && (
            <p className="text-sm font-bold py-1.5 px-3 uppercase">
              {percentage} off
            </p>
          )}
        </div>
      </div>
      <div className="py-6 px-4 card-home-body rounded-b-xl">
        <div className="flex justify-between">
          <div className="w-[60%]">
            <p className="text-lg font-medium text-ellipsis whitespace-nowrap overflow-hidden">
              {name}
            </p>
            <p>{location}</p>
          </div>

          <div className="">
            <p className="line-through text-neutral">
              &#8358;{formatPrice(amount)}
            </p>
            <p className="text-auraPrimary text-xl font-bold">
              &#8358;{formatPrice(amount - offerAmount)}
            </p>
          </div>
        </div>
        <div className="flex justify-between mt-6">
          <div>
            <p className="text-sm text-neutral">
              <span>{startDate}</span> - <span>{endDate}</span>
            </p>
          </div>

          <div className="">
            <div className="flex items-center">
              <img src={rate} alt="" />
              <span className="font-semibold tx-sm ml-1">4.9</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DealItem;
