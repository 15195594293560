import { Dispatch, AnyAction } from '@reduxjs/toolkit';
import { loadingStart, loadingStopped, filterSuccess, filteredProduct } from './reducers';
import dataService from 'services/appData';
import { NewSearchFilterProducts } from 'services/appData/types';
// import { SearchFilterProducts } from 'services/appData/types';

export const getFilter =
    (data: any) =>
    async (dispatch: Dispatch<AnyAction>): Promise<void> => {
        try {
            dispatch(filterSuccess(data));
            // dispatch(loadingStart());
        } catch (err: any) {
            // console.log(err);
        }
    };

export const filterProduct =
    (data: NewSearchFilterProducts, pageNumber?: number, pageSize?: number) =>
    async (dispatch: Dispatch<AnyAction>): Promise<void> => {
        try {
            dispatch(loadingStart());
            if (window.location.pathname.includes('recommended')) {
                const res = await dataService.getRecommendedProducts(data);
                dispatch(filteredProduct(res));
            }else if(window.location.pathname.includes('featured')){
              const res = await dataService.getFeaturedProducts(data);
              dispatch(filteredProduct(res));
            } else {
                // const res = await dataService.filterProducts(data, pageNumber, pageSize);
                // dispatch(filteredProduct(res));
            }
        } catch (err: any) {
            dispatch(loadingStopped(err?.response?.data?.responseMessage));
        }
    };
