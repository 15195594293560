import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// import { IoLogoGoogle } from 'react-icons/io';
// import { CgFacebook } from 'react-icons/cg';
// import { TiSocialLinkedin } from 'react-icons/ti';
// import { Formik, Form } from 'formik';
// import * as Yup from 'yup';
// import { Link } from 'react-router-dom';
import {
  //   ToastContextType,
  useToast,
} from 'components/common/Toast/ToastProvider';
import { Modal, Button, Input } from 'components/common';
// import { loginUser, socialMediaAuth } from 'redux/authSlice/actions';
// import { facebookProvider, googleProvider } from 'utils/config';
// import firebase from 'firebase/compat';
import { RootState } from 'redux/rootReducer';
// import Dot from 'components/common/Loader/Dot';
// import formatDate from 'utils/date/dateFormat';
import { ReactComponent as CancelReason } from 'assets/icon/cancellation.svg';
import { cancelUserBooking, clearCancelBookingResponse } from 'redux/reservation/actions';
import { Dots } from 'components/common/Loader';

interface BookingDetailModalProps {
  openModal?: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  selectedData: any[];
}

const EmergencyContactModal: FC<BookingDetailModalProps> = ({
  openModal = true,
  setOpenModal,
  selectedData
}) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  console.log("selectedData", selectedData)

  return (
    <Modal
      openModal={openModal as boolean}
      setOpenModal={setOpenModal as Dispatch<SetStateAction<boolean>>}
      className="py-7 px-4 sm:px-10 w-full max-w-[542px] bg-white shadow border border-auraPrimary rounded-xl"
      onOutsideClick={() => {}}
      onModalClose={
        setOpenModal
          ? () => setOpenModal(false)
          : () => navigate('/user-profile/bookings')
      }
    >
      <div className="flex w-full">
        <div className="w-full px-3">
          <div className="w-full">
            {/* <!-- Modal body --> */}
            <div className=" w-full">
    
              <h2 className=" mb-5 p-3 text-left uppercase font-bold rounded bg-[#FDF3E9]">
                Emergency Contact
              </h2>

              <div className="flex flex-col text-left gap-5 w-[100%]">

                {
                  selectedData && selectedData.map((data:any) => {
                    return <div className="flex gap-10">
                    <div className="font-bold w-[30%]">
                      {data?.emergencyAgency}
                    </div>
                    <div className="">
                      {data?.emergencyNumber}
                    </div>
                  </div>
                  })
                }

              </div>
              
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EmergencyContactModal;
