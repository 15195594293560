import {
  Dispatch,
  FC,
  Fragment,
  SetStateAction,
  useState,
  ReactNode,
} from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { HiOutlineSelector, HiCheck } from 'react-icons/hi';
// import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';

interface SelectProps {
  value: any;
  onChange: Dispatch<SetStateAction<any>>;
  name?: string;
  label: string;
  placeholder?: string;
  children: ReactNode | JSX.Element;
  className?: string;
  buttonClass?: string;
  height?:string;
}

interface OptionProps {
  value: any;
  label?: any;
  children: ReactNode | JSX.Element;
  onSelect?: (a: any) => void;
}

export const Option: FC<OptionProps> = ({
  value,
  label,
  onSelect,
  children,
}) => (
  <Listbox.Option
    className={({ active }) =>
      `relative cursor-default select-none py-2 pl-10 pr-4 ${
        active ? 'bg-auraPrimary100 text-auraPrimary' : 'text-gray-900'
      }`
    }
    value={value}
  >
    {({ selected }) => (
      <>
        <span
          onClick={onSelect}
          aria-hidden="true"
          className={`block truncate ${
            selected ? 'font-medium' : 'font-normal'
          }`}
        >
          {children}
        </span>
        {selected ? (
          <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
            <HiCheck className="h-5 w-5" aria-hidden="true" />
          </span>
        ) : null}
      </>
    )}
  </Listbox.Option>
);

const Select: FC<SelectProps> = ({
  value,
  onChange,
  label,
  name,
  children,
  placeholder,
  className,
  buttonClass,
  height,
  ...rest
}) => {
  return (
    <div className={`${className} mt-2`}>
      <Listbox value={value} onChange={onChange} name={name} {...rest}>
        <div className="relative mt-1">
          <Listbox.Button
            className={`${buttonClass} relative w-full cursor-default rounded-lg h-9 bg-white border border-[rgba(222,226,230,0.6)] py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm`}
            style={{height:height?height:""}}
          >
            <span className="block truncate">{label || placeholder}</span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <HiOutlineSelector
                className="h-5 w-5 text-auraPrimary"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute mt-1 z-50 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {children}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
};
export default Select;
