import { createSlice } from '@reduxjs/toolkit';

export interface BookingInitialState {
  productSelections: Array<string>;
  isLoading: boolean;
  error: Record<string, any> | null;
  wishlist: Record<string, any>;
  isApprovedBookingExist: boolean;
  bookingDetailsById:Record<string, any> ;
  bookingRouteFrom:string;
}

const bookingInitialState: BookingInitialState = {
    productSelections: [],
    isLoading: false,
    isApprovedBookingExist: false,
    error: null,
    wishlist:{},
    bookingDetailsById:{},
    // bookingDetailsActivity: {},
    bookingRouteFrom:''
};

function startLoading(state: BookingInitialState): void {
  state.isLoading = true;
}

function stopLoading(
  state: BookingInitialState,
  action: { payload: Record<string, any> | null }
): void {
  state.isLoading = false;
  state.error = action.payload;
}

const booking = createSlice({
  name: 'booking',
  initialState: bookingInitialState,
  reducers: {
    loadingStart: startLoading,
    loadingStopped: stopLoading,
    bookingSuccess: (state: BookingInitialState, { payload }): void => {
      state.productSelections = payload;
      state.isLoading = false;
      state.error = null;
    },
    createWishlistSuccess: (state: BookingInitialState, { payload }): void => {
      state.wishlist = payload;
      state.isLoading = false;
      state.error = null;
    },
    setIsApprovedBookingExist: (state: BookingInitialState,{ payload}): void => {
      state.isApprovedBookingExist = payload;
    },
    saveBookingById: (state: BookingInitialState,{ payload}): void => {
      state.bookingDetailsById = payload;
    },
    saveBookingActivity: (state: any, { payload}): void => {
      state.bookingDetailsActivity = payload;
    },
    setbookingRouteFrom: (state: BookingInitialState,{ payload}): void => {
      state.bookingRouteFrom = payload;
    },
    saveGeoLocationInfo: (state: any, { payload}): void => {
      state.geoLocationInfo = payload;
    },
  },
});

export const { 
  loadingStart, 
  loadingStopped, 
  bookingSuccess,
  createWishlistSuccess,
  setIsApprovedBookingExist,
  saveBookingById,
  setbookingRouteFrom, 
  saveBookingActivity,
  saveGeoLocationInfo,
 } =
booking.actions;

export default booking.reducer;
