import service from 'services/fetchInterceptor';
import { LoginData, SignupData, ServiceType,BioData, SocialSignupData, SocialLoginData } from './types';

const authService: ServiceType | any = {};

authService.login = (data: LoginData): Promise<any> =>
  service({
    url: '/v1/Account/AuthenticateUser',
    method: 'post',
    data,
    headers: {
      'public-request': 'true',
    },
  });
  authService.AuthenticateUserWithSocialMedia = (data: SocialLoginData): Promise<any> =>
  service({
    url: '/v1/Account/AuthenticateUserWithSocialMedia',
    method: 'post',
    data,
    headers: {
      'public-request': 'true',
    },
  });
authService.signup = (data: SignupData): Promise<any> =>
  service({
    url: '/v1/Account/Signup',
    method: 'post',
    data,
    headers: {
      'public-request': 'true',
    },
  });
  authService.signupGoogle = (data: SocialSignupData): Promise<any> =>
  service({
    url: '/v1/Account/SignupWithGoogle',
    method: 'post',
    data,
    headers: {
      'public-request': 'true',
    },
  });

  authService.signupFacebook = (data: SocialSignupData): Promise<any> =>
  service({
    url: '/v1/Account/SignupWithFacebook',
    method: 'post',
    data,
    headers: {
      'public-request': 'true',
    },
  });

  authService.signupLinkedIn = (data: SocialSignupData): Promise<any> =>
  service({
    url: '/v1/Account/SignupWithLinkedIn',
    method: 'post',
    data,
    headers: {
      'public-request': 'true',
    },
  });

authService.fetchUserDetails = (): Promise<any> =>
  service({
    url: '/v1/Account/GetUser',
    method: 'get',
  });

  authService.updateBiodata =(data:BioData ): Promise<any> =>
  service({
    url: '/v1/Account/UpdateBiodata',
    method: 'post',
    data,
    headers: {
      'public-request': 'true',
    },
  }); 

  authService.uploadPassport =(data:any):Promise<any>=>service({
    url: '/v1/Account/UploadPassport',
    method: 'post',
    data,
    headers: {
      'public-request': 'true',
    },
  });

  authService.uploadCoverPhoto =(data:any):Promise<any>=>service({
    url: '/v1/Account/UploadBanner',
    method: 'post',
    data,
    headers: {
      'public-request': 'true',
    },
  })

  authService.changePassword =(data:any ): Promise<any> =>
  service({
    url: `/v1/Account/ChangePassword?NewPassword=${data?.NewPassword}&OldPassword=${data.OldPassword}`,
    method: 'post',
    data,
    headers: {
      'public-request': 'true',
    },
  }); 

  authService.forgetPassword =(data:any ): Promise<any> =>
  service({
    url: `/v1/Account/RetrievePassword?emailAddressorPhoneNumber=${data}&IsEmail=true`,
    method: 'get',
    // data,
    headers: {
      'public-request': 'true',
    },
  }); 

  authService.resetPassword =(data:any ): Promise<any> =>
  service({
    url: `/v1/Account/ChangeRetrievePassword?retrievalCode=${data?.retrievalCode}&Password=${data?.Password}`,
    method: 'get',
    // data,
    headers: {
      'public-request': 'true',
    },
  }); 


export default authService;
