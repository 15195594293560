const productList: Array<string> = [
  'hotels',
  'apartments',
  'experiences',
  'restaurants',
  'workspaces',
  'photography',
];

export default productList;

export const productTypes = [
  {
    productID: 29,
    productName: 'Hotel',
    productCode: '1',
    productIconPath:
      'https://aurastore.blob.core.windows.net/aurastore/Group%2026359.png',
    isActive: true,
  },
  {
    productID: 30,
    productName: 'Apartment',
    productCode: '1',
    productIconPath:
      'https://aurastore.blob.core.windows.net/aurastore/Group%2026360.png',
    isActive: true,
  },
  // {
  //   productID: 31,
  //   productName: 'Restaurant',
  //   productCode: '3',
  //   productIconPath:
  //     'https://aurastore.blob.core.windows.net/aurastore/Group%2034551.png',
  //   isActive: true,
  // },
  // {
  //   productID: 32,
  //   productName: 'Photography',
  //   productCode: '4',
  //   productIconPath:
  //     'https://aurastore.blob.core.windows.net/aurastore/Vector.png',
  //   isActive: true,
  // },
  {
    productID: 33,
    productName: 'Experience',
    productCode: '5',
    productIconPath:
      'https://aurastore.blob.core.windows.net/aurastore/Icon.png',
    isActive: true,
  },
  {
    productID: 34,
    productName: 'Workspaces',
    productCode: '6',
    productIconPath:
      'https://aurastore.blob.core.windows.net/aurastore/Group%2034552.png',
    isActive: true,
  },
];

export const productTypes2 = [
  {
    productID: 29,
    productName: 'Hotel',
    productCode: '1',
    productIconPath:
      'https://aurastore.blob.core.windows.net/aurastore/Group%2026359.png',
    isActive: true,
  },
  {
    productID: 30,
    productName: 'Apartment',
    productCode: '1',
    productIconPath:
      'https://aurastore.blob.core.windows.net/aurastore/Group%2026360.png',
    isActive: true,
  },
  // {
  //   productID: 31,
  //   productName: 'Restaurant',
  //   productCode: '3',
  //   productIconPath:
  //     'https://aurastore.blob.core.windows.net/aurastore/Group%2034551.png',
  //   isActive: true,
  // },
  // {
  //   productID: 32,
  //   productName: 'Photography',
  //   productCode: '4',
  //   productIconPath:
  //     'https://aurastore.blob.core.windows.net/aurastore/Vector.png',
  //   isActive: true,
  // },
  {
    productID: 33,
    productName: 'Experiences',
    productCode: '5',
    productIconPath:
      'https://aurastore.blob.core.windows.net/aurastore/Icon.png',
    isActive: true,
  },
  {
    productID: 34,
    productName: 'Workspaces',
    productCode: '6',
    productIconPath:
      'https://aurastore.blob.core.windows.net/aurastore/Group%2034552.png',
    isActive: true,
  },
];

export const simpleProductTypes = [
  {
    productID: 29,
    productName: 'hotels'
  },
  {
    productID: 30,
    productName: 'apartments'
  },
  // {
  //   productID: 31,
  //   productName: 'restaurants'
  // },
  // {
  //   productID: 32,
  //   productName: 'photography'
  // },
  {
    productID: 33,
    productName: 'experiences'
  },
  {
    productID: 34,
    productName: 'workspaces'
  },
];


export const getNameOfProduct =(id:number)=>{
  return productTypes.filter(data=>data.productID===id)[0]?.productName;
} 

export const getNameOfProduct2 =(id:number)=>{
  return productTypes2.filter(data=>data.productID===id)[0]?.productName;
} 

export const getIDOfProduct =(name:string)=>{
  return productTypes.filter(data=>name.toLowerCase()===data.productName.toLowerCase())[0]?.productID;
} 

const convertDate = (params : Date) =>{
  const x = new Date(params);
  return x.toLocaleString()
}