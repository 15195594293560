import { createSlice } from '@reduxjs/toolkit';

export interface ReservationInitialState {
  reservation: Record<string, any>;
  isLoading: boolean;
  error: Record<string, any> | null;
  selectedBooking: Record<string, any> | any;
  createdQuote: any;
  cancelledBookingResponse: Record<string, any> | any;
}

const reservationInitialState: ReservationInitialState = {
  reservation: {},
  isLoading: false,
  error: null,
  createdQuote: {},
  cancelledBookingResponse: {},
  selectedBooking: {
    bookingID: 1,
    userID: 1,
    serviceID: 1,
    productID: 1,
    discountID: 1,
    dateCreated: new Date().toISOString(),
    approval: false,
    isActive: false,
    dateDeactivated: '0001-01-01T00:00:00',
    bookingAmount: 0,
    vat: 0,
    discountAmount: 0,
    other_Charges: 0,
    insuranceAmount: 0,
    payableAmount: 0,
    referalCode: null,
    checkInDate: new Date().toISOString(),
    checkOutDate: new Date().toISOString(),
    approvalDate: '0001-01-01T00:00:00',
    cancelledDate: '0001-01-01T00:00:00',
    paymentStatus: null,
    paymentInstrumentTypeID: 0,
    transactionID: null,
    firstName: '',
    lastName: '',
    emailAddress: '',
    bookingReference: '0',
    phoneNumber: '',
    approvedBy: 0,
    cancelledBy: 0,
    totalGuests: 0,
    totalRooms: 0,
    hostVendorID: 1,
    serviceName: '',
    servicePicture: null,
    productDetailsID: 1,
    productName: '',
    productDescription: '',
  },
};

function startLoading(state: ReservationInitialState): void {
  state.isLoading = false;
}

function stopLoading(
  state: ReservationInitialState,
  action: { payload: Record<string, any> | null }
): void {
  state.isLoading = false;
  state.error = action.payload;
}

function clearLoading(state: ReservationInitialState, payload: any) {
  state.isLoading = false;
  state.error = null;
}

const reservation = createSlice({
  name: 'reservation',
  initialState: reservationInitialState,
  reducers: {
    loadingStart: startLoading,
    loadingStopped: stopLoading,
    reservationSuccess: (
      state: ReservationInitialState,
      { payload }: any
    ): void => {
      state.reservation = payload;
      state.isLoading = false;
      state.error = null;
    },
    selectedBooking: (
      state: ReservationInitialState,
      { payload }: any
    ): void => {
      state.selectedBooking = payload;
      clearLoading(state, payload);
    },
    createQuoteSuccess: (
      state: ReservationInitialState,
      { payload }: any
    ): void => {
      state.createdQuote = payload;
      clearLoading(state, payload);
    },
    saveCancelBookingResponse: (
      state: ReservationInitialState,
      { payload }: any
    ): void => {
      state.cancelledBookingResponse = payload;
    },
    ClearCancelBookingResponse: (state: ReservationInitialState): void => {
      state.cancelledBookingResponse = {};
    },
    paysmallsmallCost: (
      state: any,
      { payload }: any
    ): void => {
      state.paysmallsmallCost = payload;
      clearLoading(state, payload);
    },
  },
});

export const {
  loadingStart,
  loadingStopped,
  reservationSuccess,
  selectedBooking,
  createQuoteSuccess,
  saveCancelBookingResponse,
  ClearCancelBookingResponse,
  paysmallsmallCost,
} = reservation.actions;

export default reservation.reducer;
