import { FC, Fragment, SetStateAction, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import Lightbox from 'react-image-lightbox';
import {
    Footer,
    Header,
    // Input,
    // PlacesCard,
    ValidationModal,
} from 'components/common';
import { Spinner } from 'components/common/Loader';
import NewsLetterSection from 'components/common/InnerNewsletter';
import { RootState } from 'redux/rootReducer';
import share from 'assets/icon/share.svg';
import love from 'assets/icon/love.svg';
import photoo from 'assets/icon/phooto.svg';
import { ReactComponent as ArrowLeft } from 'assets/icon/arrow-left.svg';
import circleBtn from 'assets/icon/circle-button.png';
import { getProductReview, saveSelectedProductDetail } from 'redux/productSlice/actions';
import { createWishlist } from 'redux/booking/action';
import { sixMonths, today } from 'utils/date/dateFormat';
import SavedModal from 'components/common/Modal/SavedModal';
import ProductReviews from './ProductReviews';
import LocationModal from 'components/common/Modal/LocationModal';
import { parseDate } from 'utils/date/dateDiff';
import PersonalDetailsForm from './PersonalDetailsFormActivity';
import { RadioChangeEvent } from 'antd';
import { UpcomingActivityServices } from 'services/upcomingActivities';
import bookingService from 'services/bookingService';
import { saveSelectedBooking } from 'redux/reservation/actions';
import ShareModal from 'components/common/Modal/ShareModal';
import { useToast } from 'components/common/Toast/ToastProvider';
import emptyImage from 'assets/img/emptyImage.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowLeft, faCircleArrowRight, faCircleXmark, faLocationDot } from '@fortawesome/free-solid-svg-icons';


export default function ActivitiesProductDetail() {
    const { isAuthenticated, user } = useSelector((state: RootState) => state.user);
    const { selectedProduct, selectedProductDetail, currentProductType } = useSelector((state: RootState) => state.product);

    const [productDetails, setproductDetails] = useState<Record<string, any>>(selectedProductDetail);
    const [isLoading, setisLoading] = useState(true);
    const [photoIndex, setphotoIndex] = useState(0);
    const [isOpen, setisOpen] = useState(false);
    const { detailsID } = useParams();
    const [locationModal, setlocationModal] = useState(false);
    const [shareModal, setshareModal] = useState(false);
    const [activiList, setActiviList] = useState(null)
    const [successReject, setSuccessReject] = useState(false);
    const [errMsg, setErrMsg] = useState('')

    const handleMove = (direction: any) => {
        let newSlideIndex;

        if (direction === 'l') {
            newSlideIndex = photoIndex === 0 ? images.length - 1 : photoIndex - 1;
        } else {
            newSlideIndex = photoIndex === images.length - 1 ? 0 : photoIndex + 1;
        }
        setphotoIndex(newSlideIndex);
    };

    // const images = (selectedProductDetail.productPictures || []).map((data: any) => data?.pictureURL);
    const [openModal, setOpenModal] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const router = useLocation();

    const totalCounts: number = activiList?.reduce((accumulator: number, object: any) => {
        return accumulator + (object?.quantity || 0);
    }, 0);

    const fetchproductDetails = async () => {
        const response = await UpcomingActivityServices.getActivityCalendar(detailsID);
        setproductDetails(response.data);

        setinitialActivityBookingAmount(productDetails?.activityCalendar?.amount);
        setactivityBookingPrice(productDetails?.activityCalendar?.amount)
        dispatch(saveSelectedProductDetail(response.data));
        setisLoading(false);
    };

    useEffect(() => {
        fetchproductDetails();
        window.scrollTo(0,0)
        dispatch(getProductReview(selectedProduct.serviceID));
    }, []);

    const handleAddToWishlist = async (checkIn?: any, checkOut?: any) => {
        if (!isAuthenticated) {
            navigate(`/login?redirect=${router.pathname}`);
            return;
        }
        const { c_in, c_out } = parseDate(checkIn, checkOut);


        setisLoading(true);
        await dispatch(
            createWishlist({
                productDetailsID: productDetails?.productDetails?.productDetailsID,
                userID: user.userID,
                startDate: checkIn ? new Date(new Date(c_in).setHours(12, 0, 0)) : today.toISOString(),
                endDate: checkOut ? new Date(new Date(c_out).setHours(12, 0, 0)) : sixMonths.toISOString(),
            })
        );
        setisLoading(false);
        setOpenModal(true);
    };

    let path = window.location.pathname.toLowerCase();

    const { filter } = useSelector((state: RootState) => state.filter);
    const { createdQuote } = useSelector((state: RootState) => state.reservation);

    const { totalPrice, selectedProductServices } = useSelector((state: RootState) => state.product);
    // const [modal, setModal] = useState(false);
    const [disabled, setdisabled] = useState(true);

    const [isBookingFor, setisBookingFor] = useState('Self');
    // let serviceID = selectedProductDetail?.servicesPictures[0]?.services?.serviceID;

    const [formData, setformData] = useState({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.emailAddress,
        phone: user.phoneNumber,
    });

    const onChange = (e: RadioChangeEvent) => {
        if (e.target.value === 'Self') {
            setformData({
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.emailAddress,
                phone: user.phoneNumber,
            });
        } else {
            setformData({
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
            });
        }
        // console.log('radio checked', e.target.value);
        setisBookingFor(e.target.value);
    };

    // useEffect(() => {
    //     fetchproductDetails();
    // }, []);

    const handleFormChange = (prop: string) => (event: any) => {
        setformData({ ...formData, [prop]: event.target.value });
    };

    useEffect(() => {
        let isCancelled = false;
        if (!isCancelled) {
            if (formData.firstName && formData.lastName && formData.email && formData.phone) {
                setdisabled(false);
            } else {
                setdisabled(true);
            }
        }
        return () => {
            isCancelled = true;
        };
    }, [formData]);

    const { c_in, c_out } = parseDate(filter?.checkIn, filter?.checkOut);
    const images = productDetails?.pictures;

    const [ticketQuantity, setticketQuantity] = useState<number>(1);
    const[ initialActivityBookingAmount, setinitialActivityBookingAmount ] = useState(0)
    // let initialActivityBookingAmount: number = productDetails?.activityCalendar?.amount;
    const [activityBookingPrice, setactivityBookingPrice] = useState<number>(0);
    const toast = useToast();

    const incrementTicketQty = () => {
        if (ticketQuantity >= productDetails?.activityCalendar?.numberOfTicketsAvailable) {
            toast?.info('Maximum available ticket is ' + (productDetails?.activityCalendar?.numberOfTicketsAvailable || 0))
            return
        }

        setticketQuantity((prev) => prev + 1);
    };
    const decrementTicketQty = () => {
        if (ticketQuantity > 1) {
            setticketQuantity((prev) => prev - 1);
        }
    };
    useEffect(() => {
        let isCancelled = false;
        if (!isCancelled) {
            setactivityBookingPrice(productDetails?.activityCalendar?.amount * ticketQuantity);
        }

        return () => {
            isCancelled = true;
        };
    }, [ticketQuantity, productDetails]);

    
    const handleReserveActivityBooking = async () => {
        localStorage.setItem('activityEmail', formData.email)
        if(!path.includes('activities')){
            if (!isAuthenticated) {
                navigate(`/login?redirect=${router.pathname}`);
                return;
            }
        }
        setisLoading(true);
        try{
        const res = await bookingService.createActivityBooking({
            activityCalenderId: detailsID,
            // userID: user.userID,
            // bookingAmount: activityBookingPrice?activityBookingPrice:initialActivityBookingAmount,
            // discountAmount: 0,
            // payableAmount: activityBookingPrice?activityBookingPrice:initialActivityBookingAmount,
            // referralCode: '',
            payInstrumentTypeID: 1,
            paymentPlanID: 1,
            firstName: user.firstName || formData.firstName,
            lastName: user.lastName || formData.lastName,
            emailAddress: user.emailAddress || formData.email,
            phoneNumber: user.phoneNumber || formData.phone,
            actiivtyLists: activiList,
            referralCode : ''
        });
        dispatch(saveSelectedBooking(res?.data));
        setisLoading(false);
        // navigate(`/confirm-pay/activity?detailID=${detailsID}&bookingID=${res?.data?.bookingID}&bookingRef=${res?.data?.bookingReference}`);
        navigate(`/confirm-pay/activity?detailID=${detailsID}&bookingID=${res?.data?.Id}&qty=${totalCounts}`);
    }catch(err){
        setisLoading(false);
        setErrMsg(err.response.data.responseMessage)
        setSuccessReject(true)
    }
    };

    useEffect(()=>{
        activiList !== null && handleReserveActivityBooking();
    },[activiList])

    return (
        <>
            <Header isAuthenticated={isAuthenticated} />
            {isLoading ? (
                <Spinner />
            ) : (
                <main className=" bg-gray-50 fit">
                    {/* <!-- Page Heading --> */}
                    <ShareModal openModal={shareModal} id={productDetails?.activityCalendar?.activityID} setOpenModal={setshareModal} />
                    <div className="container py-8">
                        <div className="flex flex-col justify-between md:flex-row ">
                            <div>
                                <button
                                    onClick={() => navigate(-1)}
                                    className="flex items-center w-40 px-4 py-2 mb-6 font-semibold border btn md:w-full"
                                >
                                    <ArrowLeft />
                                    Go back
                                </button>
                            </div>
                            <div>
                                <nav className="flex" aria-label="Breadcrumb">
                                    <ol className="inline-flex items-center space-x-1 md:space-x-3">
                                        <li className="inline-flex items-center">
                                            <Link
                                                to="/"
                                                className="inline-flex items-center text-sm font-semibold text-neutral hover:text-gray-900 "
                                            >
                                                Home
                                            </Link>
                                        </li>
                                        <li aria-current="page">
                                            <div className="flex items-center">
                                                <img src="../../img/icon/activity.svg" alt="" srcSet="" />
                                                <span className="ml-1 text-sm font-semibold text-neutral md:ml-2 dark:text-gray-400">
                                                    Upcoming Activities
                                                </span>
                                            </div>
                                        </li>

                                        <li aria-current="page">
                                            <div className="flex items-center">
                                                <svg
                                                    className="w-6 h-6 font-bold text-black"
                                                    fill="currentColor"
                                                    viewBox="0 0 20 20"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        fill-rule="evenodd"
                                                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                                        clip-rule="evenodd"
                                                    ></path>
                                                </svg>
                                                <span className="ml-1 text-sm font-semibold md:ml-2 ">
                                                    {productDetails?.activityCalendar?.title}
                                                </span>
                                            </div>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>

                        {/* <!-- page content  --> */}
                        <div className="flex flex-col justify-between gap-4 mt-4 md:flex-row">
                            <div>
                                <h1 className="text-3xl font-bold md:text-4xl">{productDetails?.activityCalendar?.title}</h1>
                                <div className="flex items-center gap-5 mt-4">
                                    <div>
                                        <img src={productDetails?.pictures ? productDetails?.pictures[0] : emptyImage} className="w-8 h-8 rounded-full" alt="" srcSet="" />
                                    </div>
                                    {/* <div className="flex items-center gap-2">
                                        <img src="../../img/icon/rate.svg" alt="" srcSet="" />
                                        <p className="font-medium">
                                            4.8 <span className="text-neutral">(256 reviews)</span>
                                        </p>
                                    </div> */}
                                    <div className="flex items-center gap-1">
                                        <img src="../../img/icon/activity.svg" alt="" srcSet="" />
                                        <p className="text-neutral">Activity</p>
                                    </div>
                                    <div className="flex items-center gap-1">
                                        <img src="../../img/icon/flag.svg" alt="" srcSet="" />
                                        <p className="text-neutral">
                                            {`${productDetails?.activityCalendar?.location}
                                                ,
                                                ${productDetails?.activityCalendar?.city}
                                                
                                                ${productDetails?.activityCalendar?.stateName ? ', ' + productDetails?.activityCalendar?.stateName : ''}`}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="flex gap-2 ">
                                    {/* <div className=' pt-3'>
                                            <button
                                            type="button"
                                            className="btn rounded-full p-1.5 border-2"
                                            onClick={() => setlocationModal(true)}
                                            >
                                            <img src={locationRedIcon} alt="" srcSet="" className='w-6'/>
                                            </button>
                                            </div> */}
                                    <div>
                                        <button type="button" className="pl-3 btn" onClick={() => setshareModal(true)}>
                                            <img src={share} alt="" srcSet="" />
                                        </button>
                                    </div>
                                    <div>
                                        <button type="button" className="btn" onClick={() => handleAddToWishlist()}>
                                            <img src={love} alt="" srcSet="" />
                                        </button>

                                        {/* Success Modal */}
                                        <SavedModal openModal={openModal} setOpenModal={setOpenModal} />
                                        {/* Model Ends */}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Photos */}
                        <div className="md:mt-4">
                            {isOpen && (
                                // <Lightbox
                                //     mainSrc={images[photoIndex]}
                                //     nextSrc={images[(photoIndex + 1) % images.length]}
                                //     prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                                //     onCloseRequest={() => setisOpen(false)}
                                //     onMovePrevRequest={() => setphotoIndex((photoIndex + images.length - 1) % images.length)}
                                //     onMoveNextRequest={() => setphotoIndex((photoIndex + 1) % images.length)}
                                // />
                                <div className="slider">
                                <FontAwesomeIcon icon={faCircleXmark} className="close" onClick={(e) => setisOpen(false)} />
                                <FontAwesomeIcon icon={faCircleArrowLeft} className="arrow" onClick={() => handleMove('l')} />
                                <div className="sliderWrapper">
                                    <img src={images[photoIndex]} alt="" className="slideImg" />
                                </div>
                                <FontAwesomeIcon icon={faCircleArrowRight} className="arrow" onClick={() => handleMove('r')} />
                            </div>
                            )}
                            <div className="grid grid-cols-2 gap-4">
                                <div className="gird-img1">
                                    <div className="relative">
                                        <img
                                            src={productDetails?.pictures ? productDetails?.pictures[0] : emptyImage}
                                            alt="Unavailable"
                                            className="w-full image img-main rounded-3xl object-cover"
                                        />
                                        <div className="absolute bottom-0 p-3 md:px-5 md:py-6">
                                            <button
                                                onClick={() => setisOpen(true)}
                                                type="button"
                                                disabled={productDetails?.pictures == null ? true : false}
                                                className="flex items-center px-5 py-2 mt-4 hover:text-auraPrimary font-semibold text-black bg-gray-100 rounded-full"
                                            >
                                                <img src={photoo} alt="" className="mr-2" srcSet="" />
                                                Show all photos
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                {(images?.length > 0 ? images : []).map((picture: any, index: number) => (
                                    <div className={`gird-img${index + 2}`} key={index}>
                                        <div className="relative overlay-div">
                                            <img
                                                src={picture}
                                                alt="hotel"
                                                className="w-full image h-80 md:w-64 md:h-64 rounded-3xl object-cover"
                                            />
                                            <div className="overlay">
                                                <button
                                                    type="button"
                                                    onClick={() => {
                                                        setisOpen(true);
                                                        setphotoIndex(index);
                                                    }}
                                                    className="icon_zoom my-btn"
                                                    title="User Profile"
                                                    data-modal="myModal"
                                                >
                                                    <img src={circleBtn} alt="" srcSet="" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        {/* Photos Ends */}

                        <div className="flex flex-col gap-6 py-16 lg:pt-40 lg:gap-16 lg:flex-row">
                            <div className="lg:w-1/2">
                                <div>
                                    <div className="pt-4 pb-6 mb-6 border-b ">
                                        <h3 className="text-2xl font-medium">{productDetails?.activityCalendar?.title}</h3>
                                    </div>

                                    <div className="flex items-center pt-4 pb-6 space-x-4">
                                        <div className="flex items-center space-x-2 text-sm capitalize text-neutral">
                                            <div>
                                                <img src="../../img/icon/useer.svg" className="w-full" alt="" />
                                            </div>
                                            <p>{productDetails?.activityCalendar?.numberOfTicketsAvailable} guests</p>
                                        </div>
                                    </div>

                                    <div className="mb-3 space-y-6 text-base font-light text-neutral text-justify">
                                        <p>{productDetails?.activityCalendar?.details}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="lg:w-1/2">
                                <div className="space-y-8">
                                    <div className="w-full">
                                        <PersonalDetailsForm
                                            productDetails={productDetails}
                                            onChange={onChange}
                                            isBookingFor={isBookingFor}
                                            formData={formData}
                                            handleFormChange={handleFormChange}
                                            // handleReserveBooking={handleReserveActivityBooking}
                                            disabled={disabled}
                                            c_in={c_in}
                                            c_out={c_out}
                                            activityBookingPrice={activityBookingPrice}
                                            setticketQuantity={setticketQuantity}
                                            ticketQuantity={ticketQuantity}
                                            initialActivityBookingAmount={productDetails?.activityCalendar?.amount}
                                            incrementTicketQty={incrementTicketQty}
                                            decrementTicketQty={decrementTicketQty}
                                            isLoading={isLoading}
                                            setisLoading={setisLoading}
                                            setActiviList={setActiviList}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <ProductReviews productName={productDetails?.productDetails?.productName} />

                    {/* <LocationModal openModal={locationModal} setOpenModal={setlocationModal} zoomLevel={17} /> */}

                    <NewsLetterSection />
                </main>
            )}
            <Footer />
            {successReject ? (
                <div
                  className="fixed inset-0 flex z-50 items-center justify-center  bg-black bg-opacity-75 modal"
                  id="successModal"
                >
                  <div className="relative max-w-xl px-3 py-2 bg-white shadow-xl rounded-xl lg:max-w-xl">
                    <div className="p-3 text-center md:p-8 xl:px-16">
                      <div className="flex justify-center">
                        <img
                          src="/icon/94900-error 1.svg"
                          className="text-center"
                          alt=""
                        />
                      </div>
                      <h2 className="text-3xl font-bold">
                        Booking Failed!
                      </h2>
                      <p className="py-3 text-lg text-neutral">
                        {errMsg}
                      </p>
                      <div className="my-10">
                        <span
                          onClick={() => setSuccessReject(false)}
                          className="block w-full px-4 py-3 text-base text-white btn bg-auraPrimary hover:bg-orange-600 my-btn"
                        >
                          Close
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
        </>
    );
}
