import { Button, Pagination, PaginationProps, Popover } from 'antd';
import { Dots, Spinner } from 'components/common/Loader';
import { useToast } from 'components/common/Toast/ToastProvider';
import { FC, ReactInstance, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  cancelReservation,
  cancelUserBooking,
  saveSelectedBooking,
} from 'redux/reservation/actions';
// import dataService from 'services/appData';
import bookingService from 'services/bookingService';
import formatDate from 'utils/date/dateFormat';
import BookingDetailModal from './BookingDetailModal';
import { useReactToPrint } from 'react-to-print';
import { Link, useNavigate } from 'react-router-dom';
import { RootState } from 'redux/rootReducer';
import { initializePayment } from 'redux/payment/actions';
import ConfirmCancellationModal from './ConfirmCancellationModal';
import ComplaintModal from './ComplaintModal';
import CheckinModal from './CheckinModal';
import ReviewBookingModal from './ReviewBookingModal';
import ReviewSuccessModal from 'components/common/Modal/ReviewSuccessModal';
import EmergencyContactModal from './EmergencyContact';
import CancelModalSucess from './CancelModalSucess';
import { bookingRouteFrom, getBookingByBookingId } from 'redux/booking/action';
import {
  getIDOfProduct,
  getNameOfProduct,
  simpleProductTypes,
} from 'utils/products';
import moment from 'moment';

export const Bookings: FC = () => {
  const [isLoading, setisLoading] = useState(true);
  const [activeTab, setactiveTab] = useState(29);
  const [bookingsData, setbookingsData] = useState<any>({});
  const [emergencyContacts, setEmergencyContacts] = useState<any>([]);
  const toast = useToast();
  const [openModal, setOpenModal] = useState(false);
  const [openModal1, setOpenModal1] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);
  const [openModal3, setOpenModal3] = useState(false);
  const [openModal4, setOpenModal4] = useState(false);
  const [openModal5, setOpenModal5] = useState(false);
  const [openConfirmCancelModal, setopenConfirmCancelModal] = useState(false);
  const [openCancelModal, setopenCancelModal] = useState(false);
  const { filteredProduct } = useSelector((state: RootState) => state.filter);
  const [pageNumber, setpageNumber] = useState(1);
  const [searchKey, setsearchKey] = useState('');
  const [sortBy, setsortBy] = useState('');
  const pageSize = 10;
  const [selectedData, setSelectedData] = useState(null);

  const dispatch = useDispatch();
  const fetchBookings = async () => {
    setisLoading(true);
    const response = await bookingService.getBookingsByProductID(
      activeTab,
      pageNumber,
      pageSize,
      searchKey,
      sortBy
    );
    setbookingsData(response);
    setisLoading(false);
  };

  const fetchEmergencyContacts = async () => {
    const response = await bookingService.getEmergencyContacts();
    setEmergencyContacts(response.data);
  };

  const createSafetyIncidence = async (bookingID : any) => {
    const response = await bookingService.createSafetyIncidence(bookingID);
  };

  const { user } = useSelector((state: RootState) => state.user);
  // const { payment } = useSelector((state: RootState) => state.payment);
  const { selectedBooking } = useSelector(
    (state: RootState) => state.reservation
  );


  const messageHost=(data:any)=>{
    navigate(`/messages?bookingId=${data?.bookingID}&name=${data?.hostName}&startMsg=true`)
  }

  const approvedPaidActions = [
    {
      name: 'Complaints',
      action: (data:any) => {
        setSelectedData(data)
        setOpenModal1(true)
      },
    },
    {
      name: "Message Host",
      action: messageHost,
    },
    {
      name: 'Emergency contacts',
      action: (data:any) => {
        setSelectedData(data)
        setOpenModal4(true);
        createSafetyIncidence(data?.bookingID)
      },
    },
  ];

  const concludedActions = [
    // {
    //   name: 'Rebook',
    //   action: () => {},
    // },
    {
      name: 'Review and Ratings',
      action: (data:any) => {
        setSelectedData(data)
        setOpenModal3(true)
      },
    },
  ];

  const getProductName = () => {
    const product = simpleProductTypes.find((s) => s.productID === activeTab);
    return product?.productName;
  };


  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      dispatch(
        initializePayment({
          email: user?.emailAddress,
          amount: selectedBooking.payableAmount,
        })
      );
    }
    return () => {
      isCancelled = true;
    };
  }, [selectedBooking, user?.emailAddress]);

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      fetchBookings();
    }

    return () => {
      isCancelled = true;
    };
  }, [activeTab, pageNumber, pageSize, searchKey, sortBy]);

  useEffect(() => {
    fetchEmergencyContacts();
  }, []);


  // console.log('bookingsData', bookingsData);

  const componentRef: React.MutableRefObject<HTMLTableElement | null> =
    useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const navigate = useNavigate();

  const onChange: PaginationProps['onChange'] = (pageNumber) => {
    setpageNumber(pageNumber);
  };

  const handleSortChange = (e: any) => {
    setsortBy(e.target.value);
  };

  return (
    <div className="w-full px-4 py-12 xl:px-0">
      <div className="flex items-center mb-6 text-2xl font-medium ">
        My Bookings
      </div>
      <BookingDetailModal 
        openModal={openModal} 
        setOpenModal={setOpenModal} 
        activeTab={activeTab}
      />
      <ConfirmCancellationModal
        openModal={openConfirmCancelModal}
        setOpenModal={setopenConfirmCancelModal}
        isLoading={isLoading}
        setisLoading={setisLoading}
        fetchBookings={fetchBookings}
        setopenCancelModal={setopenCancelModal}
      />
      <CancelModalSucess
        openModal={openCancelModal}
        setOpenModal={setopenCancelModal}
      />
      <ComplaintModal
        openModal={openModal1}
        setOpenModal={setOpenModal1}
        isLoading={isLoading}
        setisLoading={setisLoading}
        selectedData={selectedData}
      />
      <CheckinModal
        openModal={openModal2}
        setOpenModal={setOpenModal2}
        isLoading={isLoading}
        setisLoading={setisLoading}
        selectedData={selectedData}
      />
      <ReviewBookingModal
        openModal={openModal3}
        setOpenModal={setOpenModal3}
        isLoading={isLoading}
        setisLoading={setisLoading}
        selectedData={selectedData}
      />
      <EmergencyContactModal
        openModal={openModal4}
        setOpenModal={setOpenModal4}
        selectedData={emergencyContacts || []}

      />

      <ReviewSuccessModal
        openModal={openModal5}
        setOpenModal={setOpenModal5}
      />

      <div className="p-2 shadow-sm md:p-6 2xl:p-8">
        <div className="mb-4 overflow-x-auto border-b fit">
          <ul
            className="flex flex-wrap text-sm font-medium text-center"
            role="tablist"
          >
            {simpleProductTypes.map((data: any, index: number) => (
              <li className="mr-2" role="presentation" key={index}>
                <button
                  className={`inline-block p-4 border-b-2 capitalize rounded-t-lg ${
                    activeTab === data.productID ? 'border-auraPrimary' : ''
                  } `}
                  onClick={() => setactiveTab(data.productID)}
                  type="button"
                >
                  {data.productName}
                </button>
              </li>
            ))}

            {/* <li className="mr-2" role="presentation">
              <button
                onClick={() => setactiveTab('apartment')}
                className={`
                ${
                  activeTab === 'apartment'
                    ? 'border-auraPrimary'
                    : 'border-gray-100 hover:border-gray-300'
                }
                inline-block p-4 text-gray-500 border-b-2 rounded-t-lg hover:text-gray-600 `}
                id="dashboard-tab"
                data-tabs-target="#apartment"
                type="button"
                role="tab"
                aria-controls="apartment"
                aria-selected="false"
              >
                Apartment
              </button>
            </li>
            <li className="mr-2" role="presentation">
              <button
                onClick={() => setactiveTab('experience')}
                className="inline-block p-4 text-gray-500 border-b-2 border-transparent border-gray-100 rounded-t-lg hover:text-gray-600 hover:border-gray-300 "
                id="dashboard-tab"
                data-tabs-target="#Expereince"
                type="button"
                role="tab"
                aria-controls="Expereince"
                aria-selected="false"
              >
                Experience
              </button>
            </li>
            <li className="mr-2" role="presentation">
              <button
                onClick={() => setactiveTab('workspace')}
                className="inline-block p-4 text-gray-500 border-b-2 border-transparent border-gray-100 rounded-t-lg hover:text-gray-600 hover:border-gray-300 "
                id="dashboard-tab"
                data-tabs-target="#Workshop"
                type="button"
                role="tab"
                aria-controls="Workshop"
                aria-selected="false"
              >
                Workspace
              </button>
            </li> */}
          </ul>
        </div>
        <div id="transactionsContent" className="overflow-x-auto">
          {/* <!-- TABLE CONTENT --> */}
          <div
            className=""
            id="hotel"
            role="tabpanel"
            aria-labelledby="hotel-tab"
          >
            <div className="flex flex-col items-center gap-3 mb-8 justify-between overflow-x-auto 2xl:gap-4 md:flex-row fit">
              <div className="flex items-center gap-2 mt-2">
                <div className="flex items-center">
                  {/* <input
                    id="checkbox-all-search"
                    type="checkbox"
                    className="w-2 h-2 m-0 border-gray-300"
                  />
                  <label htmlFor="checkbox-all-search" className="sr-only">
                    checkbox
                  </label> */}
                </div>
                <div>
                  {/* <svg
                    className="w-6 h-6 text-gray-300"
                    fill="gray-200"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                    ></path>
                  </svg> */}
                </div>
                <form>
                  <label
                    htmlFor="default-search"
                    className="mb-2 text-sm font-medium text-gray-900 sr-only "
                  >
                    Search
                  </label>
                  <input
                    type="search"
                    id="default-search"
                    className="form-control py-1 h-8 mb-1 placeholder:text-sm border-auraPrimary focus:border-auraPrimary100"
                    placeholder="Search"
                    value={searchKey}
                    onChange={(e: any) => setsearchKey(e.target.value)}
                  />
                </form>
              </div>

              <div className="flex flex-wrap items-center gap-2 mt-2 2xl:gap-4">
                <div className="">
                  {/* <button className="w-full md:px-4 px-2 py-1.5 font-medium flex items-center  text-black border btn">
                    Filter
                    <img
                      src="../../img/icon/filteer.svg"
                      alt=""
                      className="ml-2 md:ml-4"
                      srcSet=""
                    />
                  </button> */}
                </div>
                <form action="" className="flex items-center space-x-2">
                  <label
                    htmlFor="countries"
                    className="block text-sm font-light text-gray-900 "
                  >
                    Sort By:
                  </label>
                  <select
                    id="countries"
                    className="px-3 py-1.5 text-sm font-light text-gray-900 border border-gray-300 bg-gray-50 rounded"
                    onChange={handleSortChange}
                  >
                    <option selected value="mostRecent">
                      Most Recent
                    </option>
                    <option value="checkIn">Check In</option>
                    <option value="checkOut">Check Out</option>
                    <option value="oldest">Oldest</option>
                  </select>
                </form>

                <div className="">
                  {/* <button className="w-full px-6 py-1.5 text-black border btn font-medium">
                    Export as
                  </button> */}
                </div>

                <button
                  className="flex items-center space-x-2 cursor-pointer"
                  onClick={handlePrint}
                >
                  <svg
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6 18.6875H4C3.46957 18.6875 2.96086 18.4768 2.58579 18.1017C2.21071 17.7266 2 17.2179 2 16.6875V11.6875C2 11.1571 2.21071 10.6484 2.58579 10.2733C2.96086 9.89821 3.46957 9.6875 4 9.6875H20C20.5304 9.6875 21.0391 9.89821 21.4142 10.2733C21.7893 10.6484 22 11.1571 22 11.6875V16.6875C22 17.2179 21.7893 17.7266 21.4142 18.1017C21.0391 18.4768 20.5304 18.6875 20 18.6875H18"
                      stroke="#FF8300"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M18 14.6875H6V22.6875H18V14.6875Z"
                      stroke="#FF8300"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6 9.6875V2.6875H18V9.6875"
                      stroke="#FF8300"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>

                  <p>Print</p>
                </button>
              </div>
            </div>

            <div className="">
              <table
                className="w-full text-sm text-left text-gray-500 "
                ref={componentRef}
              >
                <thead className="text-xs text-gray-700 uppercase bg-auraPrimary100 fit">
                  <tr>
                    <th scope="col" className="p-4">
                      {/* <!-- <div className="flex items-center">
                                <input id="checkbox-all" type="checkbox" className="w-3 h-3 text-blue-600 bg-gray-100 border-gray-300 rounded">
                                <label htmlFor="checkbox-all" className="sr-only">checkbox</label>
                            </div> --> */}
                    </th>
                    <th scope="col" className="px-2 py-4 uppercase">
                      Service name
                    </th>
                    <th
                      scope="col"
                      className="px-2 py-4 uppercase whitespace-nowrap"
                    >
                      Status
                    </th>
                    <th scope="col" className="px-2 py-4 uppercase ">
                      Amount
                    </th>
                    {/* <th
                      scope="col"
                      className="px-2 py-4 uppercase whitespace-nowrap"
                    >
                      Payment
                    </th> */}
                    <th scope="col" className="px-2 py-4 uppercase">
                      {( activeTab.toString() === "31" 
                      || activeTab.toString() === "32") 
                      ? "Date"  :
                      activeTab.toString() === "33" ? "Duration-From" : "Check-in"}
                    </th>

                    {( activeTab.toString() === "31" 
                      || activeTab.toString() === "32") ? ''
                      :  
                      <th scope="col" className="px-2 py-4 uppercase">
                        {activeTab.toString() === "33" ? "Duration-To" : "Check-out"}
                      </th>
                    }
                    
                    <th scope="col" className="px-2 py-4 uppercase"></th>
                  </tr>
                </thead>

                {isLoading ? (
                  <div className="bg-auraPrimary rounded-full">
                    <Dots />
                  </div>
                ) : (
                  <tbody>
                    {(bookingsData.data || []).length > 0 ? (
                      bookingsData.data.map((data: any, index: number) => (
                        <tr
                          className="bg-white border-b hover:bg-gray-50 "
                          key={index}
                        >
                          <td className="w-4 py-4 tableColfit">
                            <div className="flex items-center">
                              {/* <input
                                id="checkbox-table-1"
                                type="checkbox"
                                className="w-2 h-2 text-blue-600 bg-gray-100 border-gray-300 rounded "
                              />
                              <label
                                htmlFor="checkbox-table-1"
                                className="sr-only"
                              >
                                checkbox
                              </label> */}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-4 h-4 mr-4 text-auraPrimary"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth="2"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2"
                                />
                              </svg>
                            </div>
                          </td>
                          <td
                            scope="row"
                            className="p-2 font-medium md:px-4 2xl:pl-6 md:py-4 tableColfit whitespace-nowrap"
                          >
                            {data.productName}
                          </td>
                          <td className="p-2 md:px-2 2xl:px-4 md:py-4 tableColfit">
                            <span
                              className={`px-4 py-2 ${
                                data.bookingStatus
                                  .toLowerCase()
                                  .includes('pending') ||
                                data.bookingStatus
                                  .toLowerCase()
                                  .includes('reject') ||
                                data.bookingStatus
                                  .toLowerCase()
                                  .includes('cancel')
                                  ? 'text-auraPrimary bg-auraPrimary100 '
                                  : 'text-green-400 bg-green-50'
                              }  rounded-lg whitespace-nowrap`}
                            >
                              {
                                evaluateStatus(data, false, activeTab.toString())
                                // data.hostResponse
                              }
                            </span>
                          </td>
                          <td className="p-2 md:px-2 2xl:px-4 md:py-4 tableColfit  whitespace-nowrap">
                            ₦{' '}
                            {data.totalPayable
                              ? data.totalPayable?.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : 0}
                          </td>
                          {/* <td
                            className={`p-2 md:px-2 2xl:px-4 md:py-4 whitespace-nowrap ${
                              data.paymentStatus
                                ? 'text-green-500'
                                : 'text-red-500'
                            }`}
                          >
                            {data.paymentStatus
                              ? data.paymentStatus
                              : 'Not Paid'}
                          </td> */}
                          <td className="p-2 md:px-2 2xl:px-4 md:py-4 tableColfit">
                            {data?.checkInDate
                              ? formatDate(new Date(data?.checkInDate))
                              : 'N/A'}
                          </td>
                          {( activeTab.toString() === "31" 
                            || activeTab.toString() === "32") ? ''
                            :  
                            <td className="p-2 md:px-2 2xl:px-4 md:py-4 tableColfit">
                              {data?.checkOutDate
                                ? formatDate(new Date(data?.checkOutDate))
                                : 'N/A'}
                            </td>
                          }
                          
                          <td className="p-2 md:px-2 2xl:px-4 md:py-4">
                            <div
                              style={{
                                // marginLeft: 70,
                                clear: 'both',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              <Popover
                                placement="bottomLeft"
                                content={
                                  <div className="flex flex-col space-y-2 items-start justify-start">
                                    <button
                                      className="hover:text-auraPrimary"
                                      onClick={() => {
                                        setOpenModal(true);
                                        dispatch(saveSelectedBooking(data));
                                      }}
                                    >
                                      View Details
                                    </button>

                                    {(evaluateStatus(data, true, '')
                                      .toLowerCase()
                                      .includes('ongoing') ||
                                      evaluateStatus(data, true, '')
                                        .toLowerCase()
                                        .includes('approved') ||
                                      evaluateStatus(data, true, '')
                                        .toLowerCase()
                                        .includes('awaiting payment')) &&
                                      data?.paymentMethodMode != 'PAYSMALLSMALL' &&
                                    (data.paymentStatus === null ||
                                      data.paymentStatus.toLowerCase() ===
                                        'not paid') ? (
                                      <>
                                        <button
                                          className="proceed-to-pay hover:text-auraPrimary"
                                          onClick={() => {
                                            navigate(
                                              `/confirm-pay/${data.bookingID}`
                                            );
                                          }}
                                        >
                                          Make Payment
                                        </button>
                                        <button
                                          className="Cancelled_Reservation hover:text-auraPrimary whitespace-nowrap"
                                          onClick={() => {
                                            setopenConfirmCancelModal(true);
                                          }}
                                        >
                                          Cancel Reservation
                                        </button>
                                      </>
                                    ) : data.bookingStatus
                                        .toLowerCase()
                                        .includes('cancelled') ||
                                      data.bookingStatus
                                        .toLowerCase()
                                        .includes('rejected') ? (
                                      ''
                                    ) : (
                                      ''
                                    )}

                                    {(
                                      evaluateStatus(data, true)
                                        .toLowerCase()
                                        .includes('concluded') ||
                                      evaluateStatus(data, true)
                                        .toLowerCase()
                                        .includes('cancelled by user')) && (
                                        <button
                                        className="hover:text-auraPrimary whitespace-nowrap"
                                        onClick={() => {
                                          navigate(
                                            `/${getProductName()}/${
                                              selectedBooking?.productDetailsID
                                            }`
                                          );
                                        }}
                                      >
                                        Rebook
                                      </button>
                                    )}

                                    {(evaluateStatus(data, true)
                                      .toLowerCase() === "paid") && <button
                                          className="hover:text-auraPrimary whitespace-nowrap"
                                          onClick={() => {
                                            messageHost(data)
                                          }}
                                        >
                                          Message Host
                                        </button>}

                                    {(data.bookingStatus
                                      .toLowerCase()
                                      .includes('created') ||
                                      evaluateStatus(data, true)
                                      .toLowerCase() === "paid" ||
                                      evaluateStatus(data, true)
                                      .toLowerCase() === "pending host approval") && (
                                        <>

                                          <button
                                            className="hover:text-auraPrimary whitespace-nowrap"
                                            onClick={() => {
                                              setopenConfirmCancelModal(true);
                                            }}
                                          >
                                            Cancel Reservation
                                          </button>
                                          {/* <button
                                          className="hover:text-auraPrimary whitespace-nowrap"
                                          onClick={() => {
                                            messageHost(data)
                                          }}
                                        >
                                          Message Host
                                        </button> */}
                                      </>
                                    )}

                                    { (!data.productName.includes('restaurant') && !data.productName.includes('photo')) &&
                                    (evaluateStatus(data, true)
                                      .toLowerCase() === "paid") && (
                                        <button
                                          className="hover:text-auraPrimary whitespace-nowrap"
                                          onClick={() => {
                                            setSelectedData(data)
                                            setOpenModal2(true)
                                          }}
                                        >
                                          CheckIn
                                        </button>
                                    )}

                                    {/* for bookings approved and paid for */}
                                    {(
                                      (evaluateStatus(data, true).toLowerCase().includes('ongoing') || 
                                      evaluateStatus(data, true).toLowerCase().includes('approved'))
                                       &&
                                      data.paymentStatus
                                        .toLowerCase() === 'paid') && (
                                      <>
                                        {approvedPaidActions.map(actions => {
                                          return <button
                                            className="hover:text-auraPrimary whitespace-nowrap"
                                            onClick={()=> actions.action(data)}
                                          >
                                            {actions.name}
                                          </button>
                                      })}
                                      </>)
                                    }

                                    {/* for bookings concluded */}
                                    {evaluateStatus(data, true)
                                      .toLowerCase()
                                      .includes('concluded') && (
                                      <>
                                        {concludedActions.map((actions) => {
                                          return (
                                            <button
                                              className="hover:text-auraPrimary whitespace-nowrap"
                                              onClick={() => actions.action(data)}
                                            >
                                              {actions.name}
                                            </button>
                                          );
                                        })}
                                      </>
                                    )}
                                  </div>
                                }
                                trigger="click"
                              >
                                <Button
                                  className="text-auraPrimary hover:text-auraPrimary"
                                  style={{ border: 0 }}
                                  onClick={() => {
                                    dispatch(saveSelectedBooking(data));
                                    dispatch(
                                      getBookingByBookingId(data.bookingID)
                                    );
                                    dispatch(bookingRouteFrom('profilePage'));
                                  }}
                                >
                                  ...
                                </Button>
                              </Popover>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr className="bg-white border-b hover:bg-gray-50 ">
                        <td className="w-4 py-4">
                          <div className="flex items-center"></div>
                        </td>
                        <th
                          scope="row"
                          className="p-2 font-medium md:px-4 2xl:px-6 md:py-4"
                        ></th>
                        <td className="p-2 md:px-2 2xl:px-4 md:py-4"></td>
                        <td className="p-2 md:px-2 2xl:px-4 md:py-4"></td>
                        <td className="p-2 md:px-2 2xl:px-4 md:py-4">
                          No data
                        </td>
                        <td className="p-2 md:px-2 2xl:px-4 md:py-4"></td>
                        <td className="p-2 md:px-2 2xl:px-4 md:py-4"></td>
                      </tr>
                    )}
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
      </div>

      <div className="flex w-full justify-end pt-6 pb-12">
        <Pagination
          defaultCurrent={pageNumber || 1}
          total={bookingsData.totalRecords}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

const evalDate = date =>{
  const givenDate = new Date(date);
  const today = new Date();

  // Set the time to 00:00:00 to compare only dates
  today.setHours(0, 0, 0, 0);
  givenDate.setHours(0, 0, 0, 0);

  // Get the time difference in milliseconds
  const timeDiff = givenDate.getTime() - today.getTime();
  return timeDiff === 86400000;

  // if (today > givenDate) {
  //   return true; 
  // } else {
  //   return false; 
  // }
}

export const evaluateStatus = (data: any, noReact = false, activeTab = '') => {
  var extra = moment().format('YYYY-MM-DD') + ' ';
  var start_time = moment(extra + '14:00');
  var end_time = moment(extra + '24:00');

  const status =
    //check if chout date/time is past 2pm
    (
      ( activeTab.toString() === "31" 
        || activeTab.toString() === "32"
      ) 
        && data?.paymentStatus?.toLowerCase() === 'paid'
        // &&  evalDate(data?.checkInDate)
        && moment().isAfter(moment(data?.checkInDate), 'days')
        
      )
    ? 'Concluded'
    : (
      ( activeTab.toString() === "31" 
        || activeTab.toString() === "32"
      ) 
        && data?.paymentStatus?.toLowerCase() === 'paid'
      )
    
    ? 'Ongoing'
    : data?.bookingStatus == 'Concluded'
    ? data?.bookingStatus 
    : data?.paymentMethodMode == 'PAYSMALLSMALL'
    ? data?.paymentStatus
    : data?.bookingStatus == 'Approved' &&
    data?.paymentStatus?.toLowerCase() === 'paid' &&
    data?.checkOutDate && 
    (moment().isAfter(moment(data?.checkOutDate).format('YYYY-MM-DD')) ||
      (moment().isSame(moment(data?.checkOutDate).format('YYYY-MM-DD')) &&
        moment().isBetween(start_time, end_time)))
      ? 'Concluded'
      : //check if checkin date/time has past 2pm
      data?.bookingStatus == 'Approved' &&
        data?.paymentStatus?.toLowerCase() == 'paid' &&
        data?.checkInDate &&
        (moment().isAfter(moment(data?.checkInDate).format('YYYY-MM-DD')) ||
          (moment().isSame(moment(data?.checkInDate).format('YYYY-MM-DD')) &&
            moment().isBetween(start_time, end_time)))
      ? 'Ongoing'
      : //check if checkin data have not reached but paid
      data?.bookingStatus == 'Approved' &&
        data?.paymentStatus?.toLowerCase() == 'paid' &&
        data?.checkInDate &&
        moment().isBefore(moment(data?.checkInDate).format('YYYY-MM-DD'))
      ? 'Paid'
      : data?.hostResponse == 'Rejected by Host'
      ? 'Declined by Host'
      : data?.bookingType == 'Experience'
      ? data?.bookingStatus
      : data?.hostResponse ;

  return noReact ? status : <>{status}</>;
};