import { Modal } from 'components/common';
import React, {useState, useEffect} from 'react';
import lovedimage from '../../../assets/img/success.svg';
import Rating from '@mui/material/Rating';
import { Dots } from 'components/common/Loader';
import reviewService from 'services/reviewService';
import { useDispatch, useSelector } from 'react-redux';
import {
  //   ToastContextType,
  useToast,
} from 'components/common/Toast/ToastProvider';
import { RootState } from 'redux/rootReducer';
import ReviewSuccessModal from 'components/common/Modal/ReviewSuccessModal';

interface ReviewModalProp {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  // comment:string;
  setisLoading: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading:boolean;
  selectedData: any;
}
export default function ReviewBookingModal({
  isLoading,
  setOpenModal,
  setisLoading,
  openModal,
  selectedData
}: ReviewModalProp) {
  const dispatch = useDispatch();
  const { user } = useSelector((state: RootState) => state.user);
  const [rating, setRating] = useState(0)
  const [comment, setComment] = useState("")
  const [check, setcheck] = useState(false);
  const [ratingReasonID, setRatingReasonID] = useState("")

  const [openModal5, setOpenModal5] = useState(false);

  const toast = useToast();

  const reasons = [
    {
      ratingReasonID: 6,
      reason: 'Poor service',
    },
    {
      ratingReasonID: 7,
      reason: 'Unclean environment',
    },
    {
      ratingReasonID: 8,
      reason: 'Non responsive hosts',
    },
    {
      ratingReasonID: 9,
      reason: 'Incomplete Experience',
    },
    {
      ratingReasonID: 10,
      reason: 'Not to expectations',
    },
  ];

  const handleAddReview = async (e:any) => {
    // console.log(complaint)
    e.preventDefault();
    if(!rating) return;

    setisLoading(true);

    let date = new Date().toJSON();
    let data = {
      userID: user.userID,
      comment,
      productDetailsID: selectedData?.productDetailsID,
      rating: rating ? (rating as number) : 0,
      dateCreated: date
    }
    let selectedReason = reasons.find(r => r.ratingReasonID.toString() === ratingReasonID.toString())
    let reasonData = {
      "ratingReasonID": ratingReasonID,
      "reason": selectedReason?.reason,
      "isActive": true,
      "dateCreated": date,
      "dateDeactivated": date
    }

    // console.log(data, reasonData)
    
    try {
      await reviewService.createReview(data)
      if(rating !== 0 && rating <= 3 && reasonData.reason){
        await reviewService.createRatingReason(reasonData)
      }
      setisLoading(false);
      setRating(0);
      setComment("");
      setRatingReasonID("")
      setOpenModal(false)

      setOpenModal5(true)
      // toast?.success("Rating and review submitted successfully")
    } catch (e:any){
      setisLoading(false);
      // console.log(e)
      toast?.error(e?.response?.data?.data);
    }
  };

  useEffect(() => {
    if(
      !rating || (rating !== 0 && rating <= 3 && !ratingReasonID)
    ) return setcheck(true);

    setcheck(false);

  }, [rating, ratingReasonID]);

  return (
    <>
      <Modal
        children={
          <div className="relative w-full max-w-xl px-3 py-2 bg-white shadow-xl rounded-xl lg:max-w-xl">
            <div className="p-3 text-center md:p-10 w-full">
              <div className="px-6 py-6 text-center md:px-8  card-body w-full">
                <h2 className="mb-2 text-3xl font-bold">Ratings & Review</h2>
                <p className="mb-3">How was your experience at the hotel?</p>

                <Rating
                  name="simple-controlled"
                  value={rating}
                  onChange={(event:any, newValue:any) => {
                    setRating(newValue);
                  }}
                />

                <p className="mt-2 text-gray-800">Select a rating from 1-5</p>

                <form 
                  className=" w-full md:min-w-[24rem]"
                  onSubmit={handleAddReview}
                >
                  {(rating !== 0 && rating <= 3) && 
                  <div className="p-3 my-3 border rounded">
                    {reasons.map((data, index) => (
                      <div
                        className="flex items-center mb-4 space-x-3"
                        key={index}
                      >
                        <input
                          type="radio"
                          value={data.ratingReasonID}
                          checked={data.ratingReasonID.toString() === ratingReasonID.toString()}
                          onChange={(e) =>
                            setRatingReasonID(e.currentTarget.value)
                          }
                          name="default-radio"
                          className="Ratingled_Rating w-4 h-4 text-auraPrimary  bg-gray-100 border-gray-300 "
                        />
                        <label
                          htmlFor="default-radio-1"
                          className="ml-2 text-sm font-medium text-gray-900 "
                        >
                          {data.reason}
                        </label>
                      </div>
                    ))}
                  </div> }

                  <div className="my-6">
                    <label htmlFor="reason" className="block text-left">
                      Additional comments (optional)
                    </label>
                    <textarea
                      name=""
                      id=""
                      value={comment}
                      onChange={(e)=>setComment(e.target.value)}
                      rows={4}
                      className="mt-1 form-control"
                      placeholder=""
                    ></textarea>
                  </div>

                  <div className="mt-10 ">
                    <button
                      type="submit"
                      disabled={check}
                      className="Reviewled_Review block w-full px-4 py-3 text-base text-white btn bg-auraPrimary hover:bg-auraPrimary my-btn disabled:bg-orange-300 disabled:cursor-not-allowed"
                    >
                    {isLoading? <Dots/>:"Submit"}  
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        }
        openModal={openModal}
        setOpenModal={setOpenModal}
      />

      <ReviewSuccessModal
        openModal={openModal5}
        setOpenModal={setOpenModal5}
      />
    </>
  );
}
