import React from 'react';
import Modal from '.';
import FacebookIcon from 'assets/icon/FacebookIcon.svg';
import TwitterIcon from 'assets/icon/TwitterIcon.svg';
// import InstagramIcon from 'assets/icon/InstagramIcon.svg';
import EmailIcon from 'assets/icon/email.svg';
import LinkedinIcon from 'assets/icon/LinkedinIcon.svg';
import whatsapp from 'assets/img/whatsappIcon.png';
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  // FacebookIcon,
  // TwitterIcon,
  //   WhatsappIcon,
} from 'react-share';

import { UpcomingActivityServices } from 'services/upcomingActivities';

interface ShareModal {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  id?: any;
}

export default function ShareModal(props: ShareModal) {
  const params = window.location.pathname;
  const isActivity = params.includes('/activities')

  const shareApi = () => isActivity && UpcomingActivityServices.shareActivityCategoryById(props.id)
  return (
    <>
      <Modal
        children={
          <div className="relative min-w-lg w-full max-w-xl px-3 py-2 bg-white shadow-xl rounded-xl lg:max-w-xl text-center md:p-10 xl:px-8 ">
            <div className="px-8">
              <ul className="border rounded divide-y my-4">
                {/* <li>
                  <EmailShareButton
                    url={window.location.href}
                    subject="Aura"
                    body="Lorem ipsum"
                    title="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Rem, a illum quas asperiores commodi suscipit laudantium vero ducimus tempora, praesentium quia fuga animi quisquam hic itaque consequatur quam? Obcaecati, architecto."
                    className="flex items-center w-[15rem] justify-between p-3"
                    style={{ padding: '1rem' }}
                  >
                    <span className="capitalize">Email</span>
                    <img src={EmailIcon} className="w-6 h-6 cursor-pointer" />
                  </EmailShareButton>
                </li> */}
                <li>
                  <TwitterShareButton
                    url={window.location.href}
                    title="Kindly check is lovely event out..."
                    hashtags={['Aura']}
                    className="flex items-center w-[15rem] justify-between p-3"
                    style={{ padding: '1rem' }}
                    onClick={shareApi}
                  >
                    <span className="capitalize">Twitter</span>
                    <img src={TwitterIcon} className="w-6 h-6 cursor-pointer" />
                  </TwitterShareButton>
                </li>
                <li>
                  <FacebookShareButton
                    url={window.location.href}
                    title="Kindly check is lovely event out..."
                    hashtag={'Aura'}
                    className="flex items-center w-[15rem] justify-between p-3"
                    style={{ padding: '1rem' }}
                    onClick={shareApi}
                  >
                    <span className="capitalize">Facebook</span>
                    <img
                      src={FacebookIcon}
                      className="w-6 h-6 cursor-pointer"
                    />
                  </FacebookShareButton>
                </li>
                <li>
                  <WhatsappShareButton
                    url={window.location.href}
                    title="Kindly check is lovely event out..."
                    className="flex items-center w-[15rem] justify-between p-3"
                    style={{ padding: '1rem' }}
                    onClick={shareApi}
                  >
                    <span className="capitalize">Whatsapp</span>
                    <img
                      src={whatsapp}
                      className="w-6 h-6 cursor-pointer rounded"
                    />
                  </WhatsappShareButton>
                </li>
                <li>
                  <LinkedinShareButton
                    url={window.location.href}
                    title="Kindly check is lovely event out..."
                    className="flex items-center w-[15rem] justify-between p-3"
                    style={{ padding: '1rem' }}
                    onClick={shareApi}
                  >
                    <span className="capitalize">Linkedin</span>
                    <img
                      src={LinkedinIcon}
                      className="w-6 h-6 cursor-pointer"
                    />
                  </LinkedinShareButton>
                </li>
              </ul>
            </div>
          </div>
        }
        openModal={props.openModal}
        setOpenModal={props.setOpenModal}
      />
    </>
  );
}
