import moment from 'moment';
import MsgList from './MsgList';

function Inbox({ inboxMsg, setRelyMsg, setPageItem, searchedMessages }: any) {
    const handleMsgClick = (inbox: any) => {
        setRelyMsg({ ...inbox, from: 'inbox' });
        setPageItem('replyInbox');
    };

    return (
        <div className="  bg-white rounded-md  py-4 px-6 min-h-screen">
            <div className="flex justify-between">
                <h2 className="text-zinc-800 text-lg font-medium">Inbox</h2>
                <span className="tex-zinc-600 font-light">{moment(new Date()).format('DD/MM/YYYY')}</span>
            </div>

            {/* messaging listing section */}
            {searchedMessages && !searchedMessages?.length && inboxMsg.length > 1 ? (
                <div className="text-center text-auraPrimary py-5">No results found</div>
            ) : null}
            {inboxMsg.length ? null : <div className="text-center text-auraPrimary py-5">You have no incoming messages</div>}
            {(searchedMessages || inboxMsg)?.map((inbox: any) => (
                <MsgList key={inbox?.messageId} handleMsgClick={handleMsgClick} msgObj={inbox} isInbox={true} />
            ))}
        </div>
    );
}

export default Inbox;
