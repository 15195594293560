import { createSlice } from '@reduxjs/toolkit';
import formatDate, { today, tommorrow } from 'utils/date/dateFormat';
import { getLocation } from 'utils/location';

export interface FilterInitialState {
  filter: {
    formatted_address:string;
    longitude:any;
    latitude:any;
    checkIn:string;
    checkOut:string;
    nights:any;
    guest: any;
  };

  isLoading: boolean;
  error: Record<string, any>;
  filteredProduct:Record<string, any>;
}


const filterInitialState: FilterInitialState = {
  filter: {
    formatted_address:"",
    longitude: getLocation()?.latitude,
    latitude: getLocation()?.longitude,
    checkIn: today.toLocaleDateString(),
    checkOut: tommorrow.toLocaleDateString(),
    nights:1,
    guest: 1,
  },
  isLoading: false,
  error: {},
  filteredProduct:[],
};

const startLoading = (state: FilterInitialState): void => {
  state.isLoading = true;
};

const stopLoading = (
  state: FilterInitialState,
  action: { payload: Record<string, any> }
): void => {
  state.isLoading = false;
  state.error = action.payload;
};

const filter = createSlice({
  name: 'filter',
  initialState: filterInitialState,
  reducers: {
    loadingStart: startLoading,
    loadingStopped: stopLoading,
    filterSuccess: (state: any, { payload }: any) => {
      state.filter = payload;
      state.isLoading = false;
      state.error = {};
    },
    filteredProduct: (state: FilterInitialState, { payload }: any): void => {
      state.filteredProduct = payload;
      state.isLoading = false;
      state.error = {};
    },
  },
});

export const { loadingStart, loadingStopped, filterSuccess,filteredProduct } = filter.actions;
export default filter.reducer;
