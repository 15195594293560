import { createSlice } from '@reduxjs/toolkit';

export interface dealPromotionIdInitialState {
  id: string;
  isLoading: boolean;
  error: Record<string, any> | null;
}

const dealInitialState: dealPromotionIdInitialState = {
  id: '',
  isLoading: false,
  error: null,
};

function startLoading(state: dealPromotionIdInitialState): void {
  state.isLoading = false;
}

function stopLoading(
  state: dealPromotionIdInitialState,
  action: { payload: Record<string, any> | null }
): void {
  state.isLoading = false;
  state.error = action.payload;
}

function clearLoading(state: dealPromotionIdInitialState, payload: any) {
  state.isLoading = false;
  state.error = null;
}

const dealPromotionID = createSlice({
  name: 'deal',
  initialState: dealInitialState,
  reducers: {
    loadingStart: startLoading,
    loadingStopped: stopLoading,
    dealPromotionProductID: (
      state: dealPromotionIdInitialState,
      { payload }: any
    ): void => {
      state.id = payload;
      clearLoading(state, payload);
    },
  },
});

export const {
  loadingStart,
  loadingStopped,
  dealPromotionProductID,
} = dealPromotionID.actions;

export default dealPromotionID.reducer;
