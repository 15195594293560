import { FC, ReactNode } from 'react';
import SocialLogin from 'react-social-login';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>{
  type: 'button' | 'submit' | 'reset';
  children: ReactNode;
  variant?: string;
  className?: string;
  onClick?: any;
  onMouseEnter?: any;
  onMouseLeave?: any;
  disabled?: boolean;
}

const Button: FC<ButtonProps> = ({
  type = 'button',
  className,
  variant,
  onClick,
  onMouseEnter,
  onMouseLeave,
  disabled,
  children,
  ...rest
}) => {
  const variants: any = {
    primary: 'bg-auraPrimary text-white',
    neutral: 'border-2 border-gray-2 bg-white text-gray-6',
    outline:
      'border border-auraPrimary text-auraPrimary bg-white bg-auraPrimary100',
  };
  return (
    <button
      type={type}
      disabled={disabled}
      onClick={onClick}
      className={`rounded-lg px-4 outline-none ${className} ${
        variants[variant as string]
      }`}
      {...rest}
    >
      {children}
    </button>
  );
};

export default Button;
