import { createSlice } from '@reduxjs/toolkit';


export interface ReviewInitialState {
  reviews: Record<string, any>;
  isLoading: boolean;
  error: Record<string, any>;
  reviewedProduct:Record<string, any>;
  createdReview:Record<string, any>;
}

const reviewInitialState: ReviewInitialState = {
  reviews: {},
  isLoading: false,
  error: {},
  reviewedProduct:[],
  createdReview:{}
};

const startLoading = (state: ReviewInitialState): void => {
  state.isLoading = true;
};

const stopLoading = (
  state: ReviewInitialState,
  action: { payload: Record<string, any> }
): void => {
  state.isLoading = false;
  state.error = action.payload;
};

const review = createSlice({
  name: 'review',
  initialState: reviewInitialState,
  reducers: {
    loadingStart: startLoading,
    loadingStopped: stopLoading,
    reviewSuccess: (state: ReviewInitialState, { payload }: any): void => {
      state.reviews = payload;
      state.isLoading = false;
      state.error = {};
    },
    reviewedProduct: (state: ReviewInitialState, { payload }: any): void => {
      state.reviewedProduct = payload;
      state.isLoading = false;
      state.error = {};
    },
    createReviewSuccess: (state: ReviewInitialState, { payload }: any): void => {
      state.createdReview = payload;
    },
  },
});

export const { loadingStart, loadingStopped, reviewSuccess,reviewedProduct,createReviewSuccess } = review.actions;
export default review.reducer;
