import { ErrorMessage, Field } from 'formik';
import { FC } from 'react';

interface CheckboxProps {
  value?: any;
  name?: string;
  onChange?: any;
  label?: any;
  error?: any;
  className?: string;
  checked?:any;
  id?: any;
}

const Checkbox: FC<CheckboxProps> = ({
  value,
  name,
  onChange,
  label,
  error,
  className,
  checked,
  id
}) => {
  return (
    <>
      <div>
        <label htmlFor={name} className="flex gap-2 cursor-pointer">
          <Field
            type="checkbox"
            id={id || name}
            value={value}
            name={name}
            onChange={onChange}
            error={error}
            checked={checked}
            className="h-5 w-5 rounded bg-auraPrimary100 outline-none"
          />
          <span className={className}>{label}</span>
        </label>
      </div>
      {name && (
        <ErrorMessage
          render={(msg) => (
            <div className="text-start text-sm font-light text-red-500">
              {msg}
            </div>
          )}
          name={name as string}
        />
      )}
    </>
  );
};

export default Checkbox;
