import { Dispatch, AnyAction } from '@reduxjs/toolkit';
import { NavigateFunction } from 'react-router-dom';
import {
    loadingStart,
    loadingStopped,
    productSuccess,
    hotelSearch,
    apartmentSearch,
    selectedProduct,
    selectedProductDetail,
    selectedProductReview,
    addProductData,
    setProductPrice,
    setProductFilterButtonClick,
    clearSelectedProductServices,
    setCurrentProductType,
    // ProductInitialState,
    currentProductType,
    resetProductType,
} from './reducers';
import productService from 'services/productService';
import {
    CreateProductData,
    CreateProductServiceData,
    // ProductDetailsData,
    SubmitProductAmenityData,
    UpdateProductDetailsData,
} from 'services/productService/types';
import { ToastContextType } from 'components/common/Toast/ToastProvider';
import dataService from 'services/appData';
import Upload from 'pages/Onboarding/Common/upload';
import { includes } from 'lodash';

export const getProductsSearch =
    (
        productId: number,
        checkIn: string,
        checkOut: string,
        longitude: number,
        latitude: number,
        numberOfGuest: number,
        page: number,
        size: number,
        sort: number,
        navigate: NavigateFunction,
        toast: ToastContextType | undefined,
        category: string
    ) =>
    async (dispatch: Dispatch<AnyAction>): Promise<void> => {
        try {
            dispatch(loadingStart());
            const res = await dataService.getHotelsApartmentsAndExperiences({
                productId,
                checkIn,
                checkOut,
                longitude,
                latitude,
                numberOfGuest,
                page,
                size,
                sort,
            });
            const payload = res.data;

            switch (category) {
                case 'hotels':
                    dispatch(hotelSearch(payload));
                    navigate('/hotels');
                    break;
                case 'apartments':
                    dispatch(apartmentSearch(payload));
                    navigate('/apartments');
                    break;
                default:
                    break;
            }

            // const location = `/onboarding/${(category as string).toLowerCase()}/${
            //   productId
            // }/upload/${res.data.successResponse}?category=${(
            //   category as string
            // ).toLowerCase()}`;

            // if (category?.toLowerCase() === 'hotel') {
            //   return navigate(
            //     `/onboarding/${category?.toLowerCase()}/${productId}/offering/${
            //       res.data.successResponse
            //     }?category=${category?.toLowerCase()}`
            //   );
            // }

            // navigate(location);
        } catch (err: any) {
            dispatch(loadingStopped(err.response.data.title));
            (toast as ToastContextType).error(err.response.data.title, 5000);
            console.log(err);
        }
    };

interface UploadProps {
    icon?: string;
    size?: string;
    category?: string;
    productDetailsIDs: string;
}

export const createProduct =
    (
        data: any,
        navigate: NavigateFunction,
        toast?: ToastContextType,
        category?: string,
        productID?: string,
        productDetailsID?: number,
        updatExtras?: any
    ) =>
    async (dispatch: Dispatch<AnyAction>): Promise<void> => {
        try {
            dispatch(loadingStart());

            const updateData = {
                ...data,
                ...updatExtras
            };
            let res;
            if (data.isFormSubmitted) {
                res = await productService.editProduct(updateData);
            } else {
                res = await productService.createProduct(data);
            }

            const payload = res.data;

            // data.isFormSubmitted = true;

            dispatch(productSuccess(payload));
            // data.productDetailsID = res.data?.successResponse;
            dispatch(addProductData(data));

            const location = `/onboarding/${(category as string).toLowerCase()}/${productID}/offering/${
                res.data?.successResponse
            }?category=${category as string}`;
            navigate(location);

            if (category?.toLowerCase() === 'apartment' && res.data.statusCode === 200) {
                return navigate(
                    `/onboarding/${category?.toLowerCase()}/${data.productID}/offering/${
                        res.data?.successResponse
                    }?category=${category?.toLowerCase()}`
                );
            }
        } catch (err: any) {
            dispatch(loadingStopped(err.response?.data.title ? err.response.data.title : err.response?.data.responseMessage));
            (toast as ToastContextType).error(
                err.response.data.title ? 'Insert a valid details (e.g contact/bvn)' : err.response.data.responseMessage,
                5000
            );
            console.log(err);
        }
    };

export const submitProductAmenities =
    (
        { productID, productDetailID, productAmenityID }: SubmitProductAmenityData,
        {amenityID, savedAmenitiesIDs}: any,
        navigate?: any,
        toast?: ToastContextType,
        category?: string
    ) =>
    async (dispatch: Dispatch<AnyAction>): Promise<void> => {
          const result = savedAmenitiesIDs?.map((amenity: any) => amenity.productAmenitiesID )
          //converting result2 to numbers
          const result2 = productAmenityID?.map((amenity: any) => {
            return +amenity
            })
        //how to compare two array
        const compare = result?.filter((amenity: any) => {
            return result2?.find(selected=> selected === amenity)
          }) 
        const newAmen = result2.filter( ( item ) => !compare.includes( item ) );
        //   conver newAmen to string
          var newlyAddedAmen = newAmen.join().split(',')
        try {
            dispatch(loadingStart());
            if (result.length > 0) {
                if (newAmen.length>0) {
                    const res = await productService.submitProductAmenities({
                        productDetailID,
                        productAmenityID: newlyAddedAmen,
                    });
                    dispatch(productSuccess(res.data));
                    const location = `/onboarding/${category?.toLowerCase()}/${productID}/setup/${productDetailID}?category=${category?.toLowerCase()}`;
                    res.data.statusCode === 200 && navigate(location);
                    dispatch(loadingStopped(productAmenityID));
                    if (category?.toLowerCase() === 'apartment' && res.data.statusCode === 200) {
                        return navigate(`/onboarding/apartment/${productID}/type/${productDetailID}`);
                    }
                } else {
                    dispatch(loadingStopped(productAmenityID));
                    const location = `/onboarding/${category?.toLowerCase()}/${productID}/setup/${productDetailID}?category=${category?.toLowerCase()}`;
                    navigate(location);
                    if (category?.toLowerCase() === 'apartment') {
                        return navigate(`/onboarding/apartment/${productID}/type/${productDetailID}`);
                    }
                }
            } else if (productAmenityID.length <= 0) {
                dispatch(loadingStopped(productAmenityID));
                toast?.error('Please Select Amenities');
            } else if(result.length <=0) {
                const res = await productService.submitProductAmenities({
                    productDetailID,
                    productAmenityID,
                });
                dispatch(productSuccess(res.data));
                const location = `/onboarding/${category?.toLowerCase()}/${productID}/setup/${productDetailID}?category=${category?.toLowerCase()}`;
                res.data.statusCode === 200 && navigate(location);
                if (category?.toLowerCase() === 'apartment' && res.data.statusCode === 200) {
                    return navigate(
                        `/onboarding/apartment/${productID}/type/${productDetailID}?category=${category?.toLowerCase()}`
                    );
                }
            }
        } catch (err: any) {
            console.log(err);
            dispatch(loadingStopped(err.response.data.title));
            toast?.error(err.response.data.title, 5000);
        }
    };

export const createProductService =
    (
        hotels: CreateProductServiceData,
        { productID, productDetailsID, type }: any,
        navigate: NavigateFunction,
        toast?: ToastContextType,
        category?: string,
        fetchedHotels?: any
    ) =>
    async (dispatch: Dispatch<AnyAction>): Promise<void> => {
        // console.log('fetchedHotelsd', fetchedHotels);

        try {
            dispatch(loadingStart());
            const res = await productService.createProductService(hotels);
            const { data } = res;

            const updateProductSuccess : any = [...fetchedHotels, ...data];

            // console.log('updateProductSuccess', updateProductSuccess);
            dispatch(productSuccess(updateProductSuccess));

            // console.log('success');
            const location = `/onboarding/${category?.toLowerCase()}/${productID}/upload/${productDetailsID}?category=${category?.toLowerCase()}`;
            res.responseMessage === 'Ok' && navigate(location, { state: data });
            if (category?.toLowerCase() === 'apartment' && res.responseMessage === 'Ok') {
                return navigate(
                    `/onboarding/apartment/${productID}/upload/${productDetailsID}?category=${category?.toLowerCase()}&type=${type}`,
                    { state: data }
                );
            }
        } catch (err: any) {
            console.log(err);

            dispatch(loadingStopped(err.response.data.title));
            toast?.error(err.response.data.title, 5000);
        }
    };

export const updateProductDetails =
    (
        { productDetailsID, cancellationPolicy, checkIn, checkOut, minBookingNumber, maxBookingNumber }: UpdateProductDetailsData,
        toast: ToastContextType
    ) =>
    async (dispatch: Dispatch<AnyAction>): Promise<void> => {
        try {
            dispatch(loadingStart());

            const res = await productService.updateProductDetails({
                productDetailsID,
                cancellationPolicy,
                checkIn,
                checkOut,
                minBookingNumber,
                maxBookingNumber,
            });

            dispatch(productSuccess(res.data));

            // const location = `/onboarding/product/${productID}/setup/${productDetailID}`;
            // navigate(location);
        } catch (err: any) {
            console.log(err);
            dispatch(loadingStopped(err.response.data.title ? err.response.data.title : err.response.data.responseMessage));
            toast?.error(err.response.data.title ? err.response.data.title : err.response.data.responseMessage, 5000);
        }
    };

export const updateProductPictures =
    ({ productDetailsID, productID, coverImage, otherImages }: any, toast?: ToastContextType) =>
    async (dispatch: Dispatch<AnyAction>): Promise<void> => {
        try {
            dispatch(loadingStart());

            const formData = new FormData();
            formData.append('productDetailsID', String(productDetailsID));
            if (coverImage && coverImage.length > 0) {
                formData.append('CoverImage', coverImage[0]);
            }

            if (otherImages && otherImages.length > 0) {
                for (let i = 0; i < otherImages.length; i++) {
                    formData.append('OtherImages', otherImages[i]);
                }
            }

            await productService.updateProductPictures(formData);

            // dispatch(productSuccess('Upload Success'));
        } catch (err: any) {
            console.log(err);
            dispatch(loadingStopped(err.response.data.title));
            toast?.error(err.response.data.title, 5000);
        }
    };

export const updateServicePictures =
    ({ serviceID, otherImages }: any, toast?: ToastContextType) =>
    async (dispatch: Dispatch<AnyAction>): Promise<void> => {
        try {
            dispatch(loadingStart());

            const formData = new FormData();
            formData.append('ServiceID', String(serviceID));
            if (otherImages && otherImages.length > 0) {
                for (let i = 0; i < otherImages.length; i++) {
                    formData.append('File', otherImages[i]);
                }
            }

            await productService.updateServicePictures(formData);

            // dispatch(productSuccess('Upload Success'));
        } catch (err: any) {
            console.log(err); 
            dispatch(loadingStopped(err.response.data.title));
            toast?.error(err.response.data.title, 5000);
        }
    };

export const saveSelectedProduct = (data: any) => (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(selectedProduct(data));
    } catch (err: any) {
        console.log(err);
    }
};

export const saveSelectedProductDetail = (data: any) => (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(selectedProductDetail(data));
    } catch (err: any) {
        console.log(err);
    }
};
export const setProductPriceData =
    (data: any
        // { selectedServices: Array<any>; totalPrice: number }
        ) => (dispatch: Dispatch<AnyAction>) => {
        try {
            dispatch(setProductPrice(data));
        } catch (err: any) {
            console.log(err);
        }
    };

export const getProductReview =
    (serviceID: number | string) =>
    async (dispatch: Dispatch<AnyAction>): Promise<void> => {
        try {
            dispatch(loadingStart());
            const res = await productService.getProductReviewsByServiceID(serviceID);
            const payload = res.data;

            dispatch(selectedProductReview(payload));
        } catch (err: any) {
            dispatch(loadingStopped(err.response.data.title));
            console.log(err);
        }
    };

export const setProductFilterButtonClicked = (data: any) => (dispatch: Dispatch<AnyAction>) => {
    dispatch(setProductFilterButtonClick(data));
};

export const ClearSelectedProductServices = () => (dispatch: Dispatch<AnyAction>) => {
    dispatch(clearSelectedProductServices());
};

export const SetCurrentProductType = (data: any) => (dispatch: Dispatch<AnyAction>) => {
    dispatch(setCurrentProductType(data));
};

export const ResetProductType = () => (dispatch: Dispatch<AnyAction>) => {
    dispatch(resetProductType());
};
