import { Avatar } from '@mui/material';
import moment from 'moment';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import MenuBar from './Menubar';
import { AdminServices } from 'services/adminService';
import { useEffect, useState } from 'react';
import { useToast } from 'components/common/Toast/ToastProvider';
import { Dots } from 'components/common/Loader';

function Reply({ replyMsg, inboxMsg, setInboxMsg, getAllMessages }: any) {
    const toast = useToast();

    const [isSubmitting, setSubmitting] = useState(false);

    const editor = useEditor({
        extensions: [StarterKit],
        content: '',
    });

    const readMessage = async () => {
        if (replyMsg.isMessageRead || replyMsg?.from !== 'inbox') return;
        try {
            await AdminServices.readMessage(replyMsg.messageId);
            const msgs = inboxMsg;
            const msgIndex = msgs.findIndex((msg: any) => msg.messageId === replyMsg?.messageId);
            const updateMsgRead = {
                ...inboxMsg[msgIndex],
                isMessageRead: true,
            };
            msgs[msgIndex] = updateMsgRead;
            setInboxMsg(msgs);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        readMessage();
    }, [replyMsg]);

    const reply = async () => {
        if (!editor?.getText()) return toast?.error("Message body can't be empty!");
        setSubmitting(true);
        try {
            await AdminServices.createMessage({
                bookingId: replyMsg?.bookingId,
                messageSubject: replyMsg?.messageSubject,
                // messageBody: editor.getHTML(),
                messageBody: editor.getText({ blockSeparator: '\n\n' }),
            });
            toast?.success('Message sent!');
            editor.commands.clearContent();
            setSubmitting(false);
            getAllMessages();
        } catch (error: any) {
            console.log(error);
            setSubmitting(false);
            if (error?.response?.data?.responseCode === '01')
                toast?.error(
                    error?.response?.data?.responseMessage
                        ? error?.response?.data?.responseMessage
                        : 'Message sending failed, please try again!'
                );
        }
    };

    return (
        <div className="flex-1  bg-white rounded-md  h-screen py-4 px-6 ">
            {/* messaging listing section */}
            <div className="my-6">
                <div className="flex justify-between mb-10 items-center">
                    <div className="flex items-center">
                        <Avatar sx={{ width: 28, height: 28 }} alt="" src="/static/images/avatar/1.jpg" />
                        <h2 className="text-zinc-800 ml-4 text-lg font-medium">{replyMsg?.sentByUserName}</h2>
                    </div>
                    <h3 className="text-zinc-800 ml-4  font-medium">
                        <span className="text-zinc-600 text-sm">Ref</span>#{replyMsg?.messageRef}
                    </h3>
                    <span className="tex-zinc-600 text-sm font-light">
                        {moment(replyMsg?.dateCreated).format('hh:mm A MMM DD YYYY')}
                    </span>
                </div>
                <div className="my-3">
                    <h2 className="text-zinc-800 mb-3 text-lg font-medium">{replyMsg?.messageSubject}</h2>
                    <p className="text-zinc-600 mt-1 text-sm whitespace-pre-wrap">{replyMsg?.messageBody}</p>
                    {/* <div dangerouslySetInnerHTML={{ __html: replyMsg?.messageBody }} /> */}
                </div>
            </div>

            {replyMsg?.from === 'inbox' ? (
                <>
                    <button onClick={() => reply()} className="text-md text-orange-400">
                        {isSubmitting ? 'Sending...' : 'Reply'}
                    </button>

                    <div className="bg-gray-200 rounded-[8px] p-4 mt-10">
                        <MenuBar editor={editor} />
                        <EditorContent editor={editor} />
                    </div>

                    <div className="flex mt-5">
                        <button onClick={() => reply()} className="bg-auraPrimary text-white w-28  py-3 rounded-md">
                            {isSubmitting ? <Dots /> : 'Send'}
                        </button>
                        <button
                            type="button"
                            className="border-auraPrimary border  text-auraPrimary w-28 py-3 rounded-md ml-5"
                            onClick={() => {
                                editor?.commands.clearContent();
                            }}
                        >
                            Discard
                        </button>
                    </div>
                </>
            ) : null}
        </div>
    );
}

export default Reply;