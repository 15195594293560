import { Avatar } from '@mui/material';
import moment from 'moment';
interface props {
    msgObj: any;
    handleMsgClick: (value: any) => void;
    isInbox?: boolean;
}
const MsgList = ({ msgObj, handleMsgClick, isInbox }: props) => {
    return (
        <button
            className="my-7 block w-full text-left group"
            onClick={() => {
                handleMsgClick(msgObj);
            }}
        >
            <div className="flex justify-between items-center">
                <div className="flex items-center">
                    <Avatar sx={{ width: 32, height: 32 }} alt="" src="/static/images/avatar/1.jpg" />
                    <h2
                        className={`text-zinc-800 ml-4 text-lg  group-hover:text-auraPrimary ${
                            isInbox && !msgObj.isMessageRead ? 'font-medium' : ''
                        }`}
                    >
                        {msgObj?.sentByUserName}
                    </h2>
                </div>
                <span className={`text-gray-600 text-xs  ${isInbox && !msgObj.isMessageRead ? 'text-auraPrimary' : ''}`}>
                    ({moment(msgObj?.dateCreated).format('hh:mm')} - {moment(msgObj?.dateCreated).fromNow()})
                </span>
            </div>
            <p
                className={`text-gray-600 mt-1 text-sm  ml-[48px] line-clamp-2 ${
                    isInbox && !msgObj.isMessageRead ? 'font-medium' : ''
                } `}
            >
                {msgObj.messageBody}
            </p>
        </button>
    );
};

export default MsgList;
