import deal1 from 'assets/img/deal1.png';
import rate from 'assets/icon/rate.svg';
import wine from 'assets/icon/wine.svg';
import bed from 'assets/icon/bed.svg';
import { FC, ReactNode } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import corporate from 'assets/icon/corporate-icon.svg';

interface Props {
    image: string;
    className?: string;
    onClick?: any;
    name: string;
    location: string;
    bedrooms?: number;
    productDetailsID: string;
    productType: string;
    productID?: any;
    corporatePrice: number;
}

const ProductItem: FC<Props> = ({
    bedrooms,
    image,
    className,
    onClick,
    name,
    location,
    productDetailsID,
    productType,
    productID,
    corporatePrice,
    ...rest
}) => {
    const getProductName = (param: any) => {
        if (param === 29) return 'Hotel';
        if (param === 30) return 'Apartment';
        if (param === 33) return 'Experience';
        if (param === 34) return 'Workspaces';
        if (param === 31 ) return "Restaurant"
        if (param === 32 ) return "Photography"
    };

    const parameter = window.location.pathname;
    const lowerCaseProductType = productID ? getProductName(productID)?.toLowerCase() : productType.toLowerCase();
    const renderProductType =
        lowerCaseProductType === 'workspaces' 
        || lowerCaseProductType === 'photography' 
        || lowerCaseProductType === 'activities'
        || lowerCaseProductType === 'restaurant'
        || lowerCaseProductType === 'experience'
        || lowerCaseProductType == 'hotel'
        ? lowerCaseProductType
        : `${lowerCaseProductType}s`;

    const [searchParams] = useSearchParams();
    const checkIn = searchParams.get('checkIn');
    const checkOut = searchParams.get('checkOut');
    const guest = searchParams.get('guest');

    return (
        <Link to={`/${renderProductType}/${productDetailsID}${checkIn && checkOut && guest ? `?checkIn=${checkIn}&checkOut=${checkOut}&guest=${guest}` : ``}`}>
            <div className="flex flex-col rounded-lg relative">
            <div className="">
                <img src={image} className="w-full rounded-t-xl h-72 object-cover" alt="" />
                {corporatePrice > 0 && (
                    <div className="discount-off bg-white p-2 gap-2 flex text-black">
                        <img src={corporate} className="w-full rounded-t-xl h-25 object-cover" alt="corporate" /> 
                    <span>
                        corporate
                    </span>
                    </div>
                )}
            </div>
                {/* <div className="">
                    <img src={image} className="w-full rounded-t-xl h-72 object-cover" alt="" />
                </div> */}
                
                <div className="py-6 px-4 border-0">
                    <p className="text-base font-medium">{name}</p>
                    <div className="flex mt-2">
                        <div>
                            <p className="text-sm text-neutral">{location}</p>
                        </div>
                        <div className="ml-3">
                            {/* <div className="flex items-center">
                                <img src={productType === 'activities' ? wine : bed} alt="" />
                                {productType !== 'activities' && <span className="tx-sm ml-1"></span>}
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </Link>
    );
};

export default ProductItem;
