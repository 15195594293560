import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Spinner } from 'components/common/Loader';
import dataService from 'services/appData';
import { Modal } from 'components/common';
import successIcon from 'assets/icon/reserve-modal.svg';
import failureIcon from 'assets/icon/error.svg';

const SuccessModal: FC = (info: any) => {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(true);
  const [isLoading, setisLoading] = useState(true);
  const [data, setData] = useState({
    responseCode: '',
    responseMessage: '',
  });
    
      return (
        <Modal
          openModal={openModal}
          setOpenModal={setOpenModal}
          className="pt-16 pb-12 bg-white px-4 sm:px-10 w-full max-w-[542px]"
          onOutsideClick={() => navigate('/login')}
          onModalClose={() => navigate('/login')}
        >
          <div className="p-6 md:py-12 flex flex-col items-center justify-center space-y-6">
            <img src={successIcon} className="w-40 " alt="" />
            <h3 className="mb-5 text-3xl font-semibold">Successful!</h3>
            <p className="text-base text-neutral font-normal text-center">
              {info.responseMessage ?? 'Your password has been successfully changed.' }
            </p>
            <div className="pb-8 w-full flex">
              <Link
                to="/login"
                className=" py-3 rounded-xl text-base text-center font-normal mt-8 mx-auto px-6 bg-auraPrimary text-white hover:text-white capitalize"
              >
                Log In
              </Link>
            </div>
          </div>
        </Modal>
      );
};

export default SuccessModal;
