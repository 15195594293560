import { Dispatch, FC, SetStateAction, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dots } from 'components/common/Loader';
import {
  ToastContextType,
  useToast,
} from 'components/common/Toast/ToastProvider';
import { Modal, Button, Input } from 'components/common';
import success from 'assets/img/success.svg';
import {FaQuestion} from "react-icons/fa";
import bookingService from 'services/bookingService';


interface BookingDetailModalProps {
  openModal?: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
  setisLoading: Dispatch<SetStateAction<boolean>>;
  selectedData: any;
}

const CheckinModal: FC<BookingDetailModalProps> = ({
  openModal = true,
  setOpenModal,
  isLoading,
  setisLoading,
  selectedData,
}) => {
  const toast = useToast();

  // const dispatch = useDispatch();
  const navigate = useNavigate();

  console.log("selectedData", selectedData)

  async function confirmCheckIn (){

    setisLoading(true);
    
    try {
      await bookingService.checkInStatus(selectedData?.bookingID)
      setisLoading(false);
      setOpenModal(false)
      toast?.success("Check-In Confirmed")
    } catch (e:any){
      setisLoading(false);
      toast?.error(e?.response?.data?.data || "Something went wrong, please try again later");
    }
    
  }

  return (
    <Modal
    openModal={openModal as boolean}
    setOpenModal={setOpenModal as Dispatch<SetStateAction<boolean>>}
    className="py-2 px-4 sm:px-10 w-full max-w-[542px] bg-white shadow border border-auraPrimary rounded-xl"
    onOutsideClick={() => {}}
    onModalClose={
      setOpenModal ? () => setOpenModal(false) : () => navigate('/user-profile/bookings')
    }
  >
    <div
      className="flex items-center justify-center w-full"
    >
      <div className="w-full px-3 py-2 ">
        <div className="w-full">
          {/* <!-- Modal body --> */}
          <div className="p-3 text-center md:p-8 w-full">
            <div className="flex justify-center">
              <FaQuestion className="text-7xl" />
            </div>
            <h2 className="text-3xl font-bold mt-10">Check-in Inquiry</h2>
            <p className="py-3 text-lg text-neutral">
              Have you checked into your hotel room?
            </p>
            <div className="mt-5 flex flex-col gap-5">
              <button
                className="block w-full px-4 py-3 text-base text-white btn bg-auraPrimary hover:text-white hover:bg-auraPrimary my-btn"
                onClick={confirmCheckIn}
              >
                {isLoading ? <Dots /> : 'Yes, I have'}
              </button>

              <button
                className="block w-full px-4 py-3 text-base btn text-auraPrimary hover:border-[auraPrimary] hover:border my-btn"
                onClick={()=> setOpenModal(false)}
              >
                Not Yet
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Modal>
 
  );
}

export default CheckinModal;