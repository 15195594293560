import { ReactComponent as Bold } from './assets/bold.svg';
import { ReactComponent as Italic } from './assets/italic.svg';
import { ReactComponent as Quote } from './assets/quote.svg';
import { ReactComponent as UnderlineSvg } from './assets/underline.svg';
// import Underline from '@tiptap/extension-underline';

const MenuBar = ({ editor }: any) => {
    if (!editor) {
        return null;
    }

    return (
        <div className="pb-4 flex flex-wrap gap-4 editorMenu">
            <button
                onClick={() => editor.chain().focus().toggleBold().run()}
                disabled={!editor.can().chain().focus().toggleBold().run()}
                className={editor.isActive('bold') ? 'is-active' : ''}
                type="button"
            >
                <Bold />
            </button>
            <button
                onClick={() => editor.chain().focus().toggleItalic().run()}
                disabled={!editor.can().chain().focus().toggleItalic().run()}
                className={editor.isActive('italic') ? 'is-active' : ''}
                type="button"
            >
                <Italic />
            </button>
            <button
                onClick={() => editor.chain().focus().toggleUnderline().run()}
                className={editor.isActive('underline') ? 'is-active' : ''}
                type="button"
            >
                <UnderlineSvg />
            </button>

            <button
                onClick={() => editor.chain().focus().setParagraph().run()}
                className={editor.isActive('paragraph') ? 'is-active' : ''}
                type="button"
            >
                P
            </button>
            <button
                onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
                className={editor.isActive('heading', { level: 1 }) ? 'is-active' : ''}
                type="button"
            >
                h1
            </button>
            <button
                onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
                className={editor.isActive('heading', { level: 2 }) ? 'is-active' : ''}
                type="button"
            >
                h2
            </button>

            <button
                onClick={() => editor.chain().focus().toggleBlockquote().run()}
                className={editor.isActive('blockquote') ? 'is-active' : ''}
                type="button"
            >
                <Quote />
            </button>
        </div>
    );
};

export default MenuBar;
