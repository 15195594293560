export const majorAmenities   = [
    {
      productAmenitiesID: 58,
      productID: 29,
      amenityName: 'Kitchen',
      amenityDescription: 'Kitchen',
      isStandOutBasic: true,
      isActive: true,
      dateCreated: '2022-07-19T00:09:00.6933333',
      dateDeactivated: null,
    },
    {
      productAmenitiesID: 59,
      productID: 29,
      amenityName: 'TV',
      amenityDescription: 'TV',
      isStandOutBasic: true,
      isActive: true,
      dateCreated: '2022-07-19T00:09:00.6933333',
      dateDeactivated: null,
    },
    {
      productAmenitiesID: 60,
      productID: 29,
      amenityName: 'Washer',
      amenityDescription: 'Washer',
      isStandOutBasic: true,
      isActive: true,
      dateCreated: '2022-07-19T00:09:00.6933333',
      dateDeactivated: null,
    },
    {
      productAmenitiesID: 41,
      productID: 29,
      amenityName: 'Pool',
      amenityDescription: 'Swimming Pool',
      isStandOutBasic: false,
      isActive: true,
      dateCreated: '2022-07-19T00:44:51.96',
      dateDeactivated: null,
    },
    {
      productAmenitiesID: 61,
      productID: 29,
      amenityName: 'Gym',
      amenityDescription: 'Gym',
      isStandOutBasic: true,
      isActive: true,
      dateCreated: '2022-08-28T02:42:46.7733333',
      dateDeactivated: null,
    },
    {
      productAmenitiesID: 62,
      productID: 29,
      amenityName: 'Room Service',
      amenityDescription: 'Room Service',
      isStandOutBasic: true,
      isActive: true,
      dateCreated: '2022-08-28T02:43:08.42',
      dateDeactivated: null,
    },
    {
      productAmenitiesID: 63,
      productID: 29,
      amenityName: 'Air Conditioning',
      amenityDescription: 'Air Conditioning',
      isStandOutBasic: true,
      isActive: true,
      dateCreated: '2022-08-28T02:43:38.22',
      dateDeactivated: null,
    },
  ];

  export const guestArr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
