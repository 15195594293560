import React, { useState, useEffect } from 'react';
import Rating from '@mui/material/Rating';
import ReviewModal from './ReviewModal';
import { createReview } from 'redux/review/action';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/rootReducer';
import { useNavigate, useParams } from 'react-router-dom';
import reviewService from 'services/reviewService';
import timeSince from 'utils/date/timeSince';
import ReviewSuccessModal from 'components/common/Modal/ReviewSuccessModal';
import moment from 'moment';

export default function ProductReviews({ productName, detailid }: any) {
    // const [count, setcount] = useState<number>(5);
    // const [value, setValue] = useState<number | null>(5);
    const [show, setShow] = useState(false);
    const { user } = useSelector((state: RootState) => state.user);
    const dispatch = useDispatch();
    const [comment, setcomment] = useState('');
    const [commentSlice, setcommentSlice] = useState(2);
    const [ratingReasonID, setratingReasonID] = useState<number | string>('0');
    const { detailsID } = useParams();
    const [rating, setRating] = React.useState<number | null>(null);
    const [sortComment, setsortComment] = useState('Newest');
    const [apiComments, setapiComments] = useState([]);
    const [replies, setreplies] = useState([]);
    const [isLoading, setisLoading] = useState(false);
    const [openModal, setopenModal] = useState(false);
    const [replyloading, setreplyloading] = useState(false);
    const [selectedReply, setselectedReply] = useState("");
    const [selectedReply2, setselectedReply2] = useState("");
    const [replyValue, setreplyValue] = useState("");


    const navigate = useNavigate();
    const pathname = window.location.pathname;

    const reasons = [
        {
          ratingReasonID: 6,
          reason: 'Poor service',
        },
        {
          ratingReasonID: 7,
          reason: 'Unclean environment',
        },
        {
          ratingReasonID: 8,
          reason: 'Non responsive hosts',
        },
        {
          ratingReasonID: 9,
          reason: 'Incomplete Experience',
        },
        {
          ratingReasonID: 10,
          reason: 'Not to expectations',
        },
    ];

    const handleAddReview = async () => {
        if (!rating) return;
        setisLoading(true);
        await dispatch(
            createReview({
                userID: user.userID,
                comment,
                ratingReasonID,
                productDetailsID: detailsID,
                rating: rating ? (rating as number) : 0,
            })
        );

        let date = new Date().toJSON(); 
        let selectedReason = reasons.find(r => r.ratingReasonID.toString() === ratingReasonID.toString())
        let reasonData = {
            "ratingReasonID": ratingReasonID,
            "reason": selectedReason?.reason,
            "isActive": true,
            "dateCreated": date,
            "dateDeactivated": date
        }

        if(rating !== 0 && rating <= 3 && reasonData.reason){
            await reviewService.createRatingReason(reasonData)
        }
        setisLoading(false);
        setShow(false);
        setopenModal(true);
        fetchApiComments();

        setRating(0);
        setcomment("");
        setratingReasonID("")
    };

    const fetchApiComments = async () => {
        try {
            const res = await reviewService.getReviewsByProductDetailsID(detailsID || detailid);
            setapiComments(res.data);
        } catch (error) {
            console.log(error);
        }
    };

    const handleReply = async (e:any) => {
        e.preventDefault();
        try {
            if(!replyValue) return;
            const res = await reviewService.replyReview({
                reviewID: selectedReply,
                message: replyValue
            });
            // setapiComments(res.data);
            handleReviewReply(selectedReply)
            setreplyValue("");
            setselectedReply("");
        } catch (error) {
            console.log(error);
        }
    };

    const handleReviewReply = async(id:any)=>{
        window.scrollTo(0,0)
        setselectedReply2(id)
        setreplyloading(true)
        try {
            const res = await reviewService.getReviewReply(id);
            setreplies(
                res.data?.reviewReplyModels || [],
            )
            setreplyloading(false)
        } catch (err: any) {
            setreplyloading(false)
            console.log(err)
        }
    }

    const convertDate = (params : Date) =>{
        const x = new Date(params);
        return x.toLocaleString()
    }

    useEffect(() => {
        window.scrollTo(0,0)
        let isCancelled = false;
        if (!isCancelled) {
            fetchApiComments();
            // alert(pathname)
        }
        return () => {
            isCancelled = true;
        };
    }, []);

    return (
        <>
            <ReviewModal
                rating={rating}
                setRating={setRating}
                openModal={show}
                setOpenModal={setShow}
                comment={comment}
                setcomment={setcomment}
                ratingReasonID={ratingReasonID}
                setratingReasonID={setratingReasonID}
                handleAddReview={handleAddReview}
                isLoading={isLoading}
            />
            <ReviewSuccessModal openModal={openModal} setOpenModal={setopenModal} />
            <div className="container py-20 md:pt-36 fit">
                <p className="text-2xl font-semibold">Add a review</p>

                <div className="flex flex-col sm:items-center justify-between gap-3 mt-4 md:flex-row w-full">
                    <div className="space-x-2">
                        <span className="font-medium">{productName}</span>
                    </div>
                    <div className='hidden'>
                        <div className="flex space-x-1">
                            <button className="bg-auraPrimary text-white px-3 py-2 rounded" onClick={() => {
                                    if(!user.firstName) return navigate(`/login?redirect=${pathname}`)
                                    setShow(true)
                                }
                                }>
                                Add a review
                            </button>
                        </div>
                    </div>
                </div>

                {/* <form className="mt-6">
        <label
          htmlFor="default-search"
          className="mb-2 text-sm font-medium text-gray-900 sr-only"
        >
          Share thought
        </label>
        <div className="relative">
          <input
            type="search"
            id="default-search"
            // className="block w-full p-4 pl-10 text-sm text-gray-900 bg-transparent border border-gray-300 rounded-lg"
            className="form-control block py-1 h-8 mb-1 placeholder:text-sm border-auraPrimary focus:border-auraPrimary100"
            placeholder="Share your thoughts"
            required
          />

          <div className="absolute inset-y-0 flex items-center pl-3 pointer-events-none right-32">
            <img src="assets/img/icon/smile.svg" alt="" />
          </div>
          <button
            type="submit"
            className="text-white absolute right-[1.5px] flex items-center bottom-[1.5px] bg-auraPrimary  focus:outline-none  font-medium rounded-lg text-sm px-4 py-2 "
          >
            Post it!
            <svg
              className="ml-2"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.39412 4.84282C9.66467 4.59308 10.0864 4.60995 10.3362 4.8805L12.49 7.2138C12.7258 7.46918 12.7258 7.8628 12.49 8.11818L10.3362 10.4515C10.0865 10.7221 9.66468 10.739 9.39413 10.4892C9.12358 10.2395 9.10671 9.81772 9.35644 9.54717L10.4775 8.33266L4.00016 8.33266C3.63197 8.33266 3.3335 8.03418 3.3335 7.66599C3.3335 7.2978 3.63197 6.99933 4.00016 6.99933L10.4775 6.99933L9.35645 5.78488C9.10671 5.51433 9.12358 5.09256 9.39412 4.84282Z"
                fill="#FCFCFD"
              />
            </svg>
          </button>
        </div>
      </form> */}

                <div>
                    <div className="flex flex-wrap items-center justify-between mt-6">
                        <div>
                            <p className="text-2xl font-semibold">{(apiComments || []).length} comments</p>
                        </div>
                        <div>
                            <form action="" className="bg-gray-100 rounded-md ">
                                <select
                                    id=""
                                    className="flex items-center gap-2 px-3 py-2 text-sm font-normal capitalize border-none "
                                    value={sortComment}
                                    onChange={(e) => setsortComment(e.target.value)}
                                >
                                    <option>Newest </option>
                                    <option>Oldest</option>
                                </select>
                            </form>
                        </div>
                    </div>
                    <div className="py-6 border-b">
                        {(apiComments || [])
                            .sort((a: any, b: any) => {
                                return sortComment === 'Newest'
                                    ? (new Date(b.dateCreated) as any) - (new Date(a.dateCreated) as any)
                                    : (new Date(a.dateCreated) as any) - (new Date(b.dateCreated) as any);
                            })
                            .slice(0, commentSlice)
                            .map((data: any, index) => (
                                <div className="flex flex-row items-start">
                                    <div className="flex ">
                                        <img src={data.picture} className="rounded-full w-8 h-8" alt="" />
                                    </div>

                                    <div className="w-full px-3" key={index}>
                                        <div className="flex justify-between">
                                            <h5 className="mb-1 font-medium text-neutral06">
                                                {data.firstName + ' ' + data.lastName}
                                            </h5>

                                            <Rating name="read-only" value={data.rating} readOnly />
                                        </div>
                                        <p className="text-neutral06">{data.comment}</p>
                                        <div className="flex items-center mt-2 space-x-4">
                                            <p className="text-xs text-neutral">
                                                {
                                                    convertDate(data.dateCreated)
                                                }
                                                {/* about {timeSince(new Date(data.dateCreated))} ago */}
                                            </p>
                                            {/* <button type="button" className="text-xs font-semibold">
                                                Like
                                            </button> */}
                                            <button 
                                                type="button" 
                                                onClick={()=> setselectedReply(data.reviewID)}
                                                className="text-xs font-semibold">
                                                Reply
                                            </button>
                                            <button 
                                                type="button" 
                                                onClick={()=>handleReviewReply(data.reviewID)}
                                                className="text-xs font-semibold text-auraPrimary">
                                                View Replies
                                            </button>
                                        </div>

                                        {(selectedReply2  === data.reviewID) && <div className="bg-white my-1">
                                            {
                                                replyloading ? <div className="bg-white p-2 text-center ">
                                                    fetching replies...
                                                </div> : <>
                                                    {
                                                        replies && replies.length > 0 ? <>
                                                            {
                                                                replies.map((reply:any) => {
                                                                    return <>
                                                                        <div className='p-2 '>
                                                                            <h5 className="font-medium text-neutral06">
                                                                                {reply.createdBy}
                                                                            </h5>
                                                                            <p className="text-neutral06">{reply.message}</p>
                                                                            <p className="text-xs text-neutral">
                                                                                {convertDate(reply.dateCreated)}
                                                                            </p>
                                                                        </div>
                                                                    </>
                                                                })
                                                            }
                                                        </> : <div className="bg-white p-2 text-center ">
                                                            No replies available yet
                                                        </div> 
                                                    }
                                                </>
                                            }
                                           
                                        </div>}

                                        {(selectedReply  === data.reviewID) && <div className="mt-4 replyBox" id="">
                                            <form onSubmit={handleReply}>
                                                <div className="relative flex gap-2">
                                                    <input
                                                    type="text"
                                                    id="input-group-1"
                                                    className=" border outline-0 shadow-sm py-1 h-8 mb-1 placeholder:text-sm border-orange-300 focus:border-auraPrimary100 w-[400px]"
                                                    value={replyValue}
                                                    onChange={(e)=> setreplyValue(e.target.value)}
                                                    placeholder="Enter response here"
                                                    />
                                                    <div className="">
                                                        <button 
                                                            type='submit'
                                                            className='h-8 px-3 bg-auraPrimary text-white'>
                                                            Send
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>}
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>

                <div className="flex items-center justify-center gap-4 my-6">
                    <button
                        className="flex items-center gap-3 px-3 py-2 font-semibold shadow-sm capitalize border rounded-3xl"
                        onClick={() => setcommentSlice(commentSlice + 2)}
                    >
                        Load more
                    </button>
                    <button
                        className="flex items-center gap-3 px-3 py-2 font-semibold bg-gray-200 capitalize rounded-3xl"
                        onClick={() => setcommentSlice(2)}
                    >
                        Show less
                    </button>
                </div>
            </div>
        </>
    );
}
