import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  //   ToastContextType,
  useToast,
} from 'components/common/Toast/ToastProvider';
import { Modal, Button, Input } from 'components/common';
import { RootState } from 'redux/rootReducer';
import { ReactComponent as CancelReason } from 'assets/icon/cancellation.svg';
import { Dots } from 'components/common/Loader';
// import bookingService from 'services/bookingService';
import { AdminServices } from 'services/adminService';


interface BookingDetailModalProps {
  openModal?: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
  setisLoading: Dispatch<SetStateAction<boolean>>;
  selectedData: any;
}

const ComplaintModal: FC<BookingDetailModalProps> = ({
  openModal = true,
  setOpenModal,
  isLoading,
  setisLoading,
  selectedData
}) => {
  const { user } = useSelector((state: RootState) => state.user);
  const [check, setcheck] = useState(false);
  const toast = useToast();
  const [complaint, setComplaint] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = async (e:any) => {
    // console.log(complaint)
    e.preventDefault();
    if(!complaint) return;

    setisLoading(true);

    let date = new Date().toJSON();

    const data = {
      "complainerName": selectedData?.firstName + " " + selectedData?.lastName,
      "emailAddress": selectedData?.emailAddress,
      "phoneNumber": selectedData?.phoneNumber,
      "ticketType": "string",
      "complaintDetails": complaint,
      "agentResponse": "string",
      "dateCreated": date,
      "dateUpdated": date,
      "approvedBy": 0,
      "status": "string"
    }
    
    try {
      await AdminServices.createComplaint(data)
      setisLoading(false);
      setComplaint("");
      setOpenModal(false)
      toast?.success("Your complaint has been submitted successfully")
    } catch (e:any){
      setisLoading(false);
      // console.log(e)
      toast?.error(e?.response?.data?.data);
    }
  };

  useEffect(() => {
    if(complaint) return setcheck(true);
    setcheck(false);

  }, [complaint]);

  return (
    <Modal
      openModal={openModal as boolean}
      setOpenModal={setOpenModal as Dispatch<SetStateAction<boolean>>}
      className="pt-2 pb-12 px-4 sm:px-10 w-full max-w-[542px] bg-white shadow border border-auraPrimary rounded-xl"
      onOutsideClick={() => {}}
      onModalClose={
        setOpenModal
          ? () => setOpenModal(false)
          : () => navigate('/user-profile/bookings')
      }
    >
      <div className="flex items-center justify-center w-full">
        <div className="w-full px-3 pt-8">
          <div className="w-full">
            {/* <!-- Modal body --> */}
            <div className="p-3 text-center w-full">
              {/* <div className="flex justify-center">
                <CancelReason />
              </div> */}
              <h2 className="mt-4 mb-5 text-3xl text-center font-bold">
                Complaints
              </h2>
              <div className="text-sm">
                Kindly fill the form below to lay  your complaints
              </div>

              <form 
                className="space-y-8 pt-4 mt-8"
                onSubmit={handleSubmit}
              >
                <div className="mb-2">
                  <label htmlFor="reason" className="block text-left">
                    What's your complaint?{' '}
                    <span className="text-auraPrimary">*</span>
                  </label>
                  <textarea
                    name=""
                    id=""
                    rows={4}
                    value={complaint}
                    onChange={(e: any) => setComplaint(e.target.value)}
                    className="mt-1 form-control"
                    placeholder=""
                    required
                  ></textarea>
                </div>

                <div className="mt-10 ">
                  <button
                    type="submit"
                    disabled={!check}
                    className={`block w-full px-4 py-3 text-base text-white rounded-md  ${
                      check
                        ? 'bg-auraPrimary hover:bg-auraPrimary'
                        : 'bg-gray-300'
                    }`}
                  >
                    {isLoading ? <Dots /> : 'Submit'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ComplaintModal;
