import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IoLogoGoogle } from 'react-icons/io';
import { CgFacebook } from 'react-icons/cg';
import { TiSocialLinkedin } from 'react-icons/ti';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { ToastContextType, useToast } from 'components/common/Toast/ToastProvider';
import { Modal, Button, Input } from 'components/common';
import { loginUser, socialMediaAuth } from 'redux/authSlice/actions';
import { facebookProvider, googleProvider } from 'utils/config';
import firebase from 'firebase/compat';
import Dot from 'components/common/Loader/Dot';
import { RootState } from 'redux/rootReducer';
import formatDate from 'utils/date/dateFormat';
import { evaluateStatus } from './Bookings';
import moment from 'moment'
import bookingService from 'services/bookingService';

interface BookingDetailModalProps {
    openModal?: boolean;
    setOpenModal?: Dispatch<SetStateAction<boolean>>;
    activeTab?:any
}

const BookingDetailModal: FC<BookingDetailModalProps> = ({ openModal = true, setOpenModal, activeTab }) => {
    // const [openModal, setOpenModal] = useState(true);
    const { isLoading, user } = useSelector((state: RootState) => state.user);
    const { selectedBooking } = useSelector((state: RootState) => state.reservation);
    const { bookingDetailsById } = useSelector((state: RootState)=> state.booking)
    const toast = useToast();

    const [installment, setInstallment ] = useState<any>()

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const getPaysmallsmall = async() =>{
        try{
            const res = await bookingService.fetchPaysmallsmallBreakdown(selectedBooking?.bookingID)
            setInstallment(res.data)
            // console.log('mmeme', res)
        }
        catch(err){
            console.log('Error occurred ', err)
        }
    }

    const formatInstallment = (name : string) =>{
        if(name == '1') return '1st'
        else if ( name == '2') return '2nd'
        else if (name == '3') return '3rd'
        else  return  name + 'th'
        // else return '1st'
    }

    const convertDate = (params : Date) =>{
        const x = new Date(params);
        return x.toLocaleString()
    }

    useEffect(()=>{
        // if(selectedBooking?.PaymentMethodMode == 'PAYSMALLSMALL'){
            getPaysmallsmall()
        // }
    }, [selectedBooking?.bookingID])

    return (
        <Modal
            openModal={openModal as boolean}
            setOpenModal={setOpenModal as Dispatch<SetStateAction<boolean>>}
            className="pt-2 pb-12 px-4 sm:px-10 w-full max-w-[542px] bg-white shadow border border-auraPrimary rounded-xl"
            onOutsideClick={() => {}}
            onModalClose={setOpenModal ? () => setOpenModal(false) : () => navigate('/user-profile/bookings')}
        >
            <div className="flex items-center h-full text-left">
                <div className="relative max-w-lg pl-5 pr-5 lg:pr-16 py-3 mx-auto rounded shrink-0 md:max-w-xl lg: login-width">
                    <div className="">
                        {/* <div className="">
              <button type="button" data-modal-toggle="detailNotPaidModal">
                <img
                  src="../../img/icon/close.svg"
                  className="absolute top-0 right-0 w-9"
                  alt=""
                  srcSet=""
                />
              </button>
            </div> */}
                        <div className="px-6 pb-6 md:pt-6 card-body">
                            <div className="pb-3">
                                <p className="mb-1 text-lg text-auraPrimary font-semibold text-neutral06">Booking Details</p>
                                <div className="flex flex-row items-center justify-between my-3">
                                    <div>
                                        <p>Booking ID</p>
                                    </div>
                                    <div>
                                        <p className="font-medium text-neutral06">
                                            {selectedBooking?.bookingID || ''}
                                        </p>
                                    </div>
                                </div>
                                <div className="flex flex-row items-center justify-between my-3">
                                    <div>
                                        <p>Transaction Date</p>
                                    </div>
                                    <div>
                                        <p className="font-medium text-neutral06">
                                            {selectedBooking?.dateCreated
                                                ? convertDate(selectedBooking?.dateCreated)
                                                : 'N/A'}
                                        </p>
                                    </div>
                                </div>
                                <div className="flex flex-row items-center justify-between my-3">
                                    <div>
                                        <p>{activeTab.toString() === "33" ? "Duration-From" : "Check-In"}</p>
                                    </div>
                                    <div>
                                        <p className="font-medium text-neutral06">
                                            {selectedBooking?.checkInDate
                                                ? formatDate(new Date(selectedBooking?.checkInDate))
                                                : 'N/A'}
                                        </p>
                                    </div>
                                </div>
                                <div className="flex flex-row items-center justify-between my-3">
                                    <div>
                                        <p>{activeTab.toString() === "33" ? "Duration-To" : "Check-Out"}</p>
                                    </div>
                                    <div>
                                        <p className="font-medium text-neutral06">
                                            {selectedBooking?.checkOutDate
                                                ? formatDate(new Date(selectedBooking?.checkOutDate))
                                                : 'N/A'}
                                        </p>
                                    </div>
                                </div>
                                <div className="flex flex-row items-center justify-between my-3">
                                    <div>
                                        <p>Guest</p>
                                    </div>
                                    <div>
                                        <p className="font-medium text-neutral06">
                                            {selectedBooking?.firstName ? selectedBooking?.firstName : user?.firstName}{' '}
                                            {selectedBooking?.lastName ? selectedBooking?.lastName : user?.lastName}
                                        </p>
                                    </div>
                                </div>
                                {/* <div className="flex flex-row items-center justify-between my-3">
                                    <div>
                                        <p>Room</p>
                                    </div>
                                    <div>
                                        <p className="font-medium text-neutral06">Room 24</p>
                                    </div>
                                    </div> */}
                                <div className="flex flex-row items-center justify-between my-3">
                                    <div>
                                        <p>Payment Status</p>
                                    </div>
                                    <div>
                                        <p
                                            className={`font-medium ${
                                                selectedBooking?.paymentStatus === null ||
                                                selectedBooking?.paymentStatus?.toLowerCase() === 'not paid'
                                                    ? 'text-red-500'
                                                    : 'text-green-500'
                                            }`}
                                        >
                                            {
                                                selectedBooking?.paymentMethodMode == 'PAYSMALLSMALL'
                                                ? 'Pay small small'
                                                : selectedBooking?.paymentStatus ? selectedBooking?.paymentStatus : 'Not Paid'}
                                        </p>
                                    </div>
                                </div>

                                <div className="flex flex-row items-center justify-between my-3">
                                    <div>
                                        <p>Reservation Status</p>
                                    </div>
                                    <div>
                                        {/* <p className="px-4 py-1 text-auraPrimary rounded-lg bg-auraPrimary100">
                      Pending
                    </p> */}
                                        <span
                                            className={`px-4 py-2 ${
                                                (selectedBooking.bookingStatus || '')?.toLowerCase() === 'pending' ||
                                                (selectedBooking.bookingStatus || '')?.toLowerCase() === 'rejected' ||
                                                (selectedBooking.bookingStatus || '')?.toLowerCase() === 'cancelled'
                                                    ? 'text-auraPrimary bg-auraPrimary100 '
                                                    : 'text-green-400 bg-green-50'
                                            }  rounded-lg`}
                                        >
                                            {evaluateStatus(selectedBooking)}
                                        </span>
                                    </div>
                                </div>

                                {((selectedBooking.bookingStatus || '')?.toLowerCase() === 'rejected' ||
                                    (selectedBooking.bookingStatus || '')?.toLowerCase() === 'cancelled') && (
                                    <div className="flex flex-row items-center justify-between my-3">
                                        <p>Reason</p>
                                        <p>{selectedBooking?.reason ?selectedBooking?.reason:"---"}</p>
                                    </div>
                                )}
                                
                            </div>
                            <div className="">
                                <p className="mb-1 text-lg text-auraPrimary font-semibold text-neutral06">Payment Breakdown</p>

                                <div className="flex flex-row items-center justify-between my-3">
                                    <div>
                                        <p>Booking Amount:</p>
                                    </div>
                                    <div>
                                        <p className="font-medium text-neutral06">
                                            ₦
                                            {selectedBooking?.bookingAmount
                                                ? ((selectedBooking?.bookingAmount || 0)).toLocaleString(undefined, {
                                                      minimumFractionDigits: 2,
                                                      maximumFractionDigits: 2,
                                                  })
                                                : 0}
                                        </p>
                                    </div>
                                </div>
                                <div className="flex flex-row items-center justify-between my-3">
                                    <div>
                                        <p>Discount:</p>
                                    </div>
                                    <div>
                                        <p className="font-medium text-neutral06">
                                            ₦ {bookingDetailsById?.discountAmount ? bookingDetailsById?.discountAmount : 0}
                                        </p>
                                    </div>
                                </div>
                                <div className="flex flex-row items-center justify-between my-3">
                                    <div>
                                        <p>Service Charge :</p>
                                    </div>
                                    <div>
                                        <p className="font-medium text-neutral06">₦ {selectedBooking?.auraGuestComm || 0}</p>
                                    </div>
                                </div>
                                <div className="flex flex-row items-center justify-between my-3">
                                    <div>
                                        <p>VAT :</p>
                                    </div>
                                    <div>
                                        <p className="font-medium text-neutral06">
                                            ₦{' '}
                                            {selectedBooking?.vat !== 0
                                                ? selectedBooking?.vat?.toLocaleString(undefined, {
                                                      minimumFractionDigits: 2,
                                                      maximumFractionDigits: 2,
                                                  })
                                                : selectedBooking?.vatFee !== 0
                                                ? selectedBooking?.vatFee?.toLocaleString(undefined, {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                })
                                                : 0}
                                        </p>
                                    </div>
                                </div>
                                {installment?.customerBookingInformation?.paySmallSmallPercentage > 0 &&
                                    <div className="flex flex-row items-center justify-between my-3">
                                    <div>
                                        <p>Percentage Interest ({installment?.customerBookingInformation?.paySmallSmallPercentage}%) :</p>
                                    </div>
                                    <div>
                                        <p className="font-medium text-neutral06">
                                            ₦{installment?.customerBookingInformation?.totalInterest.toFixed(2)}
                                        </p>
                                    </div>
                                </div>}
                                <div className="flex flex-row items-center justify-between pt-2 my-3 border-t total">
                                    <div>
                                        <p className="font-medium">Total</p>
                                    </div>
                                    <div>
                                        <p className="font-medium text-auraPrimary">
                                            ₦{' '}
                                            {selectedBooking?.totalPayable
                                                ? selectedBooking?.totalPayable.toLocaleString(undefined, {
                                                      minimumFractionDigits: 2,
                                                      maximumFractionDigits: 2,
                                                  })
                                                : 0}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            {
                                selectedBooking?.paymentMethodMode == 'PAYSMALLSMALL'
                                ? (
                                    <div className="">
                                        <p className="mb-1 text-lg text-auraPrimary font-semibold text-neutral06">Payment Installments</p>
                                        
                                        {
                                            installment?.bookingPaymentSmallSmallModels?.map((obj: any)=>
                                                <>
                                                    <div className="flex flex-row items-center justify-between my-3">
                                                        <div>
                                                            <p>{formatInstallment(obj?.installment)} Installment:</p>
                                                        </div>
                                                        
                                                        <div>
                                                            <p className="font-medium text-neutral06">
                                                                ₦
                                                                {obj?.amountDue
                                                                    ? obj?.amountDue.toLocaleString(undefined, {
                                                                        minimumFractionDigits: 2,
                                                                        maximumFractionDigits: 2,
                                                                    })
                                                                    : 0 }
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <p
                                                                className={`font-medium ${
                                                                    obj?.paymentStatus?.toLowerCase() === 'pending'
                                                                        ? 'text-red-500'
                                                                        : 'text-green-500'
                                                                }`}
                                                            >
                                                                {obj?.paymentStatus}
                                                            </p>
                                                            {/* <br /> */}
                                                            { obj?.paymentStatus?.toLowerCase() !== 'paid' 
                                                                && <span style={{ fontSize: 9, fontWeight:'bold', }} >{moment(obj?.expectedPaymentDate).format('DD/MM/YYYY')}</span> }
                                                        </div>
                                                        {
                                                            obj?.paymentStatus?.toLowerCase() !== 'paid'
                                                            ? (
                                                                <Link
                                                                    to={{
                                                                        pathname: `/confirm-pay/${obj?.bookingId}`,
                                                                    }}
                                                                    className="hover:text-auraPrimary whitespace-nowrap text-auraPrimary"
                                                                    state={{
                                                                        paymentInfo: obj
                                                                    }}
                                                                > Pay now
                                                                </Link>
                                                            )
                                                            : <div style={{ fontSize: 12 }} className="font-medium text-neutral06">{moment(obj?.datetime).format('DD/MM/YYYY')}</div>
                                                        }
                                                        
                                                    </div>
                                                </>
                                            )
                                        }
                                    </div>
                                ) : ''
                            }

                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default BookingDetailModal;
