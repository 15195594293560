//Format: checkinDate='dd/mm/yyyy' or 'dd-mm-yyyy'

export const parseDate = (checkinDate: string, checkoutDate: string) => {
  let c_in, c_out;

  if ((checkinDate||"").includes('/')) {
    c_in = new Date(
      parseInt((checkinDate||"").split('/')[2]),
      parseInt((checkinDate||"").split('/')[1]) - 1,
      parseInt((checkinDate||"").split('/')[0])
    ).toString();
  } else {
    c_in = new Date(
      parseInt((checkinDate||"").split('-')[2]),
      parseInt((checkinDate||"").split('-')[1]) - 1,
      parseInt((checkinDate||"").split('-')[0])
    ).toString();
  }
  if ((checkoutDate||"").includes('/')) {
    c_out = new Date(
      parseInt((checkoutDate||"").split('/')[2]),
      parseInt((checkoutDate||"").split('/')[1]) - 1,
      parseInt((checkoutDate||"").split('/')[0])
    ).toString();
  } else {
    c_out = new Date(
      parseInt((checkoutDate||"").split('-')[2]),
      parseInt((checkoutDate||"").split('-')[1]) - 1,
      parseInt((checkoutDate||"").split('-')[0])
    ).toString();
  }

  return {
    c_in,
    c_out,
  };
};

export function dateDifference(checkinDate: string, checkoutDate: string) {
  const timeDiff = Math.abs(
    new Date(parseDate(checkinDate, checkoutDate).c_in).getTime() -
      new Date(parseDate(checkinDate, checkoutDate).c_out).getTime()
  );
  const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
  //if check in and checkout is the same date, return 1 (i.e 1 night, in order not to turn number of nights to 0)
  return diffDays === 0 ? 1 : diffDays;
}

export function isCheckoutGreaterOrEqualToCheckin(
  checkinDate: string,
  checkoutDate: string
): boolean {

  // console.log(  new Date(parseDate(checkinDate, checkoutDate).c_out).getTime() >=
  // new Date(parseDate(checkinDate, checkoutDate).c_in).getTime());
  
  const timeDiff =
    new Date(parseDate(checkinDate, checkoutDate).c_out).getTime() -  new Date(parseDate(checkinDate, checkoutDate).c_in).getTime();
  return timeDiff >= 0;
}
