import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import successIcon from 'assets/icon/reserve-modal.svg';
import errorIcon from 'assets/icon/error.svg';
import Modal from '../Modal';

interface ValidationProps {
  validationType: 'error' | 'success';
  message: string;
  cta?: any;
}

const ValidationModal: FC<ValidationProps> = ({ validationType, message }) => {
  const [openModal, setOpenModal] = useState(true);
  const navigate = useNavigate();

  return (
    <Modal
      openModal={openModal}
      setOpenModal={setOpenModal}
      className="pt-16 pb-12 bg-white px-4 sm:px-10 w-full max-w-[542px] fit"
      onOutsideClick={() => navigate('/apartments')}
      onModalClose={() => navigate('/apartments')}
    >
      <div className="p-6 md:py-12 flex flex-col items-center justify-center space-y-6">
        {validationType === 'error' ? (
          <img src={errorIcon} className="w-40 " alt="" />
        ) : (
          <img src={successIcon} className="w-40 " alt="" />
        )}
        {/* <img src={successIcon} className="w-40 " alt="" /> */}
        <p className="text-base text-neutral font-normal text-center">
          {message}
        </p>
        {/* <div className="pb-8 w-full flex">
          <Link
            to="/login"
            className=" py-3 rounded-xl text-base text-center font-normal mt-8 mx-auto px-6 bg-auraPrimary text-white capitalize"
          >
            Log In
          </Link>
        </div> */}
      </div>
    </Modal>
  );
};

export default ValidationModal;
