import { IAddTocart } from 'pages/Photography/interface';
import service from 'services/fetchInterceptor';
import { DeleteMultipleCartDto, ICartCheckoutDto, ICreateOrder, IOrderCompleteDto, IOrderDetails, IOrderHistory, IUpdateCart } from './interface';



class auxService {
  AddToCart(
    data: IAddTocart
  ): Promise<any> {
    return service({
      url: `v1/Cart/AddToCart`,
      method: 'post',
      data
    });
  }

  GetUserCart(
    id: number,
    pageNumber: number,
    pageSize: number,
    searchVal?: string
  ): Promise<any> {
    return service({
      url: `v1/Cart/getByCustomerId/${id}?PageNumber=${pageNumber}&PageSize=${pageSize}&SearchVal=${searchVal || ""}'`,
      method: 'get',
    });
  }

  DeleteCart(
    id: number,
  ): Promise<any> {
    return service({
      url: `v1/Cart/delete/${id}`,
      method: 'delete',
    });
  }

  UpdateCart(
    data: IUpdateCart
  ): Promise<any> {
    return service({
      url: `v1/Cart/update`,
      method: 'put',
      data
    });
  }

  CheckoutCart(
    id: number,
    deliveryId: number,
    pageNumber: number,
    pageSize: number,
    searchVal?: string
  ): Promise<any> {
    return service({
      url: `v1/Cart/getByCustomerId/${id}/${deliveryId}?PageNumber=${pageNumber}&PageSize=${pageSize}&SearchVal=${searchVal || ""}'`,
      method: 'get',
    });
  }

  CreateOrder(
    data: ICreateOrder
  ): Promise<any> {
    return service({
      url: `v1/Order/create-order`,
      method: 'post',
      data
    });
  }

  CartCheckout(
    data: ICartCheckoutDto
  ): Promise<any> {
    return service({
      url: `v1/cart/checkout`,
      method: 'post',
      data
    });
  }

  DeliveryDetails(
    id: string | number
  ): Promise<any> {
    return service({
      url: `v1/DeliveryDetails/delivery/detailByVendorID/${id}`,
      method: 'get',
    });
  }

  CreateOrderComplete(
    data: IOrderCompleteDto
  ): Promise<any> {
    return service({
      url: `v1/Order/create-order-complete`,
      method: 'post',
      data
    });
  }

  GetOrderById(
    id: string | number
  ): Promise<{data: IOrderDetails}> {
    return service({
      url: `v1/Order/get-orders-by-id/${id}`,
      method: 'get',
    });
  }

  GetUserOrders(
    id: number,
    pageNumber: number,
    pageSize: number,
    searchVal?: string
  ): Promise<{data: IOrderDetails[]}> {
    return service({
      url: `v1/Order/get-orders-by-customer-id/${id}?PageNumber=${pageNumber}&PageSize=${pageSize}&SearchVal=${searchVal || ""}'`,
      method: 'get',
    });
  }

  GetOrderHistory(
    id: number,
    pageNumber: number,
    pageSize: number,
    searchVal?: string
  ): Promise<{data: IOrderHistory[]}> {
    return service({
      url: `v1/Order/get-orders-history-by-order-id/${id}?PageNumber=${pageNumber}&PageSize=${pageSize}&SearchVal=${searchVal || ""}`,
      method: 'get',
    });
  }

  DeleteMultipleCart(
    data: DeleteMultipleCartDto,
  ): Promise<any> {
    return service({
      url: `v1/Cart/delete/carts`,
      method: 'post',
      data
    });
  }
}

export const AuxService = new auxService();