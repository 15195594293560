import React from 'react';

function Card({ item, setModalData, setModal }: any) {
    return (
        <div>
            <div className="card overflow-hidden relative pb-2 mt-5 rounded px-2 bg-white h-96">
                <div className="flex mx-1 my-2 space-x-2 items-center">
                    <h2>{item.title}</h2>
                    <div className="border-4 mx-1 my-1 rounded-full">
                        <div className="p-1">
                            <svg width="12" height="12" viewBox="0 0 384 512" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M202.021 0C122.202 0 70.5029 32.703 29.9139 91.026C22.5509 101.606 24.8209 116.112 35.0919 123.9L78.2299 156.609C88.6029 164.474 103.362 162.635 111.483 152.461C136.532 121.08 155.113 103.012 194.24 103.012C225.004 103.012 263.056 122.811 263.056 152.643C263.056 175.195 244.439 186.777 214.063 203.807C178.64 223.667 131.764 248.383 131.764 310.212V320C131.764 333.255 142.509 344 155.764 344H228.235C241.49 344 252.235 333.255 252.235 320V314.227C252.235 271.367 377.503 269.582 377.503 153.6C377.504 66.256 286.902 0 202.021 0ZM192 373.459C153.804 373.459 122.729 404.534 122.729 442.73C122.729 480.925 153.804 512 192 512C230.196 512 261.271 480.925 261.271 442.729C261.271 404.533 230.196 373.459 192 373.459Z"
                                    fill="black"
                                />
                            </svg>
                        </div>
                    </div>
                </div>

                <div className="absolute text-center flex items-center justify-center  h-60  hover:bg-[#959595ad] top-13 w-96  ">
                    <button
                        className="border center px-3 py-2 text-white mr-20 hover:text-white hover:border-white"
                        onClick={() => {
                            setModal(true);
                            setModalData({ ...item });
                        }}
                    >
                        View More
                    </button>
                </div>

                <div className="h-60 ">
                    <img src={item.image} className="w-full h-full object-cover" alt="" />
                </div>
                <div className="py-3 px-3 line-clamp-2 text-gray-700">
                    {item.subtitle && <b>{item.subtitle}</b>}
                    <p className="truncate">{item.paragraph}</p>
                    {item.headline1 && <b className="truncate">{item.headline1}</b>}
                    {item.string1 && <p className="truncate">{item.string1}</p>}
                    {item.headline2 && <b className="truncate">{item.headline2}</b>}
                    {item.string2 && <p className="truncate">{item.string2}</p>}
                    {item.headline3 && <b className="truncate">{item.headline3}</b>}
                    {item.paragraph3 && <p className="truncate">{item.paragraph3}</p>}
                    {item.headline4 && <b className="truncate">{item.headline4}</b>}
                    {item.paragraph4 && <p className="truncate">{item.paragraph4}</p>}
                </div>
            </div>
        </div>
    );
}

export default Card;
