import service from 'services/fetchInterceptor';
// import { IActivateUser, IDeactivateUser } from './interface';

class NotificationService {
  getAllNotificationByUserId(): Promise<any> {
    return service({
      url: `v1/Notification/GetAllNotificationByUserId`,
      method: 'get',
      headers: {
        'public-request': 'true',
      },
    });
  }
  getAllNotificationByHostVendorId(id: number): Promise<any> {
    return service({
      url: `v1/Notification/get-all-notification-by-host-vendor-Id/${id}`,
      method: 'get',
      headers: {
        'public-request': 'true',
      },
    });
  }

  getUserNotificationSetting(id: number): Promise<any> {
    return service({
      url: `v1/UserNotificationSettings/GetAllUserNotificationSettingByUserId?UserId=${id}`,
      method: 'get',
      headers: {
        'public-request': 'true',
      },
    });
  }

  postUserBrowserNotificationSetting(id: number, value): Promise<any> {
    return service({
      url: `v1/UserNotificationSettings/UpdateUserNotificationSettingBrowser?UserId=${id}&IsBrowserEnabled=${value}`,
      method: 'post',
      headers: {
        'public-request': 'true',
      },
    });
  }

  postUserEmailNotificationSetting(id: number, value): Promise<any> {
    return service({
      url: `v1/UserNotificationSettings/UpdateUserNotificationSettingEmail?UserId=${id}&IsEmailEnabled=${value}`,
      method: 'post',
      headers: {
        'public-request': 'true',
      },
    });
  }

  postUserTextNotificationSetting(id: number, value): Promise<any> {
    return service({
      url: `v1/UserNotificationSettings/UpdateUserNotificationSettingText?UserId=${id}&IsSmsEnabled=${value}`,
      method: 'post',
      headers: {
        'public-request': 'true',
      },
    });
  }

}

export const NotificationServices = new NotificationService();
