import { Dispatch as AppDispatch, SetStateAction } from 'react';
import { Dispatch, AnyAction } from '@reduxjs/toolkit';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { BioData, LoginData, SignupData, SocialSignupData } from 'services/authService/types';
import authService from 'services/authService';
// import { useToast } from 'components/common/Toast/ToastProvider';
// import { useGlobalState } from 'context/Providers';
import type { ToastContextType } from 'components/common/Toast/ToastProvider';
import { storage } from 'utils';
import {
  loadingStart,
  loadingStopped,
  loginSuccess,
  signupSuccess,
  signupFailed,
  profileUpdateSuccessful,
  profileUpdateFailed,
  logoutStart,
  logoutSuccess,
  logoutFailed,
  clearError,
  deviceTokenSuccess,
  setReloadCount,
} from './reducers';
import firebase from 'services/firebase-config';
import dataService from 'services/appData';
import { string } from 'yup';
import { useLinkedIn } from 'react-linkedin-login-oauth2';
// import { loadingStopped as ProductLoadingStop } from 'redux/productSlice/reducers';
// import dataService from 'services/appData';

export const loginUser =
  (
    { email, password }: LoginData,
    navigate: NavigateFunction,
    toast: ToastContextType,
    setOpenModal: {
      (value: SetStateAction<boolean>): void;
      (arg0: boolean): void;
    },
    setResend: {
      (value: SetStateAction<boolean>): void;
      (arg0: boolean): void;
    },
  ) =>
  async (dispatch: Dispatch<AnyAction>): Promise<void> => {
    try {
      dispatch(loadingStart());
      const res = await authService.login({ email, password });
      storage.setToken(res.data.accessToken);
      const user = await authService.fetchUserDetails();
      storage.setUserId(user?.data.userID);
      dispatch(loginSuccess(user.data));
      const url = new URL(window.location.href);
      const nextRoute = url && url.searchParams.get('redirect');
      navigate(nextRoute, {
        replace: true,
      });
      setOpenModal(false);
      // console.log(res.data);
      // const reservations = await dataService.getReservations();
      // console.log(reservations);
    } catch (err: any) {
      // dispatch(ProductLoadingStop(err.response.data.responseMessage));
      toast.error(err.response?.data.responseMessage, 5000);
      dispatch(loadingStopped(err.response?.data.responseMessage));

      if(err.response?.data.responseMessage == 'You are yet to confirm your email') return setResend(true)
    }
  };

export const signupUser =
  ({
    firstName,
    middleName,
    lastName,
    emailAddress,
    phoneNumber,
    genderId,
    countryId,
    stateId,
    age,
    password,
    referralCode
  }: SignupData) =>
  async (dispatch: Dispatch<AnyAction>): Promise<void> => {
    try {
      dispatch(loadingStart());
      const res = await authService.signup({
        firstName,
        middleName,
        lastName,
        emailAddress,
        phoneNumber,
        genderId,
        countryId,
        stateId,
        age,
        password,
        referralCode
      });
      dispatch(signupSuccess(res.responseMessage));
    } catch (err: any) {
      dispatch(signupFailed(err));
      dispatch(loadingStopped(err.response.data.responseMessage));
    }
  };

  export const GoogleSignup =
  ({
    id,
    firstName,
    middleName,
    lastName,
    emailAddress,
    // phoneNumber,
    accessToken,
    setOpenModal,
    navigate
  }: any) =>
  async (dispatch: Dispatch<AnyAction>): Promise<void> => {
    try {
      dispatch(loadingStart());
      const res = await authService.signupGoogle({
        id,
        firstName,
        middleName,
        lastName,
        emailAddress,
        // phoneNumber,
      });

      dispatch(signupSuccess(res.responseMessage));
      storage.setToken(accessToken);
      storage.setUserId(res?.data.userID);
      dispatch(loginSuccess(res.data));
      const url = new URL(window.location.href);
      const nextRoute = url.searchParams.get('redirect') || '/';
      navigate(nextRoute, {
        replace: true,
      });
      setOpenModal(false);
    } catch (err: any) {
      dispatch(signupFailed(err));
      dispatch(loadingStopped(err.response.data.responseMessage));
    }
  };

  export const FacebookSignup =
  ({
    id,
    firstName,
    middleName,
    lastName,
    emailAddress,
    // phoneNumber,
    navigate,
    setOpenModal,
    accessToken
  }: any) =>
  async (dispatch: Dispatch<AnyAction>): Promise<void> => {
    try {
      dispatch(loadingStart());
      const res = await authService.signupFacebook({
        id,
        firstName,
        middleName,
        lastName,
        emailAddress,
        // phoneNumber,
      });
      dispatch(signupSuccess(res.responseMessage));
      storage.setToken(accessToken);
      storage.setUserId(res?.data.userID);
      dispatch(loginSuccess(res.data));
      const url = new URL(window.location.href);
      const nextRoute = url.searchParams.get('redirect') || '/';
      navigate(nextRoute, {
        replace: true,
      });
      setOpenModal(false);
    } catch (err: any) {
      dispatch(signupFailed(err));
      dispatch(loadingStopped(err.response.data.responseMessage));
    }
  };

  export const LinkedInSignup =
  ({
    id,
    firstName,
    middleName,
    lastName,
    emailAddress,
    // phoneNumber,
  }: SocialSignupData) =>
  async (dispatch: Dispatch<AnyAction>): Promise<void> => {
    try {
      dispatch(loadingStart());
      const res = await authService.signupLinkedIn({
        id,
        firstName,
        middleName,
        lastName,
        emailAddress,
        // phoneNumber,
      });
      dispatch(signupSuccess(res.responseMessage));
    } catch (err: any) {
      dispatch(signupFailed(err));
      dispatch(loadingStopped(err.response.data.responseMessage));
    }
  };

  // export function LinkedInPage() {
  //   const { linkedInLogin } = useLinkedIn({
  //     clientId: '86vhj2q7ukf83q',
  //     redirectUri: `${window.location.origin}/linkedin`, // for Next.js, you can use `${typeof window === 'object' && window.location.origin}/linkedin`
  //     onSuccess: (code) => {
  //       console.log("linkedin code",code);
  //     },
  //     onError: (error) => {
  //       console.log(error);
  //     },
  //   });
  //   linkedInLogin()
  // }

export const updateUserDetails =
  (data: BioData, toast: ToastContextType | undefined) =>
  async (dispatch: Dispatch<AnyAction>): Promise<void> => {
    try {
      dispatch(loadingStart());
      const user = await authService.updateBiodata(data);
      dispatch(profileUpdateSuccessful());
      dispatch(loginSuccess(user.data));
      toast?.success(user.responseMessage, 5000);
    } catch (err: any) {
      dispatch(loadingStopped(err.response.data.responseMessage));
      dispatch(profileUpdateFailed());
      toast?.error(err.response.data.responseMessage, 5000);
    }
  };

export const uploadPassportPhoto =
  (data: any, toast: ToastContextType | undefined) =>
  async (dispatch: Dispatch<AnyAction>): Promise<void> => {
    try {
      dispatch(loadingStart());
      const user = await authService.uploadPassport(data);
      dispatch(profileUpdateSuccessful());
      dispatch(loginSuccess(user.data));
      toast?.success(user.responseMessage, 5000);
    } catch (err: any) {
      dispatch(loadingStopped(err.response.data.responseMessage));
      dispatch(profileUpdateFailed());
      toast?.error(err.response.data.responseMessage, 5000);
    }
  };

export const uploadCoverPhoto =
  (data: any, toast: ToastContextType | undefined) =>
  async (dispatch: Dispatch<AnyAction>): Promise<void> => {
    try {
      dispatch(loadingStart());
      const user = await authService.uploadCoverPhoto(data);
      dispatch(profileUpdateSuccessful());
      dispatch(loginSuccess(user.data));
      toast?.success(user.responseMessage, 5000);
    } catch (err: any) {
      dispatch(loadingStopped(err.response.data.responseMessage));
      dispatch(profileUpdateFailed());
      toast?.error(err.response.data.responseMessage, 5000);
    }
  };

type SocialAuth = {
  email: string;
};

export const socialMediaAuth = (provider: firebase.auth.AuthProvider) =>
  firebase
    .auth()
    .signInWithPopup(provider)
    .then((res) => res.user)
    .catch((err) => err);

export const socialAuthentication =
  (
    {
      displayName,
      email,
      passportPath,
      accessToken,
      refreshToken,
      phoneNumber,
      uid,
    }: any,
    setOpenModal: any,
    navigate: NavigateFunction,
    toast: ToastContextType | undefined
  ) =>
  async (dispatch: Dispatch<AnyAction>): Promise<void> => {
    try {
      dispatch(loadingStart());
      const res = await authService.AuthenticateUserWithSocialMedia(
        {
          id: uid,
          email,
          isMobile: true
        }
      );
      storage.setToken(res.data.accessToken);
      // console.log("userdata", res.data)
      const user = await authService.fetchUserDetails();
      storage.setUserId(user?.data.userID);
      dispatch(
        loginSuccess(user.data)
      );
      const url = new URL(window.location.href);
      const nextRoute = url.searchParams.get('redirect') || '/';
      navigate(nextRoute, {
        replace: true,
      });
      setOpenModal(false);
    } catch (err: any) {
      toast?.error(err.response.data.responseMessage, 5000);
    }
  };

export const logout =
  (navigate: NavigateFunction) =>
  async (dispatch: Dispatch<AnyAction>): Promise<void> => {
    try {
      dispatch(logoutStart());
      storage.clearToken();
      storage.clearCache();
      dispatch(logoutSuccess());
      navigate('/');
    } catch (err: any) {
      dispatch(loadingStopped({ error: 'Failed to logout' }));
    }
  };

export const ClearError =
  () =>
  async (dispatch: Dispatch<AnyAction>): Promise<void> => {
    dispatch(clearError());
  };

export const createDeviceToken =
  (data: { email: string; deviceToken: string }) =>
  async (dispatch: Dispatch<AnyAction>): Promise<void> => {
    try {
      dispatch(loadingStart());
      const res = await dataService.createDeviceToken(data);
      dispatch(deviceTokenSuccess(res));
    } catch (err: any) {
      dispatch(loadingStopped(err.response.data.responseMessage));
    }
  };

export const setreloadCount =
  (data: any) => (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(setReloadCount(data));
    } catch (err: any) {
      console.log(err);
    }
  };

export const stopTheLoading = () => (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(loadingStopped({ error: null }));
  } catch (err: any) {
    console.log(err);
  }
};
