import {
  FC,
  Fragment,
  Dispatch,
  ReactNode,
  SetStateAction,
  useRef,
} from 'react';
import { Dialog, Transition } from '@headlessui/react';

interface ModalProps {
  openModal: boolean;
  verifyloading?: boolean;
  paymentSuccess?: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  children: ReactNode | any;
  className?: string;
  onModalClose?: () => void;
  onOutsideClick?: () => void;
}

const Modal: FC<ModalProps> = ({
  openModal,
  setOpenModal,
  children,
  className,
  onOutsideClick,
  onModalClose,
}) => {
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={openModal} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-50 inset-0 overflow-y-auto "
        initialFocus={cancelButtonRef}
        onClose={() => {}}
      >
        <div
          className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block
         sm:p-0"
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              onClick={onOutsideClick}
            />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={`inline-block relative align-bottom bg-white rounded-2xl shadow-xl transform transition-all sm:my-8 sm:align-middle ${className}`}
            >
              <div className="absolute top-[-10px] right-[-10px] z-50 h-8 w-8 items-center justify-center inline-flex bg-white border border-auraPrimary rounded-[50%]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 cursor-pointer text-auraPrimary"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  onClick={
                    onModalClose
                      ? () => onModalClose()
                      : () => setOpenModal(false)
                  }
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </div>
              {children}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;
