import React from 'react';
import { Link } from 'react-router-dom';
import Modal from '.';
import lovedimage from "../../../assets/icon/loved-gif.svg";

interface SavedModal {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}
export default function SavedModal(props: SavedModal) {
  return (
    <>
      <Modal
        children={
          <div className="relative w-full max-w-xl px-3 py-2 bg-white shadow-xl rounded-xl lg:max-w-xl">
            <div className="p-3 text-center md:p-10 xl:px-8">
              <div className="flex justify-center">
                <img
                  src={lovedimage}
                  className="text-center"
                  alt=""
                  srcSet=""
                />
              </div>
              <h2 className="text-3xl font-bold">Saved!</h2>
              <p className="py-3 text-lg text-neutral">
                This has been added to your wishlist
              </p>
              <div className="my-10 ">
                <Link
                  to="/user-profile/wishlist"
                  className="block w-full px-4 py-3 text-base text-white btn bg-auraPrimary hover:bg-auraPrimary hover:text-white my-btn"
                >
                  View Wishlist
                </Link>
              </div>
            </div>
          </div>
        }
        openModal={props.openModal}
        setOpenModal={props.setOpenModal}
      />
    </>
  );
}
