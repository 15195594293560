import service from 'services/fetchInterceptor';
import { IActivateUser, IDeactivateUser } from './interface';

class AdminService {
  login(data: any): Promise<any> {
    return service({
      url: '/v1/Admin/AuthenticateAdminUser',
      method: 'post',
      data,
      headers: {
        'public-request': 'true',
      },
    });
  }
  verificationCode(code: any): Promise<any> {
    return service({
      url: `/v1/Admin/ValidateAdminAuthentication?AccessCode=${code}`,
      method: 'post',
      headers: {
        'public-request': 'true',
      },
    });
  }
  signup(data: any): Promise<any> {
    return service({
      url: '/v1/Account/Signup',
      method: 'post',
      data,
      headers: {
        'public-request': 'true',
      },
    });
  }
  fetchUserDetails(): Promise<any> {
    return service({
      url: '/v1/Admin/GetUser',
      method: 'get',
    });
  }

  getAllActiveProducts(): Promise<any> {
    return service({
      url: 'v1/Admin/GetAllActiveProducts',
      method: 'get',
    });
  }

  getProductSummaryById(id: number): Promise<any> {
    return service({
      url: `v1/Admin/GetHostVendorProductsCount?productId=${id}`,
      method: 'get',
    });
  }

  fetchPendingProductData(id: number): Promise<any> {
    return service({
      url: `v1/Admin/FetchPendingProductDetailsByProductId?ProductId=${id}`,
      method: 'get',
    });
  }

  fetchUnpublishedProductData(id: number): Promise<any> {
    return service({
      url: `v1/Admin/FetchUnPublishedProductDetailsByProductId?ProductId=${id}`,
      method: 'get',
    });
  }

  fetchPublishedProductData(id: number): Promise<any> {
    return service({
      url: `v1/Admin/FetchPublishedProductDetailsByProductId?ProductId=${id}`,
      method: 'get',
    });
  }

  fetchDeclinedProductData(id: number): Promise<any> {
    return service({
      url: `v1/Admin/FetchDeclinedProductDetailsByProductId?ProductId=${id}`,
      method: 'get',
    });
  }

  getSingleProductById(id: number): Promise<any> {
    return service({
      url: `v1/Admin/GetSingleProductDetailsById?detailId=${id}`,
      method: 'get',
    });
  }

  getProductServiceById(id: number): Promise<any> {
    return service({
      url: `v1/Admin/GetServiceByProductDetailsID?detailId=${id}`,
      method: 'get',
    });
  }

  verifyHostVendorFirst(vendorId: number): Promise<any> {
    return service({
      url: `/v1/Admin/VerifyVendorHostFirst?HostVendorId=${vendorId}`,
      method: 'get',
    });
  }

  createAdminUser(data: any): Promise<any> {
    return service({
      url: 'v1/Admin/CreateAdminUser',
      method: 'post',
      data,
      headers: {
        'public-request': 'true',
      },
    });
  }

  getAdminRoles(): Promise<any> {
    return service({
      url: `/v1/AppPermission/GetAdminRoles`,
      method: 'get',
    });
  }

  getAdminPermission(): Promise<any> {
    return service({
      url: `/v1/AppPermission/GetAppPermissions`,
      method: 'get',
    });
  }
  // /v1/Admin/GetAllUsers?PageNumber=${page}&PageSize=${size}
  getAllAdminUsers(page: number, size: number): Promise<any> {
    return service({
      url: `/v1/Admin/GetAllUsers`,
      method: 'get',
    });
  }

  verifyFirstLevel(data: any = {}, id: number): Promise<any> {
    return service({
      url: `v1/Admin/VerifyProductDetailsFirstLevel?ProductDetailsId=${id}`,
      method: 'post',
      data,
      headers: {
        'public-request': 'true',
      },
    });
  }
  verifySecondLevel(data: any = {}, id: number): Promise<any> {
    return service({
      url: `v1/Admin/VerifyProductDetailsSecondLevel?ProductDetailsId=${id} `,
      method: 'post',
      data,
      headers: {
        'public-request': 'true',
      },
    });
  }

  verifyLastLevel(data: any = {}, id: number): Promise<any> {
    return service({
      url: `v1/Admin/VerifyProductDetails?ProductDetailsId=${id} `,
      method: 'post',
      data,
      headers: {
        'public-request': 'true',
      },
    });
  }
  getUserById(data: any, id: number): Promise<any> {
    return service({
      url: `v1/Account/GetByIdUser?UserId=${id} `,
      method: 'post',
      data,
      headers: {
        'public-request': 'true',
      },
    });
  }
  activateHostVendor(data: any, id: number): Promise<any> {
    return service({
      url: `v1/Admin/VerifyVendorHost?HostVendorId=${id} `,
      method: 'post',
      data,
      headers: {
        'public-request': 'true',
      },
    });
  }

  deActivateHostVendor(data: any): Promise<any> {
    return service({
      url: 'v1/Admin/DeactivateVendorHost ',
      method: 'post',
      data,
      headers: {
        'public-request': 'true',
      },
    });
  }

  getAllAdmins(): Promise<any> {
    return service({
      url: 'v1/Account/GetAllAdmin ',
      method: 'get',
    });
  }
  reAssignAdminUserRole(
    data: any = {},
    email: string,
    roleId: number
  ): Promise<any> {
    return service({
      url: `v1/Admin/ReAssignAdminUserRole?EmailAddress=${email}&RoleId=${roleId} `,
      method: 'post',
      data,
      headers: {
        'public-request': 'true',
      },
    });
  }

  getProductHistoryById(id: number): Promise<any> {
    return service({
      url: `/ProductDetails/product/details/all/${id} `,
      method: 'get',
      headers: {
        'public-request': 'true',
      },
    });
  }

  resetPassword(id: number): Promise<any> {
    return service({
      url: `v1/Admin/ResetUserPassword?UserID=${id}`,
      method: 'post',
    });
  }

  getUserDetailsById(id: number): Promise<any> {
    return service({
      url: `v1/Account/GetByIdUser?UserId=${id} `,
      method: 'get',
      headers: {
        'public-request': 'true',
      },
    });
  }

  deactivateUser(payload: IDeactivateUser) {
    return service({
      url: `v1/Account/DeactivateUser?UserId=${payload.UserId}&&DeactivationComment=${payload.DeactivationComment}`,
      method: 'post',
    });
  }

  activateUser(payload: IActivateUser) {
    return service({
      url: `v1/Account/activateUser?UserId=${payload.UserId}&&ActivationComment=${payload.ActivationComment}`,
      method: 'post',
    });
  }

  declineProductDetails(data: any, id: number): Promise<any> {
    return service({
      url: `v1/Account/CommentOnProductDetails?ProductDetailsId=${id} `,
      method: 'post',
      data,

      headers: {
        'public-request': 'true',
      },
    });
  }

  getAllHostVendors(page: number, size: number): Promise<any> {
    // `v1/HostVendor/GetAllVendorHosts?PageNumber=${page}&PageSize=${size}
    return service({
      url: `v1/HostVendor/GetAllVendorHosts`,
      method: 'get',
    });
  }

  getHostBankDetails(id: number): Promise<any> {
    return service({
      url: `v1/HostVendorBankAccount/GetByHostVendorIdHostVendorBankAccount?HostVendorId=${id}`,
      method: 'get',
    });
  }

  getAdminOverview(): Promise<any> {
    return service({
      url: `v1/AppData/GetEntitiesAggregator`,
      method: 'post',
    });
  }

  getAdminComplaint(): Promise<any> {
    return service({
      url: `v1/Complaint/get-all-complaints`,
      method: 'get',
    });
  }

  createComplaint(data:any): Promise<any> {
    return service({
      url: `v1/Complaint/create-complaints`,
      method: 'post',
      data
    });
  }

  unPublishProduct(comment: string, id: number): Promise<any> {
    return service({
      url: `v1/Admin/UnPublishProductDetail?ProductDetailsId=${id}&Comment="${comment}"`,
      method: 'delete',
      headers: {
        'public-request': 'false',
      },
    });
  }

  publishProduct(id: number): Promise<any> {
    return service({
      url: `v1/Admin/PublishProductDetail?ProductDetailsId=${id}`,
      method: 'post',
      headers: {
        'public-request': 'false',
      },
    });
  }

  // queryProduct(id: number, comment: string): Promise<any> {
  //   return service({
  //     url: `v1/Admin/CommentOnProductDetails?ProductDetailsId=${id}&Comment="${comment}"`,
  //     method: 'post',
  //     headers: {
  //       'public-request': 'false',
  //     },
  //   });
  // }

  getProducthistory(id: number): Promise<any> {
    return service({
      url: `v1/Admin/GetProductDetailsApprovalHistory?ProductDetailId=${id}`,
      method: 'get',
      headers: {
        'public-request': 'false',
      },
    });
  }

  changePassword(data: any): Promise<any> {
    return service({
      url: `v1/Account/ChangePassword?OldPassword=${data.oldPassword}&NewPassword=${data.newPassword}`,
      method: 'post',
      data,
      headers: {
        'public-request': 'false',
      },
    });
  }

  getReservationCountById(id: number): Promise<any> {
    return service({
      url: `v1/Admin/TotalReservationCount?productID=${id}`,
      method: 'get',
      headers: {
        'public-request': 'false',
      },
    });
  }

  getPendingReservationId(id: number): Promise<any> {
    return service({
      url: `v1/Admin/GetAllPendingReservationAdmin?productID=${id}`,
      method: 'get',
      headers: {
        'public-request': 'false',
      },
    });
  }

  getOngoingReservationId(id: number): Promise<any> {
    return service({
      url: `v1/Admin/GetAllOngoingReservationAdmin?productID=${id}`,
      method: 'get',
      headers: {
        'public-request': 'false',
      },
    });
  }

  getConcludedReservationId(id: number): Promise<any> {
    return service({
      url: `v1/Admin/GetAllConcludedReservationAdmin?productID=${id}`,
      method: 'get',
      headers: {
        'public-request': 'false',
      },
    });
  }

  getAllReservationId(id: number): Promise<any> {
    return service({
      url: `v1/Admin/GetAllReservationAdmin?productID=${id}`,
      method: 'get',
      headers: {
        'public-request': 'false',
      },
    });
  }

  cancelReservation(id: number): Promise<any> {
    return service({
      url: `v1/Admin/AdminCancelBooking?bookingId=${id}`,
      method: 'put',
      headers: {
        'public-request': 'false',
      },
    });
  }

  getReservationByBookingId(id: number): Promise<any> {
    return service({
      url: `v1/Admin/GetSingleBookingById?bookingId=${id}`,
      method: 'get',
      headers: {
        'public-request': 'false',
      },
    });
  }

  declineProduct(data: any): Promise<any> {
    return service({
      url: 'v1/Admin/DeclineProductDetails',
      method: 'delete',
      data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  queryProduct(data: any): Promise<any> {
    return service({
      url: 'v1/Admin/QueryProductDetails',
      method: 'post',
      data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  getSavedProductById(id: number): Promise<any> {
    return service({
      url: `v1/Admin/FetchSavedProductDetailsByProductId?ProductId=${id} `,
      method: 'get',
      headers: {
        'public-request': 'true',
      },
    });
  }

  createMessage(data: {
    bookingId: number | string;
    messageSubject: string;
    messageBody: string;
  }): Promise<any> {
    return service({
      url: `v1/Message/CreateMessage`,
      method: 'post',
      data,
    });
  }

  getMessagesInbox(userId: number): Promise<any> {
    return service({
      url: `v1/Message/GetMessagesInbox?UserId=${userId}`,
      method: 'get',
    });
  }
  getMessagesOutbox(userId: number): Promise<any> {
    return service({
      url: `v1/Message/GetMessagesOutbox?UserId=${userId}`,
      method: 'get',
    });
  }
  readMessage(msgId: number): Promise<any> {
    return service({
      url: `v1/Message/ReadMessage?MessageId=${msgId}`,
      method: 'put',
    });
  }
  sendWaWebHook(data: any): Promise<any> {
    return service({
      url: `v1/ChatBot/WhatsAppWebHook`,
      method: 'post',
      data
    });
  }
}

export const AdminServices = new AdminService();
