import { Dispatch, FC, SetStateAction, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IoLogoGoogle } from 'react-icons/io';
import { CgFacebook } from 'react-icons/cg';
import { TiSocialLinkedin } from 'react-icons/ti';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import {
  ToastContextType,
  useToast,
} from 'components/common/Toast/ToastProvider';
import { Modal, Button, Input } from 'components/common';
import { loginUser, socialMediaAuth } from 'redux/authSlice/actions';
import { facebookProvider, googleProvider } from 'utils/config';
import firebase from 'firebase/compat';
import { RootState } from 'redux/rootReducer';
import Dot from 'components/common/Loader/Dot';
import formatDate from 'utils/date/dateFormat';
import success from 'assets/img/success.svg';



interface BookingDetailModalProps {
  openModal?: boolean;
  setOpenModal?: Dispatch<SetStateAction<boolean>>;
}

const CancelModalSucess: FC<BookingDetailModalProps> = ({
  openModal = true,
  setOpenModal,
}) => {
  // const [openModal, setOpenModal] = useState(true);
  const { isLoading, user } = useSelector((state: RootState) => state.user);
  const { selectedBooking } = useSelector(
    (state: RootState) => state.reservation
  );
  const toast = useToast();

  const dispatch = useDispatch();
  const navigate = useNavigate();


  return (
    <Modal
    openModal={openModal as boolean}
    setOpenModal={setOpenModal as Dispatch<SetStateAction<boolean>>}
    className="pt-2 pb-12 px-4 sm:px-10 w-full max-w-[542px] bg-white shadow border border-auraPrimary rounded-xl"
    onOutsideClick={() => {}}
    onModalClose={
      setOpenModal ? () => setOpenModal(false) : () => navigate('/user-profile/bookings')
    }
  >
       <div
      className="flex items-center justify-center w-full"
    >
      <div className="w-full px-3 py-2 ">
        <div className="w-full">
          {/* <!-- Modal body --> */}
          <div className="p-3 text-center md:p-8 w-full">
            <div className="flex justify-center">
              <img
                src={success}
                className="text-center"
                alt=""
                srcSet=""
              />
            </div>
            <h2 className="text-3xl font-bold">Cancellation Request Sent!</h2>
            <p className="py-3 text-lg text-neutral">
              Your refund is now being processed, a customer care representative
              will reach out to you soon.
            </p>
            <div className="my-10">
              <Link
                to="/"
                className="block w-full px-4 py-3 text-base text-white btn bg-auraPrimary hover:text-white hover:bg-auraPrimary my-btn"
              >
                Go to Home
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Modal>
 
  );
}

export default  CancelModalSucess;