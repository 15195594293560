import { Footer, Header } from "components/common";
import Card from "components/common/Card";
import HelpCenterPageModal from "components/common/Modal/HelpCenterModal";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";

export default function HelpCenterPage() {
  const [modalData, setModalData] = useState([]);
  const [modal, setModal] = useState(false);
  const [select, setSelect] = useState("all");
  const {isAuthenticated} = useSelector((state:RootState)=>state.user);
  const handleChange = (event:any) => {
    setSelect(event.target.value);
  };

  useEffect(() => {
      window.scrollTo(0,0)
  }, []);

  const data = [
    {
      category: "Health & Safety",
      title: "Cleaning Protocols",
      image: "	https://aurastorage.blob.core.windows.net/auraprod/4857cf39-2db9-487e-bd16-801ac62851d1.jpg",
      subtitle: "Cleaning protocol:",
      paragraph:
        "Our hosts observe accurate host housekeeping protocol which includes removing and cleaning all used items from the room such as bedding and towels, ensuring deep cleaning of all rooms and bathrooms (vacuuming the carpet, mopping floors and wiping down all surfaces with hospital-grade cleaners and bleach) periodically at least 3 times a day for shared areas of low traffic and more for high frequency areas.",
      paragraph1:
        "  Our hosts are advised to carry out additional disinfecting steps for several surfaces in the room. The focused disinfection of high-touch areas in the guest room includes: all switches and electronic controls, handles and knobs, major bathroom surfaces and the remote control. They are also advised to put up Covid-19 notices at their premises as part of their commitment to providing guests with a clean, comfortable space. From social distancing, increased scheduled cleaning of public spaces, and deep cleaning of all guest spaces, Aura and the hosts are committed to protecting your wellbeing while using your host amenities.",
      id: "1",
    },
    {
      category: "Health & Safety",
      title: "COVID 19 Protocols",
      image: "https://aurastorage.blob.core.windows.net/auraprod/4192b074-bfc1-43fd-8108-a519a345895c.jpg",
      subtitle: "Your safety is our priority.",
      paragraph:
        "Our hosts are responsible for delivering clean, safe properties following COVID 19 guidelines to ensure that all employees and guests are protected. Our guests are also expected to adhere to all Host safety standards because safety is a shared responsibility.",
      headline1: "Mask up",
      paragraph1:
        "If it is shared accommodation, ensure you mask up. Both guests and hosts must wear masks during physical interactions.",
      headline2: "Observe Social distancing",
      paragraph2:
        "We expect guests and hosts to maintain a social distance of six feet (two meters) or more—as recommended by global health organizations.",
      headline3: "Deep cleaning",
      paragraph3:
        "Our hosts are responsible for delivering our committed to our deep cleaning guidelines to ensure accommodations are clean and safe enough for guests. Also notices would be put up to remind guests to observe sanitary hygiene practices",
      headline4: "Entertain responsibly.",
      paragraph4:
        "Our hosts encourage guests to entertain responsibly. If hosting Aura events, they ensure all entertaining are limited to the numbers as recommended by Global health organizations or the local authority. Please note that the lower numbers supersede.",
      id: "2",
    },
    {
      category: "General",
      title: "Contact Support",
      image: "https://aurastorage.blob.core.windows.net:443/auraprod/d69c663b-7b6e-4f1d-b787-2e1958bdc026.jpg",
      paragraph: "Visit the help center by clicking on the “Help” button on the menu of the home screen.",
      id: "3",
    },
    {
      category: "Reviews and Referrals",
      title: "Contest a Bad Review.",
      image: "	https://aurastorage.blob.core.windows.net/auraprod/69b56f4e-52bf-4fdf-bcd7-f70a7557b2fd.png",
      subtitle: "Reviews from guests appear under your profile.",
      paragraph:
        "If you feel a review is untrue: You can respond to the review or report this review via the contact us. If you choose to leave a response, be sure to follow the Aura review policy outlined here",
      id: "4",
    },
    {
      category: "Verification",
      title: "Background Checks",
      image: "https://aurastorage.blob.core.windows.net/auraprod/9fe033a5-72a0-4eb3-b0a7-873a33a3c7f8.png",
      subtitle: "",
      paragraph: "No, Aura does not conduct background checks.",
      id: "5",
    },
    {
      category: "Verification",
      title: "User Verification",
      image: "https://aurastorage.blob.core.windows.net/auraprod/9fe033a5-72a0-4eb3-b0a7-873a33a3c7f8.png",
      subtitle: "",
      paragraph:
        "Aura verifies government-issued identity cards from all users on its platform and verifies them using independent third-party verifying companies.",
      id: "6",
    },

    {
      category: "Host Onboarding",
      title: "Listing Approval",
      image: "https://aurastorage.blob.core.windows.net:443/auraprod/8abb9b69-8611-4096-8052-4e6a958e0d0e.jpg",
      subtitle: "",
      paragraph:
        "It takes between 48-72hours to run our verification process on your home before your listing is approved.",
      id: "7",
    },
    {
      category: "Host Onboarding",
      title: "Become a Host",
      image: "https://aurastorage.blob.core.windows.net:443/auraprod/4110493d-e423-42b8-a461-f4da9dca6dc0.jpg",
      subtitle: "",
      paragraph:
        "Sign up with your email and password. Click on the “Become a host” link and follow the directions on the site.",
      id: "8",
    },
    {
      category: "Payments",
      title: "Payment Methods",
      image: "https://aurastorage.blob.core.windows.net/auraprod/d3addf73-efa5-47c6-b853-b591a02133fa.png",
      subtitle: "",
      paragraph: "We use payment partners to collect payments from MasterCard and Visa debit and credit cards.",
      id: "9",
    },
    {
      category: "Guest Booking",
      title: "Create an Account",
      image: "https://aurastorage.blob.core.windows.net:443/auraprod/ba6e61b3-1687-4ff6-86df-3f90d5598674.jpg",
      subtitle: "",
      paragraph:
        "Click on sign up on the top right-hand menu of the home screen and fill out the registration forms with your required details",
      id: "10",
    },
    {
      category: "Guest Booking",
      title: "Book a Home",
      image: "https://aurastorage.blob.core.windows.net:443/auraprod/17673cf5-add8-4f7c-8311-e30a82410d96.jpg",
      subtitle: "",
      paragraph: "By filling out the search bar on the home page and click on “Book Now”.",
      id: "11",
    },
    {
      category: "Guest Booking",
      title: "How it Works",
      image: "https://aurastorage.blob.core.windows.net:443/auraprod/1182714c-3aa5-4188-b011-0d9e043e757e.png",
      subtitle: "",
      paragraph:
        "Aura is a digital platform for connecting travellers to quality accommodation, great food, and memorable experiences. The platform is also built for people with unoccupied homes, hotels, restaurants, or different skill sets that may interest others, to earn an income by becoming hosts. ",
      id: "12",
    },
    {
      category: "Guest Booking",
      title: "Unreachable Host",
      image: "https://aurastorage.blob.core.windows.net:443/auraprod/3f5bc932-5737-4719-b17c-35a285a0e3f3.jpg",
      subtitle: "",
      paragraph:
        "Simply reach out to the Aura Customer help center via the help button on the website of via email auracs@transcorphotelsplc.com.",
      paragraph1:
        "Access the property on the Aura platform and simply click on the button, “Contact the Host” to chat with the Host.",
      id: "13",
    },
    {
      category: "Guest Booking",
      title: "Profile Viewing",
      image: "https://aurastorage.blob.core.windows.net:443/auraprod/271fff00-007c-4daa-a5a7-2ba71ca43a65.jpg",
      subtitle: "",
      paragraph: "Nobody can view your profile as a signed-up Aura guest.",
      id: "14",
    },
    {
      category: "Guest Booking",
      title: "Reset Password",
      image: "https://aurastorage.blob.core.windows.net/auraprod/b8ad0bab-6b6f-4cc4-a36d-a6dca988493d.jpg",
      subtitle: "",
      paragraph:
        "Go to the sign-in page, click on forgot password and a link will be sent to your registered email address with a link to enable password reset.",
      id: "15",
    },
    {
      category: "Guest Booking",
      title: "Unbearable Host",
      image: "https://aurastorage.blob.core.windows.net:443/auraprod/5894e7ca-f2e0-4feb-9024-a160ea9bdecf.jpg",
      subtitle: "",
      paragraph:
        "Simply reach out to the Aura Customer help center via the help button on the website of via email auracs@transcorphotelsplc.com",
      id: "16",
    },
    {
      category: "Guest Booking",
      title: "Successful Booking",
      image: "https://aurastorage.blob.core.windows.net/auraprod/0cbec64a-40ee-4caa-a82b-5308edb85309.png",
      subtitle: "",
      paragraph: "You will receive a notification email on your registered email address confirming your booking",
      id: "17",
    },
    {
      category: "Guest Booking",
      title: "Misplaced Keys",
      image: "https://aurastorage.blob.core.windows.net:443/auraprod/bbe1a2e3-996a-4840-ae6e-3a105c96e742.jpg",
      subtitle: "",
      paragraph: "Log on to your Aura account.",
      paragraph1: "Access your selected property and click on the button, “Contact the Host” to chat with the Host",
      id: "18",
    },
    {
      category: "Guest Booking",
      title: "Payment Process",
      image: "https://aurastorage.blob.core.windows.net:443/auraprod/e4fb8b6f-8227-4484-9497-9e001a430dcf.jpg",
      subtitle: "",
      paragraph: "Hosts get paid on the day the guest is to check-in",
      id: "19",
    },
    {
      category: "Cancellation",
      title: "Cancellation Policy",
      image: "https://aurastorage.blob.core.windows.net/auraprod/f24c5068-cc09-4491-bbba-b0234d1e727d.jpg",
      subtitle: "",
      paragraph:
        "Guests are entitled to free cancellation if they cancel their booking not more than 48 hours after booking, and at least 14 days before check-in (time shown in the confirmation email). Click link for more details cancellation policy",
      id: "21",
    },
    {
      category: "Cancellation",
      title: "Booking Cancellation",
      image: "https://aurastorage.blob.core.windows.net/auraprod/9cb95812-fff1-4b9b-8f42-d8dd05732b36.png",
      subtitle: "",
      paragraph: "You will receive a notification email on your registered email address confirming your cancellation",
      id: "122",
    },
  ];

  function removeDuplicates(data: { category: any; }[]) {
    const unique: any[] = [];
    data.forEach((element: { category: any; }) => {
      if (!unique.includes(element.category)) {
        unique.push(element.category);
      }
    });
    return unique.map((item) => <option value={item}>{item}</option>);
  }

  return (
    <>
    <Header isAuthenticated={isAuthenticated}/>
    <div className="bg-slate-100">
          <section className="py-12 px-4 sm:px-8 container">
      <div className="border-b-2 border-auraPrimary w-44">
        <h2 className=" text-dark text-md font-semibold mb-1">AURA GUEST GUIDE</h2>
      </div>
      <select
        name=""
        onChange={handleChange}
        className="px-3 py-1.5 text-sm mt-6 outline-none font-light text-gray-900 border border-gray-300 bg-gray-50 rounded"
        id=""
      >
        <option value="all">All Categories</option>
        {removeDuplicates(data)}
      </select>

      {modal && <HelpCenterPageModal setModal={setModal} modalData={modalData} />}

      <div className="grid grid-cols-1 gap-2  md:grid-cols-4">
        {data.map((item, key) => (
          <React.Fragment key={key}>
            {select === "all" ? (
              <Card item={item} setModal={setModal} setModalData={setModalData} />
            ) : (
              <>{item.category === select && <Card item={item} setModal={setModal} setModalData={setModalData} />}</>
            )}
          </React.Fragment>
        ))}
      </div>
    </section>
    </div>
  
  <div className="drop-shadow-2xl">
  <Footer/>
  </div>
   
    </>
  
  );
}

