import service from 'services/fetchInterceptor';
import { InitializePaymentData, IProcessBankAccount, ServiceType } from './types';

const paymentService: ServiceType | any = {};

paymentService.initializePayment = (
  data: InitializePaymentData
): Promise<any> =>
  service({
    url: '/Payment/payment-initialization',
    method: 'post',
    data,
    headers: { 'public-request': 'true' },
  });
paymentService.verifyPayment = (id: number | string): Promise<any> =>
  service({
    url: `/Payment/payment-verification/${id}`,
    method: 'get',
    headers: { 'public-request': 'true' },
  });
  paymentService.validateAccountNumber = ( data :{bankCode:string; accountNumber:string}): Promise<any> =>
  service({
    url: `/Payment/validate-account-number`,
    method: 'post',
    data,
    headers: { 'public-request': 'true' },
  });
paymentService.confirmPayment = (data: {
  transactionID: number | string;
  bookingID: number | string;
}): Promise<any> =>
  service({
    url: `/v1/Booking/confirm/booking/payment`,
    method: 'post',
    data,
    headers: { 'public-request': 'true' },
  });

paymentService.getUserBankAccount = (id:number): Promise<any> =>
  service({
    url: `/v1/Account/GetUserBankAccount?UserId=${id}`,
    method: 'get',
    headers: { 'public-request': 'true' },
  });
  paymentService.getBanks = (): Promise<any> =>
  service({
    url: `/Payment/get-banks`,
    method: 'get',
    headers: { 'public-request': 'true' },
  });
paymentService.processUserBankAccount = (data: IProcessBankAccount): Promise<any> =>
  service({
    url: `/v1/Account/ProcessUserBankAccount`,
    method: 'post',
    data,
    headers: { 'public-request': 'true' },
  });

export default paymentService;
