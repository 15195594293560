import { Dispatch, AnyAction } from '@reduxjs/toolkit';
import dataService from 'services/appData';
import { CreateWishlist } from 'services/appData/types';
import bookingService from 'services/bookingService';
import {
  loadingStart,
  loadingStopped,
  bookingSuccess,
  createWishlistSuccess,
  setIsApprovedBookingExist,
  saveBookingById,
  setbookingRouteFrom,
  saveBookingActivity,
  saveGeoLocationInfo,
} from './reducers';


export const createBooking = (data: any) => (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(bookingSuccess(data));
  } catch (err: any) {
    dispatch(loadingStopped(err.response.data.responseMessage));
  }
};

export const createWishlist =
  (data: CreateWishlist) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      const res = await dataService.createWishlist(data);
      dispatch(createWishlistSuccess(res.data));
    } catch (err: any) {
      dispatch(loadingStopped(err.response.data.responseMessage));
    }
  };


  export const setIsApprovedBooking =
  (data: boolean) =>
  async (dispatch: Dispatch<AnyAction>): Promise<void> => {
    try {
      dispatch(setIsApprovedBookingExist(data));
    } catch (err: any) {
      console.log(err);
    }
  };

  export const getBookingByBookingId =
  (id: number|string) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      const res = await bookingService.getBookingByBookingID(id);
      dispatch(saveBookingById(res.data));
    } catch (err: any) {
      dispatch(loadingStopped(err.response.data.responseMessage));
    }
  };

  export const bookingRouteFrom = (data: string) => (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(setbookingRouteFrom(data));
    } catch (err: any) {
      dispatch(loadingStopped(err.response.data.responseMessage));
    }
  };

  export const saveActivityId =
  (data: any) =>
  async (dispatch: Dispatch<AnyAction>): Promise<void> => {
    try {
      dispatch(saveBookingActivity(data));
    } catch (err: any) {
      console.log(err);
    }
  };

  export const saveGeoLocation=
  (data: any) =>
  async (dispatch: Dispatch<AnyAction>): Promise<void> => {
    try {
      dispatch(saveGeoLocationInfo(data));
    } catch (err: any) {
      console.log(err);
    }
  };