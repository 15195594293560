import firebase from 'services/firebase-config';

export const facebookProvider = new firebase.auth.FacebookAuthProvider();
export const googleProvider = new firebase.auth.GoogleAuthProvider();

const config = {
  API_BASE_URL:
    process.env.REACT_APP_API_URL ||
    // 'https://placid-001-site31.itempurl.com/api',
    'https://prodaurapi.azurewebsites.net/api',
  AUTH_TOKEN: 'x-auth-token',
  AUTH_USER_ID: 'x-auth-user',
  REDUX_PERSIST: 'persist:root',
};

export default config;