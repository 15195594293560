import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Modal from '.';
import lovedimage from '../../../assets/img/success.svg';
import loadingicon from '../../../assets/icon/loading.svg';
import {RiAlertFill} from "react-icons/ri";

interface SelectPaymentTypeProp {
  verifyloading: boolean;
  openModal: boolean;
  paymentSuccess: boolean
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmit:  (e: any) => Promise<void>
  setOpenBankTransferModal: React.Dispatch<React.SetStateAction<boolean>>;
  bankDetails: null;
}
export default function SelectPaymentType(props: SelectPaymentTypeProp) {

  return (
    <>
      <Modal
        children={
          <div className="relative py-10 px-10 w-[350px] md:w-[450px] lg:w-full max-w-xl bg-white shadow-xl rounded-xl lg:max-w-xl">
            <div className="p-2 text-center mb-8 xl:px-24">
              <h2 className="text-2xl font-bold">
                Payment Method
              </h2>
              <h4 className='text-[16px] mt-2'>Select your payment Method</h4>
            </div>
          <div className='flex items-center gap-4'>
            <div className='p-4 rounded-xl border w-[50%] cursor-pointer hover:border-[#F48220]'
              onClick={(e)=>{
                props.handleSubmit(e)
                props.setOpenModal(false)
              }}
              >
              Card
            </div>

            <div className={`p-4 rounded-xl border w-[50%] ${props.bankDetails == null ? 'cursor-not-allowed text-[#c6c5c5]' : 'cursor-pointer hover:border-[#F48220] text-[#2F2F2F]'} `}
              onClick={()=> {
                if(props.bankDetails !== null){
                  props.setOpenBankTransferModal(true)
                  }
              }}
            >
              Bank Transfer
            </div>
          </div>
          </div>
        }
        openModal={props.openModal}
        setOpenModal={props.setOpenModal}
        verifyloading={props.verifyloading}
        paymentSuccess={props.paymentSuccess}
      />
    </>
  );
}
