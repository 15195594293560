import { Dispatch, AnyAction } from '@reduxjs/toolkit';
import { loadingStart, loadingStopped, reviewSuccess,createReviewSuccess } from './reducers';
// import dataService from 'services/appData';
import reviewService from 'services/reviewService';
import { CreateReviewData } from 'services/reviewService/types';
// import { reviewProducts } from 'services/appData/types';
// import {createReviewSuccess} from "."


// export const getReviewsByProductDetailsId =
//   (id: number|string|undefined) =>
//   async (dispatch: Dispatch<AnyAction>): Promise<void> => {
//     try {
//       dispatch(loadingStart());
//       const res = await reviewService.getReviewsByProductDetailsID(id);
//       dispatch(reviewSuccess(res.data));
//       // dispatch(loadingStart());
//     } catch(err: any){
//       // console.log(err);
//       dispatch(loadingStopped(err.response.data.responseMessage));
//     }
//   };

  export const createReview = (data: CreateReviewData) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(loadingStart());
      const res = await reviewService.createReview(data);
      dispatch(createReviewSuccess(res.data));
    } catch (err: any) {
      dispatch(loadingStopped(err.response.data.responseMessage));
    }
  };


