import { RootState } from 'redux/rootReducer';
import { useSelector } from 'react-redux';
import NotificationContent from './NotificationContent';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Button, Dropdown, Menu, Space } from 'antd';
import { Link } from 'react-router-dom';

const menu = (
  <Menu
    items={[
      {
        key: '1',
        label: <button>Mark all as read</button>,
      },
      {
        key: '2',
        label: <Link to="/user-profile/notification">Notification Settings</Link>,
      },
    ]}
  />
);

interface NotificationProps {
  notification: {
    title: string;
    body: string;
  };
  setLocalNotificationLength: (arg0: any) => void;
}
export default function NotificationComponent({
  notification, setLocalNotificationLength
}: NotificationProps) {
  const { isAuthenticated } = useSelector((state: RootState) => state.user);

  return (
    <div className="bg-white w-64 p-4">
      {isAuthenticated && (
        <>
          <div className="flex justify-between items-center">
            <span
              className="text-lg font-medium capitalize flex items-center gap-1 cursor-pointer "
            >
              Notification
            </span>
            <Space direction="vertical">
              <Space wrap>
                <Dropdown overlay={menu} placement="bottomLeft">
                  <Button style={{ border: 0 }}>
                    <MoreHorizIcon className="hover:text-auraPrimary" />
                  </Button>
                </Dropdown>
              </Space>
            </Space>
          </div>
          <NotificationContent setLocalNotificationLength={setLocalNotificationLength}/>
        </>
      )}
    </div>
  );
}
