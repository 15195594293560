import { Modal } from 'components/common';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import reserveModalIcon from 'assets/icon/reserve-modal.svg';
import { Dispatch, SetStateAction } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/rootReducer';


export default function ReservationModal(props: { bookingType?: string; modal: boolean; bookingId?: any; instantBooking?: string; setModal: Dispatch<SetStateAction<boolean>> }) {
    const location = useLocation()
    const navigate = useNavigate();
    const { user } = useSelector((state: RootState) => state.user);

    const isAuxService = props.instantBooking === 'INSTANT_BOOKING';

    return (
        <Modal
            openModal={props.modal}
            setOpenModal={props.setModal}
            className="pt-16 pb-12 bg-white px-4 sm:px-10 w-full max-w-[542px] fit"
            onOutsideClick={() => navigate(isAuxService? '/user-profile/bookings' : '/')}
            onModalClose={() => navigate(isAuxService? '/user-profile/bookings' : '/')}
        >
            <div className="p-6 flex flex-col items-center justify-center space-y-6">
                <img src={reserveModalIcon} className="w-40 " alt="" />
                <h3 className="mb-5 text-2xl font-semibold">{isAuxService? 'Reservation Created Successfully' : 'Reservation Request Sent!'}</h3>
                {!isAuxService && <p className="text-base text-neutral font-normal text-center">
                    You will be contacted as soon as host confirms availability
                </p>}
                <div className="pb-8 w-full">
                    {/* <Link
                        to="/"
                        className=" py-3 rounded-xl text-base font-normal  mx-auto w-full bg-auraPrimary text-white capitalize hover:text-white"
                        type="button"
                        data-modal-toggle="approved-modal"
                        >
                        go home
                    
                        </Link> */}

                    {isAuxService?
                        <Link
                        to={`/confirm-pay/${props.bookingId}`}
                        className=" py-3 rounded-xl text-base font-normal  mx-auto w-full bg-auraPrimary text-white capitalize hover:text-white"
                        type="button"
                        data-modal-toggle="approved-modal"
                    >
                        Proceed to pay
                    </Link> :
                    !user.userID ? 
                    <p className="text-base text-neutral font-normal text-center">
                        <b>Note:</b> You will receive an email that contains the payment link to complete your booking, 
                        after host has confirmed the availability of your reservation. Thank you
                    </p> : 
                    <Link
                        to="/user-profile/bookings"
                        className=" py-3 rounded-xl text-base font-normal  mx-auto w-full bg-auraPrimary text-white capitalize hover:text-white"
                        type="button"
                        data-modal-toggle="approved-modal"
                    >
                        View your bookings
                    </Link>}
                </div>
            </div>
        </Modal>
    );
}
