import { Navigate, useLocation } from 'react-router-dom';
import { getSession, storage } from 'utils';

const PrivateRoute = ({ children }: { children: JSX.Element }) => {
  const location = useLocation();

  if (!storage.getToken() || !getSession(storage.getToken())) {
    return (
      <Navigate
        to={`/login?redirect=${location.pathname}${location.search}`}
        state={{ from: location }}
      />
    );
  }

  return children;
};

export default PrivateRoute;
