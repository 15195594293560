import { FC } from 'react';
import { HiOutlineMinusSm, HiOutlinePlusSm } from 'react-icons/hi';
import Input from '../Input';
import Button from '../Button';

interface CounterProps {
  error?: string;
  touched?: boolean;
  value: number;
  name: string;
  label?: string;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
}

const Counter: FC<CounterProps> = ({
  error,
  touched,
  value,
  name,
  label,
  setFieldValue,
}) => {
  return (
    <div>
      <label htmlFor="" className="block" style={{fontWeight:600}}>
        {label}
      </label>
      <div className="flex items-center gap-2 mt-2">
        <div>
          <Button
            type="button"
            variant="outline"
            className="h-10 w-10 flex justify-center items-center"
            onClick={() => {
              value > 0 && setFieldValue(name, value - 1);
            }}
          >
            <span className="text-base">
              <HiOutlineMinusSm />
            </span>
          </Button>
        </div>
        <div className="min-w-14 w-14">
          <Input
            className="h-10 p-0"
            name="roomNumber"
            onChange={(e) => {
              const value = e.target.value.replace(/\D/g, '');
              setFieldValue('roomNumber', Number(value));
            }}
            error={error}
            touched={touched}
            value={value}
          />
        </div>
        <div>
          <Button
            type="button"
            variant="outline"
            className="h-10 w-10 flex justify-center items-center"
            onClick={() => setFieldValue(name, value + 1)}
          >
            <span className="text-base">
              <HiOutlinePlusSm />
            </span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Counter;
