import { Header } from 'components/common';
import Inbox from 'components/Messaging/Inbox';
import NewInbox from 'components/Messaging/NewInbox';
import Outbox from 'components/Messaging/Outbox';
import { ReactComponent as WhiteBgInboxIcon } from 'assets/icon/WhiteBgInboxIcon.svg';
import { ReactComponent as BlackBgInboxIcon } from 'assets/icon/BlackBgInboxIcon.svg';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/rootReducer';
import { useNavigate } from 'react-router-dom';
import { AdminServices } from 'services/adminService';
import moment from 'moment';
import Reply from 'components/Messaging/Reply';
import { Spinner } from 'components/common/Loader';
import { useLocation } from 'react-router-dom';


export default function Messages({history}: any) {
    const { isAuthenticated, user } = useSelector((state: RootState) => state.user);
    const { search } = useLocation();
    const query = useMemo(() => new URLSearchParams(search), [search]);
    const startMsg: any = query.get('startMsg');
    const navigate = useNavigate();

    const [pageItem, setPageItem] = useState(startMsg == "true" ? "newMessage" : 'inbox');
    const [inboxMsg, setInboxMsg] = useState<any[]>([]);
    const [outBox, setOutboxMsg] = useState<any[]>([]);
    const [replyMsg, setRelyMsg] = useState<any>({});
    const [searchedMessages, setSearchedMessages] = useState<any[] | null>(null);
    const [isFetching, setFetch] = useState(true);

    const ComponentItem = () => {
        switch (pageItem) {
            case 'inbox':
                return (
                    <Inbox
                        inboxMsg={inboxMsg}
                        setRelyMsg={setRelyMsg}
                        setPageItem={setPageItem}
                        searchedMessages={searchedMessages}
                    />
                );
            case 'Outbox':
                return (
                    <Outbox
                        outBox={outBox}
                        setRelyMsg={setRelyMsg}
                        setPageItem={setPageItem}
                        searchedMessages={searchedMessages}
                    />
                );
            case 'newMessage':
                return <NewInbox setPageItem={setPageItem} getAllMessages={getAllMessages} />;
            case 'reply':
            case 'replyInbox':
            case 'replyOutbox':
                return (
                    <Reply replyMsg={replyMsg} inboxMsg={inboxMsg} setInboxMsg={setInboxMsg} getAllMessages={getAllMessages} />
                );
            default:
                return <h1>No project match</h1>;
        }
    };

    const getAllMessages = async () => {
        try {
            const resp = await Promise.all([
                AdminServices.getMessagesInbox(user.userID),
                AdminServices.getMessagesOutbox(user.userID),
            ]);
            setInboxMsg(resp[0]?.data?.reverse());
            setOutboxMsg(resp[1]?.data?.reverse());
            if (isFetching) setFetch(false);
        } catch (error) {
            console.log(error);
        }
    };

    const searchMessages = (val: string) => {
        if (val.length < 1) return setSearchedMessages(null);
        const msgState = pageItem === 'inbox' ? inboxMsg : outBox;
        const filterByVal = [...msgState].filter((msgs) =>
            pageItem === 'inbox'
                ? [msgs.sentByUserName.toLowerCase(), msgs.messageSubject.toLowerCase()].join(' ').includes(val.toLowerCase())
                : [msgs.toUserName.toLowerCase(), msgs.messageSubject.toLowerCase()].join(' ').includes(val.toLowerCase())
        );
        setSearchedMessages(filterByVal);
    };

    useEffect(() => {
        getAllMessages();
    }, []);

    if (isFetching) return <Spinner />;
    return (
        <>
            <Header isAuthenticated={isAuthenticated} />{' '}
            <section className="h-auto md:px-10 px-4 py-4 space-y-6 bg-dashboard">
                <button onClick={() => navigate(-1)} className="text-2xl font-medium capitalize flex items-center gap-1 ">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                    >
                        <path stroke-linecap="round" stroke-linejoin="round" d="M15 19l-7-7 7-7"></path>
                    </svg>
                    Go Back
                </button>
                {/* <!-- Chat main container --> */}
                <div className="grid grid-cols-1 gap-3 box-border md:grid-cols-[364px_minmax(0,_1fr)] items-start relative">
                    <div className=" bg-white shadow-lg rounded-lg shadown  box-border sticky  md:top-[100px]">
                        <div className="  h-full p-4 box-border">
                            <div className="mb-3">
                                <button
                                    className="bg-auraPrimary text-white px-6 py-3 rounded-md"
                                    onClick={() => {
                                        setPageItem('newMessage');
                                    }}
                                >
                                    Start New Message
                                </button>
                            </div>

                            <div className="my-6">
                                <input
                                    type="text"
                                    id="search"
                                    className="block w-full py-3 px-3 outline-none border border-gray-300 rounded-lg shadow-sm placeholder:text-sm placeholder:font-light"
                                    placeholder="search message..."
                                    onChange={(e) => searchMessages(e.target.value)}
                                />
                            </div>

                            <div className="overflow-hidden space-y-6">
                                <div
                                    className={`flex  gap-3 items-center  ${
                                        ['inbox', 'replyInbox'].includes(pageItem)
                                            ? 'bg-zinc-800 text-white '
                                            : 'bg-white text-zinc-800'
                                    } px-3 py-4 rounded-xl`}
                                >
                                    <div>
                                        {['inbox', 'replyInbox'].includes(pageItem) ? <WhiteBgInboxIcon /> : <BlackBgInboxIcon />}
                                    </div>
                                    <button
                                        className="w-full truncate text-ellipsis"
                                        onClick={() => {
                                            setPageItem('inbox');
                                        }}
                                    >
                                        <div className="flex items-center justify-between  mb-2">
                                            <div className="text-sm font-semibold  capitalize">Inbox</div>
                                            <div className=" text-xs font-medium">
                                                {moment(inboxMsg[0]?.dateCreated).format('hh:mm A')}
                                            </div>
                                        </div>
                                        <p
                                            className={`${
                                                ['inbox', 'replyInbox'].includes(pageItem) ? 'text-white' : 'text-auraPrimary'
                                            }   text-sm text-left truncate text-ellipsis`}
                                        >
                                            <span className="font-bold">{inboxMsg[0]?.messageSubject}</span>-{' '}
                                            {inboxMsg[0]?.messageBody}
                                        </p>
                                    </button>
                                </div>
                                <div
                                    className={`flex gap-3 items-center box-border  ${
                                        ['Outbox', 'replyOutbox'].includes(pageItem)
                                            ? 'bg-zinc-800 text-white '
                                            : 'bg-white text-zinc-800'
                                    } px-3 py-4 rounded-xl `}
                                >
                                    <div>
                                        {['Outbox', 'replyOutbox'].includes(pageItem) ? (
                                            <WhiteBgInboxIcon />
                                        ) : (
                                            <BlackBgInboxIcon />
                                        )}
                                    </div>
                                    <button
                                        className="w-full block truncate text-ellipsis"
                                        onClick={() => {
                                            setPageItem('Outbox');
                                        }}
                                    >
                                        <div className="flex items-center justify-between  mb-2">
                                            <div className="text-sm font-semibold capitalize">Outbox</div>
                                            <div className="text-xs font-medium">
                                                {moment(outBox[0]?.dateCreated).format('hh:mm A')}
                                            </div>
                                        </div>
                                        <div
                                            className={`${
                                                ['Outbox', 'replyOutbox'].includes(pageItem) ? 'text-white' : 'text-auraPrimary'
                                            }   text-sm text-left truncate text-ellipsis`}
                                        >
                                            <span className="font-bold">{outBox[0]?.messageSubject}</span>-{' '}
                                            {outBox[0]?.messageBody}
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <ComponentItem />
                </div>
            </section>
        </>
    );
}
